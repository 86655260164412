import Collapse, { Panel } from 'rc-collapse';
import { useMemo } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUser } from 'redux/features/auth.slice';
import data from './data.json';
import 'rc-collapse/assets/index.css';
import './styles.scss';

const Subscription = () => {
	const navigate = useNavigate();
	const user = useSelector(getUser);

	const current_plan = useMemo(() => {
		if (user) return user?.access_type ?? 'guest';
		return 'guest';
	}, [user]);

	return (
		<div className='upgrade-modal'>
			<div className='mask' onClick={() => navigate(-1)} />

			<div className='content'>
				<div className='heading'>
					<div className='d-flex justify-content-between align-items-center'>
						<img src='/assets/img/logo_2.png' alt='ucpox' className='logo' />

						<button onClick={() => navigate(-1)}>
							<FaTimes />
						</button>
					</div>

					<h1>Subscription Plans</h1>
				</div>

				<div className='body desktop'>
					{data.map(sub => (
						<div
							key={sub.id}
							className={sub.id === current_plan ? 'current' : ''}>
							<h2>
								{sub.title}
								{sub.id === current_plan && <span>&nbsp;(Current)</span>}
							</h2>

							<ul className='list'>
								{sub.features.map(feat => (
									<li key={feat}>{feat}</li>
								))}
							</ul>
						</div>
					))}
				</div>

				<div className='body mobile'>
					<Collapse accordion={true}>
						{data.map(sub => (
							<Panel
								key={sub.id}
								header={
									<>
										{sub.title}
										{sub.id === current_plan && <span>&nbsp;(Current)</span>}
									</>
								}
								className={sub.id === current_plan ? 'current' : ''}>
								<ul className='list'>
									{sub.features.map(feat => (
										<li key={feat}>{feat}</li>
									))}
								</ul>
							</Panel>
						))}
					</Collapse>
				</div>
			</div>
		</div>
	);
};

export default Subscription;
