import { Fragment, useEffect } from "react";
import { BasicLoader } from "components";
import { FaDownload } from "react-icons/fa";
import { useScrollToBottom } from "react-scroll-to-bottom";
import convertBytes from "utils/convertBytes";
import chatTime from "utils/date";

export default function ChatBodyList({
  messageList,
  user,
  room,
  selected,
  handleSelect,
}) {
  const scrollToBottom = useScrollToBottom({});

  const download = (path, filename) => {
    // Create a new link
    const anchor = document.createElement("a");
    anchor.href = path;
    anchor.download = filename;
    anchor.target = "_blank";
    anchor.rel = "noreferrer";
    // Append to the DOM
    document.body.appendChild(anchor);
    // Trigger `click` event
    anchor.click();
    // Remove element from DOM
    document.body.removeChild(anchor);
  };

  useEffect(() => {
    if (messageList) scrollToBottom({ behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageList]);

  return (
    <div className="messages-view">
      {!room ? (
        <div className="loading">
          <BasicLoader />
        </div>
      ) : (
        messageList.map((item, idx) => {
          const prev = idx > 0 ? messageList?.[idx - 1] : null;
          const {
            author,
            content,
            created_at: createdAt,
            content_type: contentType,
            meta,
            author_actions,
            receiver_actions,
          } = item;
          const sent = author === user.uid;
          const time = chatTime(createdAt);

          if (author_actions?.includes("deleted") && sent) {
            return null;
          }

          if (receiver_actions?.includes("deleted") && !sent) {
            return null;
          }

          const textContent = content.split(" ").map((e, index) => {
            if (e.startsWith("http")) {
              const entities = e.split("/");
              const label = entities[entities.length - 1];
              const linkEl = <a href={e}>{label}</a>;

              return (
                <Fragment key={index}>
                  {index === 0 ? linkEl : <> {linkEl}</>}
                </Fragment>
              );
            }

            return <Fragment key={index}>{index === 0 ? e : ` ${e}`}</Fragment>;
          });

          return (
            <div key={idx} className="message-item">
              {time.day !== chatTime(prev?.created_at).day && (
                <span className="chat-day">{time.day}</span>
              )}

              <div className={`message ${sent ? "sent" : "received"}`}>
                <button
                  type="button"
                  className={`${contentType} ${
                    selected.includes(item._id) ? "selected" : ""
                  }`}
                  style={
                    item._id
                      ? { opacity: 1, cursor: "pointer" }
                      : { opacity: 0.5 }
                  }
                  disabled={item._id || !sent ? false : true}
                  onClick={item._id ? () => handleSelect(item._id) : null}
                >
                  <div>
                    {contentType === "file" ? (
                      <>
                        <div>
                          <span>{content}</span>
                          <div
                            className="btn download-btn btn-sm"
                            style={{ borderRadius: "50%" }}
                            onClick={() => download(meta?.url, meta?.name)}
                          >
                            <FaDownload />
                          </div>
                        </div>
                        <span className="meta">{convertBytes(meta?.size)}</span>
                      </>
                    ) : (
                      textContent
                    )}
                  </div>

                  <span className="checkbox" />
                </button>
                <span className="time">{time.at}</span>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
