import { useQueryClient } from "react-query";
import { BasicLoader } from "components";
import { useAxiosPrivate, useURLQuery } from "hooks";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect } from "react";

export default function CreateChat() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  const query = useURLQuery();
  const contactid = query.get("to");
  const pox_url = query.get("for");
  const { user, loading } = useSelector((state) => state.auth);

  const createChat = async () => {
    try {
      if (user?.uid && !loading) {
        const initialMessage = {
          id: uuid(),
          author: user.uid,
          content: `Good day, I am interested in this Property ${pox_url}`,
          content_type: "text",
          created_at: Date.now(),
        };

        const res = await axiosPrivate.post(`chats`, {
          contactid,
          initialMessage,
        });

        navigate(`/messages/chat/${res.data.chatid}`, { replace: true });
        queryClient.invalidateQueries("contacts");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      navigate("/messsages/inbox", { replace: true });
    }
  };

  useEffect(() => {
    if (!contactid || !pox_url || !user) {
      toast.error("Something went wrong");
      navigate("/messages/inbox", { replace: true });
    } else {
      createChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pox_url, contactid, user]);

  return (
    <div className="loading d-flex align-items-center justify-content-center h-100">
      <BasicLoader />
    </div>
  );
}
