import { useMutation, useQuery } from "react-query";
import { useAxiosPrivate } from "hooks";
import { toast } from "react-toastify";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import { Spinner } from "react-bootstrap";

export default function SaveButton({ pox }) {
  const axios = useAxiosPrivate();

  const is_saved_query = useQuery({
    queryKey: ["IS_SAVED", { pox_id: pox._id }],
    queryFn: async () => {
      if (!pox._id) return null;
      const res = await axios.get(`/saved-poxes/${pox._id}/is-saved`);
      return res.data;
    },
  });

  const toggle_save_mutation = useMutation({
    mutationFn: async () => {
      return await axios.post(`/saved-poxes`, { pox_id: pox._id });
    },
    async onSuccess(res) {
      await is_saved_query.refetch();
      toast.info(res.data?.message);
    },
    onError() {
      toast.error("There was an error, Try again later");
    },
  });

  return (
    <>
      <button
        className="btn-icons"
        onClick={() => toggle_save_mutation.mutate()}
        disabled={is_saved_query.isLoading || toggle_save_mutation.isLoading}
      >
        {is_saved_query.isLoading || toggle_save_mutation.isLoading ? (
          <Spinner animation="border" size="sm" />
        ) : is_saved_query.data?.isSaved ? (
          <FaBookmark fontSize={12} />
        ) : (
          <FaRegBookmark fontSize={12} />
        )}
      </button>
    </>
  );
}
