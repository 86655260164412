import "./styles.scss";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFilterQueries,
  updateLayer,
  updatePayType,
} from "redux/features/map.slice";
import { FaFilter } from "react-icons/fa";
import { ReactComponent as FlatSvg } from "assets/svg/flat.svg";
import { ReactComponent as PlotSvg } from "assets/svg/plot.svg";
import { ReactComponent as EstabSvg } from "assets/svg/estab.svg";
import { ReactComponent as MyPoxesSvg } from "assets/svg/my-poxes.svg";
import { ReactComponent as GuidedTourSvg } from "assets/svg/guided-tour.svg";
import { GuidedTourWrapper } from "components";
import Menu from "./Menu";
import FilterSection from "./FilterSection";
import { initGuidedTour } from "redux/features/other.slice";

const poxTypeFilters = [
  { id: "Flat", label: "Flat", icon: FlatSvg },
  { id: "Plot", label: "Plot", icon: PlotSvg },
  { id: "Estab", label: "Estab", icon: EstabSvg },
];

const payTypesGroup = new Map([
  ["Flat", { options: ["Rent", "Sale"] }],
  ["Plot", { options: ["Lease", "Sale"] }],
  ["Estab", { options: ["Rent", "Sale"] }],
]);

export default function SideNav() {
  const dispatch = useDispatch();
  const { layer, payType } = useSelector((state) => state.map);
  const { user } = useSelector((state) => state.auth);
  const { guided_tour } = useSelector((state) => state.other);
  const [filterIsOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const disable_top = useMemo(() => {
    return (
      location.pathname === "/my-poxes" ||
      location.pathname === "/geofence" ||
      location.pathname === "/saved-poxes"
    );
  }, [location.pathname]);

  const poxTypeChange = (poxType) => {
    dispatch(updateLayer(poxType));
    dispatch(updateFilterQueries(""));
    if (!payTypesGroup.get(poxType)?.options?.includes(payType)) {
      dispatch(updatePayType(payTypesGroup.get(poxType).options[0]));
    }
  };

  useEffect(() => {
    setIsOpen(false);
    return () => {
      setIsOpen(false);
    };
  }, [location.pathname]);

  return (
    <div
      className="side-nav"
      style={{
        zIndex:
          (guided_tour.init &&
            (guided_tour.stage === 2 || guided_tour.stage === 3)) ||
          filterIsOpen ||
          open
            ? 1001
            : 401,
      }}
    >
      <Menu onChange={(p) => setOpen(p)} />

      {!disable_top && (
        <div className="top-section">
          <GuidedTourWrapper stage={2} className="pox-type-filters">
            {poxTypeFilters.map((filter) => {
              const active = layer === filter.id;
              return (
                <button
                  type="button"
                  title={filter.label}
                  key={filter.id}
                  className={active ? "nav-btn active" : "nav-btn"}
                  onClick={() => poxTypeChange(filter.id)}
                >
                  <filter.icon />
                  <span>{filter.label}</span>
                </button>
              );
            })}
          </GuidedTourWrapper>
          <div className="pox-filters">
            <button
              type="button"
              title={"Filter"}
              className={"nav-btn"}
              onClick={() => setIsOpen(true)}
            >
              <FaFilter />
              <span>Filter</span>
            </button>

            {layer && (
              <FilterSection
                active={filterIsOpen}
                close={() => setIsOpen(false)}
              />
            )}
          </div>
          <GuidedTourWrapper stage={3} className="pay-type-filters">
            <div className="nav-switch">
              {payTypesGroup.get(layer)?.options.map((pT) => {
                const active = pT === payType;
                return (
                  <button
                    type="button"
                    title={pT}
                    key={pT}
                    className={
                      active ? "nav-switch-btn active" : "nav-switch-btn"
                    }
                    onClick={() => dispatch(updatePayType(pT))}
                  >
                    <span>{pT}</span>
                  </button>
                );
              })}
            </div>
          </GuidedTourWrapper>
        </div>
      )}

      <div className="bottom-section">
        {user && (
          <Link
            to={"/my-poxes"}
            className={`nav-btn my-poxes-link ${
              location.pathname === "/my-poxes" ? "active" : ""
            }`}
          >
            <MyPoxesSvg />
            <span>My Poxes</span>
          </Link>
        )}

        <button
          type="button"
          className={`nav-btn guided-tour-btn ${
            guided_tour.init ? "active" : ""
          }`}
          onClick={() => {
            dispatch(initGuidedTour());
          }}
        >
          <GuidedTourSvg />
          <span>Take Tour</span>
        </button>
      </div>
    </div>
  );
}
