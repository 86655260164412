import "./styles.scss";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Modal, Spinner } from "react-bootstrap";
import { FaPen, FaTrash } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAxiosPrivate } from "hooks";
import { NoteForm } from "components";
import Pagination from "./Pagination";
import FilterNDSearch from "./FilterNDSearch";

const columns = [
  {
    key: "pox_name",
    label: "Pox Name",
    render: (_, row) => (
      <Link to={`/${row.pox.type.toLowerCase()}/${row.pox.id}`}>
        {row.pox.name}
      </Link>
    ),
  },
  { key: "title", label: "Note Title" },
  { key: "description", label: "Note Description" },
  {
    key: "createdAt",
    label: "Created At",
    render: (val) => (
      <span style={{ whiteSpace: "nowrap" }}>
        {new Date(val).toDateString()}
      </span>
    ),
  },
  {
    key: "actions",
    label: "Actions",
    render: (_, row) => <ActionsCell {...row} />,
  },
];

const per_page = 10;
export default function SavedNotesPage() {
  const [current_page, setCurrentPage] = useState(1);
  const { user, loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({ type: "All" });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["my-notes", user.uid],
    queryFn: async () => {
      const res = await axios.get("/poxes-notes");
      return res.data;
    },
    enabled: Boolean(user) && !loading,
  });

  const skip = useMemo(() => (current_page - 1) * per_page, [current_page]);
  const limit = useMemo(
    () => (current_page - 1) * per_page + per_page,
    [current_page]
  );

  const close = () => {
    navigate(-1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onFilterChange = (e) => {
    setFilter((prev) => ({ ...prev, ...e }));
    setCurrentPage(1);
  };

  const onSearchChange = (e) => {
    setSearch(e);
    setCurrentPage(1);
  };

  const filtered_data = useMemo(() => {
    if (data?.notes) {
      if (filter.type === "All" && search === "") {
        return data?.notes;
      }
      return data?.notes.filter((item) => {
        const type_match =
          filter.type === "All" ? true : item.pox.type === filter.type;

        const search_title_match =
          item.title.toLowerCase().indexOf(search.toLowerCase()) > -1;

        const search_pox_name_match =
          item.pox.name.toLowerCase().indexOf(search.toLowerCase()) > -1;

        const search_match = search_title_match || search_pox_name_match;

        return type_match && search_match;
      });
    }
    return [];
  }, [filter, search, data]);

  useEffect(() => {
    if (isError) {
      toast.error(
        error?.response?.data?.message ?? error?.data?.message ?? error?.message
      );
    }
  }, [isError, error]);

  if (!loading && !user) {
    return <div>You are not logged in</div>;
  }

  return (
    <div className="saved-notes-page">
      <div className="mask" onClick={close} />

      <div className="content py-4 d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between mb-3 border-bottom pb-2">
          <h1 className="fs-5 fw-bold mb-0">Saved Notes</h1>

          <button
            type="close"
            className="btn py-1 px-2"
            style={{ backgroundColor: "#042954" }}
            onClick={close}
          >
            <FiX color="#fff" size={"1.125em"} />
          </button>
        </div>

        <FilterNDSearch
          {...{ filter, search, onFilterChange, onSearchChange }}
        />

        <div className="flex-grow-1 d-flex flex-column">
          <div
            style={{
              height: !loading && filtered_data.length > 0 ? "100%" : "auto",
              overflowX: "scroll",
            }}
          >
            <table style={{ width: "100%" }}>
              <thead style={{ backgroundColor: "#F2F2F2" }}>
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.key}
                      className="fw-semibold p-2 border text-center"
                      style={{
                        color: "#042954",
                        borderColor: "#C9C9C9",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>

              {filtered_data.length > 0 && (
                <tbody className="flex-grow-1">
                  {filtered_data.slice(skip, limit).map((item) => (
                    <tr key={item._id}>
                      {columns.map(({ render, ...column }) => (
                        <td key={column.key} className="p-2 border text-center">
                          {render
                            ? render(item[column.key], item)
                            : item[column.key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>

          {isLoading ? (
            <div
              className="d-flex align-items-center justify-content-center h-50"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            filtered_data.length <= 0 && (
              <div
                className="d-flex align-items-center justify-content-center h-50"
                style={{ width: "100%" }}
              >
                <span className="fw-bold fs-5">No Data</span>
              </div>
            )
          )}

          <Pagination
            current_page={current_page}
            total_pages={
              isLoading ? 1 : Math.ceil(filtered_data.length / per_page)
            }
            onClick={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

function ActionsCell({ pox_id, title, description }) {
  const { user } = useSelector((state) => state.auth);
  const [dshow, setDShow] = useState(false);
  const [fshow, setFShow] = useState(false);
  const queryClient = useQueryClient();
  const axios = useAxiosPrivate();

  const handleClose = () => setDShow(false);
  const handleShow = () => setDShow(true);

  const delete_mutation = useMutation({
    mutationFn: async (id) => {
      return await axios.delete(`/poxes-notes/${id}`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["my-notes", user.uid]);
      handleClose();
    },
    onError: (error) => {
      toast.error(
        error?.response?.data?.message ?? error?.data?.message ?? error?.message
      );
    },
  });

  return (
    <>
      <div className="d-flex gap-2 justify-content-center">
        <button
          className="btn btn-sm btn-primary py-2 d-flex align-items-center"
          onClick={() => setFShow(true)}
        >
          <FaPen />
        </button>
        <button
          className="btn btn-sm btn-danger d-flex align-items-center py-2"
          onClick={handleShow}
          disabled={delete_mutation.isLoading}
        >
          {delete_mutation.isLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <FaTrash />
          )}
        </button>
      </div>

      <Modal
        show={dshow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold fs-6">Delete Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-1 align-items-center justify-content-center">
            <span className="d-block fw-bold fs-6">
              You are about to delete this note
            </span>
            <span style={{ fontSize: "0.85em" }}>
              Are you sure you want to delete?
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            size="sm"
            onClick={handleClose}
            disabled={delete_mutation.isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => delete_mutation.mutate(pox_id)}
            disabled={delete_mutation.isLoading}
          >
            {delete_mutation.isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Delete"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {fshow && (
        <NoteForm
          show={fshow}
          handleClose={() => setFShow(false)}
          type="update"
          pox_id={pox_id}
          title={title}
          description={description}
        />
      )}
    </>
  );
}
