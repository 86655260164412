import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { logOut } from "utils/firebase";
import { logOutSuccess } from "redux/features/auth.slice";
import { AuthModal } from "components/Modals";
import GuestPNG from "assets/img/profile.png";

export default function UserSection() {
  const [isOpen, setIsOpen] = useState(false);
  const { user, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await logOut();
      dispatch(logOutSuccess());
    } catch (error) {
      console.error(error);
    }
  };

  const handleAuth = () => {
    if (user) {
      handleLogout();
    } else {
      setIsOpen(true);
    }
  };

  return (
    <>
      <div className="user-section">
        {loading ? (
          <div>
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <img
              referrerPolicy="no-referrer"
              src={user?.photoURL || GuestPNG}
              alt={user?.displayName || "Guest"}
            />
            <div>
              <span className="d-block text-truncate">
                {user?.displayName || "Guest"}
              </span>

              <button
                type="button"
                title={user ? "logout" : "login"}
                className="auth-btn btn"
                style={{
                  backgroundColor: user ? "#EA43353D" : "#34A8533D",
                  color: user ? "#EA4335" : "#34A853",
                }}
                onClick={handleAuth}
              >
                {user ? "Logout" : "Login"}
              </button>
            </div>
          </>
        )}
      </div>
      <AuthModal show={isOpen} onHide={() => setIsOpen(false)} />
    </>
  );
}
