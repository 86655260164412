import { toast } from "react-toastify";
import { ReactComponent as CopyIcon } from "assets/svg/copy.svg";
import { ReactComponent as PropertyIcon } from "assets/svg/Property.svg";
import { FaFacebook, FaTimes, FaWhatsapp } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { useURLQuery } from "hooks";

const shareOnFacebook = (pox, already_shared) => {
  let poxLink = window.location.href;
  if (!already_shared) {
    poxLink = window.location.href + "?shared=true";
  }

  const navUrl =
    "https://www.facebook.com/sharer/sharer.php?u=" +
    encodeURIComponent(poxLink);
  window.open(navUrl, "_blank");
};
const shareOnEmail = (pox, already_shared) => {
  let poxLink = window.location.href;
  if (!already_shared) {
    poxLink = window.location.href + "?shared=true";
  }

  const poxName = pox.name;
  const navUrl =
    "mailto:?subject=UxPox%20the%20Property%20Analysis%20Platform&body=Hi%20there%2C%0D%0ACheck%20out%20this%20awesome%20property%20" +
    poxName +
    " on%20UcPox!%0D%0Aclick%20this%20to%20check%20the%20property.%0D%0A" +
    poxLink;
  window.open(navUrl, "_blank");
};
const shareOnWhatsapp = (pox, already_shared) => {
  let poxLink = window.location.href;
  if (!already_shared) {
    poxLink = window.location.href + "?shared=true";
  }

  const poxName = pox.name;
  const navUrl =
    "https://api.whatsapp.com/send?phone=&text=Hi There%2C%0D%0Acheck out this awesome property " +
    poxName +
    "  on UcPox!%0D%0Aclick this%2C%0D%0A" +
    poxLink;
  window.open(navUrl, "_blank");
};

const copyUrl = () => {
  navigator.clipboard.writeText(window.location.href);
  toast("Link Copied!");
};

export default function ShareNow({ open, pox, onClose }) {
  const URLQuery = useURLQuery();
  const shared = URLQuery.get("shared");

  if (!open) return null;

  return (
    <div className="share-modal">
      <div className="mask" onClick={onClose} />
      <div className="content px-3 py-3 d-flex flex-column justify-content-between gap-3 ">
        <div className="heading pt-3 d-flex align-items-center">
          <button className="btn btn-close" title="Close" onClick={onClose}>
            <FaTimes />
          </button>

          <h5 className="mb-0 d-flex align-items-center gap-1">
            <PropertyIcon />
            {pox.name}
          </h5>

          <button className="btn" title="Copy Link" onClick={copyUrl}>
            <CopyIcon />
          </button>
        </div>

        <div className="d-flex align-items-center justify-content-between socials">
          <button
            className="btn d-flex flex-column align-items-center gap-1"
            title="Mail"
            onClick={() => shareOnEmail(pox, shared)}
          >
            <FiMail />
            Mail
          </button>
          <button
            className="btn d-flex flex-column align-items-center gap-1"
            title="Facebook"
            onClick={() => shareOnFacebook(pox, shared)}
          >
            <FaFacebook color="#1877F2" />
            Facebook
          </button>
          <button
            className="btn d-flex flex-column align-items-center gap-1"
            title="Whatsapp"
            onClick={() => shareOnWhatsapp(pox, shared)}
          >
            <FaWhatsapp color="#60D669" />
            Whatsapp
          </button>
        </div>
      </div>
    </div>
  );
}
