import { useEffect, useMemo, useState } from "react";
import { BasicLoader } from "components";
import { useAxiosPrivate } from "hooks";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function InboxPage() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  const { user, loading } = useSelector((state) => state.auth);
  const [search, setSearch] = useState("");

  const { data, isLoading } = useQuery(
    ["contacts", user.uid],
    async () => {
      const res = await axiosPrivate("chats/contacts");
      return res.data;
    },
    { enabled: !loading }
  );

  const filtered_data = useMemo(() => {
    if (isLoading) return [];
    return data.filter((item) => {
      if (!item.contact) return null;
      return item.contact.displayName
        .toLowerCase()
        .includes(search.toLowerCase());
    });
  }, [data, isLoading, search]);

  const joinRoom = async ({ unread, chatid }) => {
    navigate(`/messages/chat/${chatid}`);
    if (unread) {
      await axiosPrivate.patch(`chats/${chatid}`);
      queryClient.invalidateQueries("contacts");
    }
  };

  useEffect(() => {
    return () => {
      setSearch("");
    };
  }, []);

  return (
    <div className="inbox">
      <div className="header">
        <div>
          <span>Messages</span>
          <button
            type="button"
            title="close button"
            className="btn btn-sm btn-dark py-1 mb-0"
            onClick={() => {
              navigate("/");
            }}
          >
            <FaTimes />
          </button>
        </div>

        {!isLoading && data.length > 0 && (
          <div className="search-area">
            <div className="search-bar">
              <div className="d-flex align-items-center justify-content-center icon">
                <FaSearch />
              </div>
              <input
                type="search"
                name="search"
                id="search"
                placeholder="Search all pox..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                className="pr-2 border-0"
              />
            </div>
          </div>
        )}
      </div>
      <div className="body">
        {isLoading ? (
          <div className="loading">
            <BasicLoader />
          </div>
        ) : filtered_data?.length === 0 ? (
          <div className="empty">No Contact!</div>
        ) : (
          <div className="list-items">
            {filtered_data.map((item) => {
              if (!item.contact) return null;
              return (
                <button
                  key={item.chatid}
                  type="button"
                  className="list-item contact"
                  onClick={() => {
                    joinRoom(item);
                  }}
                >
                  <div>
                    <img
                      src={item.contact?.photoURL}
                      alt={item.contact?.displayName}
                      referrerPolicy="no-referrer"
                    />
                    <span className="text-truncate">
                      {item.contact?.displayName}
                    </span>
                  </div>
                  {item?.unread && <span className="unread" />}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
