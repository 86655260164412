import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { FaFilter, FaSearch } from "react-icons/fa";

const types_options = ["All", "Flat", "Plot", "Estab"];

export default function FilterNDSearch({
  filter,
  search,
  onSearchChange,
  onFilterChange,
}) {
  return (
    <>
      <div className="mb-3 d-flex gap-1">
        <div className="flex-grow-1" style={{ position: "relative" }}>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: 32,
            }}
          >
            <FaSearch size={14} />
          </div>
          <input
            type="search"
            name="search"
            id="search"
            placeholder="Search all pox..."
            value={search}
            onChange={(e) => {
              onSearchChange(e.target.value);
            }}
            className="pr-2 py-2 border-0"
            style={{
              width: "100%",
              paddingLeft: 32,
              fontSize: "0.85rem",
              height: "40px",
              outline: "none",
            }}
          />
        </div>
        <div>
          <Dropdown style={{ height: "100%" }}>
            <Dropdown.Toggle
              variant="dark"
              className="filter-toggle px-3"
              style={{ backgroundColor: "#042954", height: "40px" }}
              size="sm"
            >
              <FaFilter />
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ width: 360 }}>
              <Form.Group as={Row} controlId={"type"} className="px-2">
                <Form.Label column sm={3} className="fw-bold d-flex gap-1">
                  {"Type"}
                </Form.Label>
                <Col sm={9}>
                  <div className="d-flex gap-2 flex-wrap">
                    {types_options.map((e) => {
                      return (
                        <Form.Check
                          key={e}
                          className={`custom-radio-wrapper ${
                            filter.type === e ? "active" : ""
                          }`}
                        >
                          <Form.Check.Label
                            htmlFor={e}
                            style={{
                              fontSize: "0.65rem",
                              fontWeight: "600",
                              margin: 0,
                              lineHeight: 0,
                            }}
                          >
                            {e}
                          </Form.Check.Label>
                          <Form.Check.Input
                            type="radio"
                            name={"type"}
                            id={e}
                            value={filter.type}
                            onChange={() => {
                              onFilterChange({ type: e });
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </Form.Check>
                      );
                    })}
                  </div>
                </Col>
              </Form.Group>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}
