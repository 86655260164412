import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Popup, Marker } from "react-leaflet";
import { useSelector } from "react-redux";
import { getMarkerColor, getMarkerIcon } from "./utils";
import convertAreaUnit from "utils/convertAreaUnit";
import verified from "assets/svg/Verified.svg";

const PoxMarker = ({ data, param = null }) => {
  const location = useLocation();
  const { preview_pox, preview_opened } = useSelector((state) => state.other);

  const markers = useMemo(() => {
    return data?.map((pox) => {
      const color = getMarkerColor(param, pox);

      if (
        pox.status === "blocked" ||
        (pox.status === "inactive" &&
          pox?.inactive_reason === "temporarily_unavailable") ||
        (pox.status === "inactive" && !pox?.inactive_reason)
      )
        return null;

      return (
        <Marker
          key={pox._id}
          position={[pox.position.lat, pox.position.lng]}
          fillOpacity={1}
          weight={0}
          radius={4}
          icon={getMarkerIcon(pox, color, preview_opened, preview_pox)}
        >
          {pox.status === "inactive" && pox?.inactive_reason === "sold_out" ? (
            <Popup>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: 130, padding: 4 }}
              >
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "0.9em",
                    letterSpacing: 2.5,
                    whiteSpace: "nowrap",
                  }}
                >
                  SOLD OUT!!!
                </span>
              </div>
            </Popup>
          ) : (
            <Popup>
              <div className="popup-content">
                <div className="img-section">
                  <img
                    src={pox.images[0]?.url || "/assets/img/ucpox_ux.png"}
                    alt="pox"
                  />
                  <img src={verified} alt="verified" className="verified" />
                </div>

                <div className="popup-info">
                  {PopupContent(pox)}

                  <Link
                    to={`/${pox.type.toLowerCase()}/${pox.id}`}
                    className="btn btn-primary"
                  >
                    More Info
                  </Link>
                </div>
              </div>
            </Popup>
          )}
        </Marker>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, location]);

  return <>{markers}</>;
};

const PopupContent = (venue) => {
  switch (venue.type) {
    case "Flat":
      return (
        <>
          {venue.pay_type === "Rent" && (
            <>
              <div>
                Rent
                <b>{`${
                  venue?.currency
                } ${venue?.rent_cost?.toLocaleString()}`}</b>
              </div>
              <div>
                Deposit
                <b>{`${
                  venue?.currency
                } ${venue.deposit_cost?.toLocaleString()}`}</b>
              </div>
            </>
          )}

          {venue.pay_type === "Sale" && (
            <div>
              Sale
              <b>{`${
                venue?.currency
              } ${venue?.sale_cost?.toLocaleString()}`}</b>
            </div>
          )}
          {/* <div>
						Facing <b>{venue.facing}</b>
					</div>
					<div>
						Floor <b>{venue.floor}</b>
					</div> */}
        </>
      );
    case "Plot":
      return (
        <>
          {venue.pay_type === "Lease" && (
            <>
              <div>
                Lease Amount
                <b>{`${
                  venue?.currency
                } ${venue.lease_cost?.toLocaleString()}`}</b>
              </div>
              <div>
                Lease Period <b>{`${venue.lease_period} days`}</b>
              </div>
            </>
          )}

          {venue.pay_type === "Sale" && (
            <>
              <div>
                Sale
                <b>{`${
                  venue?.currency
                } ${venue?.sale_cost?.toLocaleString()}`}</b>
              </div>
              <div>
                Area <b>{convertAreaUnit(venue?.area, venue?.area_unit)}</b>
              </div>
            </>
          )}
        </>
      );
    case "Estab":
      return (
        <>
          {venue.pay_type === "Rent" && (
            <>
              <div>
                Rent
                <b>{`${
                  venue?.currency
                } ${venue.rent_cost?.toLocaleString()}`}</b>
              </div>
              <div>
                Deposit
                <b>{`${
                  venue?.currency
                } ${venue.deposit_cost?.toLocaleString()}`}</b>
              </div>
            </>
          )}

          {venue.pay_type === "Sale" && (
            <>
              <div>
                Sale
                <b>{`${
                  venue?.currency
                } ${venue?.sale_cost?.toLocaleString()}`}</b>
              </div>
              <div>
                Area <b>{convertAreaUnit(venue?.area, venue?.area_unit)}</b>
              </div>
            </>
          )}
        </>
      );
    default:
      return (
        <>
          {venue.pay_type === "Rent" && (
            <>
              <div>
                Rent
                <b>{`${
                  venue?.currency
                } ${venue.rent_cost?.toLocaleString()}`}</b>
              </div>
              <div>
                Deposit
                <b>{`${
                  venue?.currency
                } ${venue.deposit_cost?.toLocaleString()}`}</b>
              </div>
            </>
          )}

          {venue.pay_type === "Sale" && (
            <div>
              Sale
              <b>{`${
                venue?.currency
              } ${venue?.sale_cost?.toLocaleString()}`}</b>
            </div>
          )}
        </>
      );
  }
};

export default PoxMarker;
