import { Fragment, useState } from "react";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { FeatureGroup, Marker, Rectangle } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useMutation, useQuery } from "react-query";
import { Tooltip } from "react-bootstrap";
import { GeofenceFormWebView } from "components/Forms";
import { createClusterCustomIcon } from "components/MapView/utils";
import PoxMarker from "components/MapView/PoxMarker";
import instance from "apis/axios";
import LocationSvg from "assets/svg/marker.svg";
import "leaflet-draw/dist/leaflet.draw.css";

const LocationIcon = L.icon({
  iconUrl: LocationSvg,
  iconRetinaUrl: LocationSvg,
  iconSize: [20, 20],
  className: `marker `,
});

export default function GeofenceMap({ token }) {
  const [fence, setFence] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };

  const { isLoading, data, refetch } = useQuery({
    queryKey: ["web-view-geofences"],
    queryFn: async () => {
      const res = await instance.get("/geofences", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data?.geofences;
    },
    enabled: !!token,
    staleTime: Infinity,
  });

  const onSuccess = () => {
    refetch();
  };

  const editMutation = useMutation({
    mutationFn: async ({ id, payload }) => {
      await instance.patch(`/geofences/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess,
  });

  const deleteMutation = useMutation({
    mutationFn: async (id) => {
      await instance.delete(`/geofences/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess,
  });

  const onCreated = (e) => {
    const newFence = {
      id: e.layer._leaflet_id,
      bounds: [
        [e.layer._bounds._southWest.lat, e.layer._bounds._southWest.lng],
        [e.layer._bounds._northEast.lat, e.layer._bounds._northEast.lng],
      ],
      layer_type: e.layerType,
      color: e.layer.options?.color,
    };
    setShow(true);
    setFence(newFence);
  };

  const onEdited = (e) => {
    e.layers.eachLayer((a) => {
      editMutation.mutate({
        id: a.options.id ?? a._leaflet_id,
        payload: {
          bounds: [
            [a._bounds._southWest.lat, a._bounds._southWest.lng],
            [a._bounds._northEast.lat, a._bounds._northEast.lng],
          ],
        },
      });
    });
  };

  const onDeleted = (e) => {
    e.layers.eachLayer((a) => {
      deleteMutation.mutate(a.options.id ?? a._leaflet_id);
    });
  };

  if (isLoading) return null;

  return (
    <>
      <FeatureGroup>
        <EditControl
          position="bottomright"
          onCreated={onCreated}
          onEdited={onEdited}
          onDeleted={onDeleted}
          draw={{
            marker: false,
            circle: false,
            circlemarker: false,
            polygon: false,
            polyline: false,
          }}
        />
        {data?.map((fence) => (
          <Fragment key={fence.id}>
            <Rectangle bounds={fence.bounds} color={fence.color} id={fence.id}>
              <Tooltip>
                <span style={{ color: fence.color }}>{fence.name}</span>
              </Tooltip>
            </Rectangle>
            <Marker
              disabled
              icon={LocationIcon}
              position={[
                (fence.bounds[0][0] + fence.bounds[1][0]) / 2,
                (fence.bounds[0][1] + fence.bounds[1][1]) / 2,
              ]}
            >
              <Tooltip>
                <span style={{ color: fence.color }}>{fence.name}</span>
              </Tooltip>
            </Marker>
          </Fragment>
        ))}
      </FeatureGroup>

      {data?.map((fence) => (
        <MarkerClusterGroup
          key={fence.id}
          showCoverageOnHover
          spiderfyOnMaxZoom
          iconCreateFunction={createClusterCustomIcon()}
        >
          <PoxMarker data={fence.poxes} />
        </MarkerClusterGroup>
      ))}

      {show && (
        <GeofenceFormWebView
          {...{ show: show && fence, handleClose, fence, token }}
        />
      )}
    </>
  );
}
