import {
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";

export default function InputRender({ formik, type, id, label, ...props }) {
  if (type === "input-select") {
    return (
      <Form.Group as={Row} controlId={id} className="mb-4">
        <Form.Label column sm={3} className="fw-bold d-flex gap-1">
          {props.required && <span className="text-danger">*</span>}
          {label}
        </Form.Label>
        <Col sm={9}>
          <InputGroup className={props.reverse ? "reverse" : ""}>
            <Form.Control
              type={props.input_type}
              name={id}
              placeholder={props.placeholder}
              value={formik.values?.[id]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched?.[id] && formik.errors[id]}
            />
            <DropdownButton
              variant="secondary"
              title={formik.values[props.options_id]}
            >
              {props.options.map((e) => {
                return (
                  <Dropdown.Item
                    key={e}
                    id={e}
                    onClick={() => {
                      formik.setFieldValue(props.options_id, e);
                    }}
                  >
                    {e}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </InputGroup>

          {formik.touched?.[id] && formik.errors[id] && (
            <span
              className="text-danger"
              style={{ fontSize: ".875em", marginTop: ".2rem", width: "100%" }}
            >
              {formik.errors[id]}
            </span>
          )}
        </Col>
      </Form.Group>
    );
  }
  if (type === "select") {
    return (
      <Form.Group as={Row} controlId={id} className="mb-4">
        <Form.Label column sm={3} className="fw-bold d-flex gap-1">
          {props.required && <span className="text-danger">*</span>}
          {label}
        </Form.Label>
        <Col sm={9}>
          <div className="d-flex gap-2 flex-wrap">
            {props?.options.map((e) => {
              return (
                <Form.Check
                  key={e}
                  className={`custom-radio-wrapper ${
                    formik.values[id] === e ? "active" : ""
                  }`}
                >
                  <Form.Check.Label
                    htmlFor={id}
                    style={{
                      fontSize: "0.85rem",
                      fontWeight: "500",
                      margin: 0,
                      lineHeight: 0,
                    }}
                  >
                    {e}
                  </Form.Check.Label>
                  <Form.Check.Input
                    type="radio"
                    name={id}
                    id={e}
                    value={formik.values[id]}
                    onChange={() => {
                      formik.setFieldValue(id, e);
                    }}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched[id] && formik.errors[id]}
                    style={{ cursor: "pointer" }}
                  />
                </Form.Check>
              );
            })}
          </div>

          {formik.touched?.[id] && formik.errors[id] && (
            <span
              className="text-danger"
              style={{ fontSize: ".875em", marginTop: ".2rem", width: "100%" }}
            >
              {formik.errors[id]}
            </span>
          )}
        </Col>
      </Form.Group>
    );
  }

  return (
    <Form.Group as={Row} controlId={id} className="mb-4">
      <Form.Label column sm={3} className="fw-bold d-flex gap-1">
        {props.required && <span className="text-danger">*</span>}
        {label}
      </Form.Label>
      <Col sm={9}>
        <Form.Control
          type={type}
          {...props}
          name={id}
          value={formik.values?.[id]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={formik.touched?.[id] && formik.errors[id]}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors[id]}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
}
