import { useEffect, useState } from "react";
import { ProgressBar, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { FaTrashAlt, FaUpload } from "react-icons/fa";
import { customRequest, onRemove } from "utils/firebase";

const formatMap = new Map([
  [
    "image",
    {
      accept: {
        "image/jpg": [],
        "image/jpeg": [],
        "image/png": [],
      },
      ext: ["jpg", "jpeg", "png"],
    },
  ],
]);

const max = 4;
const format = "image";

export default function ImageUpload({
  value = [],
  onUpload,
  error,
  error_message,
}) {
  const [img_data, setData] = useState(value);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: formatMap.get(format)?.accept,
    maxFiles: max,
    disabled: img_data.length >= max,
    multiple: true,
  });

  const handleDelete = (uid) => {
    setData((prev) => prev.filter((e) => e.uid !== uid));
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.slice(0, max - img_data.length).forEach((file) => {
        const uid = uuidv4();
        const name = file.name;
        const error = null;
        const progress = 1;
        const status = "uploading";

        setData((prev) => [
          ...prev,
          { uid, name, error, status, progress, size: file.size },
        ]);

        const onError = ({ error }) => {
          setData((prev) =>
            prev.map((e) =>
              e.uid === uid ? { ...e, status: "error", progress: 0, error } : e
            )
          );
        };

        const onSuccess = ({ pathname, url }) => {
          setData((prev) =>
            prev.map((e) =>
              e.uid === uid
                ? {
                    ...e,
                    pathname,
                    url,
                    status: "successs",
                    progress: 100,
                    error: null,
                  }
                : e
            )
          );
        };

        const onProgress = (p) => {
          setData((prev) =>
            prev.map((e) => (e.uid === uid ? { ...e, progress: p } : e))
          );
        };

        customRequest({ file, onError, onSuccess, onProgress });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  useEffect(() => {
    onUpload?.(img_data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [img_data]);

  return (
    <div className="mb-4">
      <div
        style={{
          width: "100%",
          cursor: "pointer",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
      >
        <span
          style={{
            fontSize: "1rem",
            fontWeight: "bold",
            display: "block",
            marginBottom: "0.25rem",
          }}
        >
          Pox Images
        </span>

        <div
          {...getRootProps({
            style: {
              width: "100%",
              height: 150,
              borderStyle: "dashed",
              borderColor: "#DDDDDD",
              backgroundColor: "#FAFAFA",
              cursor: "pointer",
              borderRadius: "8px",
              padding: "16px",
              display: img_data.length >= max ? "none" : "block",
            },
          })}
        >
          <input {...getInputProps()} />
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <FaUpload size={32} color="#4285F4" />
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingHorizontal: "0.5rem",
                  textAlign: "center",
                  fontSize: "0.65rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.25rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>
                  Drag & Drop {format}
                  {max > 1 ? "s" : ""} here or Click to browse files
                </span>
                <span>
                  Can upload maximum {max} {format}
                  {max > 1 ? "s" : ""}
                </span>
                <span>
                  File format- {formatMap.get(format)?.ext?.join(", ")}
                </span>
              </div>
            </div>
          </div>
        </div>

        <ul className="mt-2 p-0" style={{ width: "100%", listStyle: "none" }}>
          {img_data.map(
            ({ uid, name, url, error: file_error, status, progress }) => {
              return (
                <li
                  key={uid}
                  style={{
                    width: "100%",
                    transition: "all 0.3s ease-in-out",
                    marginBottom: "0.25rem",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      gap: "1rem",
                      padding: "0.25rem 0.5rem",
                      border: "1px solid #DDDDDD",
                    }}
                  >
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{
                        flexGrow: 1,
                        overflow: "hidden",
                        padding: "1px",
                        transition: "all 0.3s ease-in-out",
                        fontSize: "0.75rem",
                      }}
                    >
                      {name}
                    </a>
                    {!url ? (
                      <Spinner animation="border" size="sm" variant="primary" />
                    ) : (
                      <button
                        type="button"
                        aria-label="delete"
                        onClick={() => {
                          onRemove({ url, status }, handleDelete(uid));
                        }}
                        className="btn p-0"
                      >
                        <FaTrashAlt size={14} color="rgb(239 68 68)" />
                      </button>
                    )}
                  </div>
                  {progress > 0 && progress < 100 && status === "uploading" && (
                    <ProgressBar
                      now={progress}
                      label={`${progress}%`}
                      variant="info"
                    />
                  )}
                  {file_error && (
                    <span
                      style={{
                        display: "block",
                        width: "100%",
                        fontSize: "0.5rem",
                        marginTop: "0.125rem",
                        color: "rgb(239 68 68)",
                      }}
                    >
                      {file_error?.message ?? "There was an error"}
                    </span>
                  )}
                </li>
              );
            }
          )}
        </ul>

        {error && (
          <span
            style={{
              display: "block",
              width: "100%",
              fontSize: "0.5rem",
              marginTop: "0.125rem",
              color: "rgb(239 68 68)",
            }}
          >
            {typeof error_message === "string"
              ? error_message
              : "There was an error"}
          </span>
        )}
      </div>
    </div>
  );
}
