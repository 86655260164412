import "./styles.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { groups, initialValues, validationSchema } from "./config";
import { useAxiosPrivate } from "hooks";
import LocationAndDescription from "./LocationAndDescription";
import InputRender from "./InputRender";
import ImageUpload from "./ImageUpload";

export default function PoxForm({ pre_data }) {
  const [isCreating, setIsCreating] = useState(false);
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues: pre_data ?? initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleOnFinish(values);
    },
  });

  useEffect(() => {
    if (formik.values.type) {
      formik.setFieldValue("pay_type", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.type]);

  function handleCancel() {
    // eslint-disable-next-line no-alert
    const res = window.confirm(
      "Are you sure you want to leave this page? Changes would be lost"
    );
    if (res) navigate(-1);
  }

  async function handleOnFinish(values) {
    let stillUploading = false;
    let failedUpload = false;

    values.images?.forEach((e) => {
      if (e.status === "uploading") stillUploading = true;
      if (e.status === "error") failedUpload = true;
    });

    if (stillUploading)
      return toast.error(
        "Please wait for pending uploads to complete or cancel and retry if taking too long"
      );

    if (failedUpload)
      return toast.error("Please remove failed image upload and try again");

    const {
      // Flat
      room_config,
      bath,
      floor,
      facing,

      // Plot
      plot_config,

      // Rent
      rent,
      deposit,

      // Sale
      sale,

      // Lease
      lease,
      lease_duration,

      // Estab
      turnover,

      ownership,
      ...rest
    } = values;
    let payload = { ...rest, ownership: ownership === "Self" ?? false };

    switch (values.type) {
      case "Flat":
        payload = { ...payload, room_config, bath, floor, facing };
        break;

      case "Plot":
        payload = { ...payload, plot_config };
        break;

      case "Estab":
        payload = { ...payload, turnover };
        break;

      default:
        break;
    }

    switch (values.pay_type) {
      case "Rent":
        payload = { ...payload, rent, deposit };
        break;

      case "Sale":
        payload = { ...payload, sale };
        break;

      case "Lease":
        payload = { ...payload, lease, lease_duration };
        break;

      default:
        break;
    }

    setIsCreating(true);
    try {
      if (pre_data) {
        const res = await axios.patch(`/poxes/${pre_data.id}`, payload);
        queryClient.invalidateQueries(["pox", { id: pre_data.id }]);
        toast.success(res.data.message);
        navigate(-1);
      } else {
        const res = await axios.post(`/poxes`, payload);
        toast.success(res.data.message);
        navigate("/", { replace: true });
      }
    } catch (error) {
      toast.error(error?.response?.data.message || error.message);
    } finally {
      setIsCreating(false);
    }

    return undefined;
  }

  useEffect(() => {
    return () => {
      formik.resetForm();
      setIsCreating(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pre_data) {
      formik.setValues({ ...pre_data });
    } else {
      formik.setValues({ ...initialValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pre_data]);

  return (
    <>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={6}>
            <ImageUpload
              value={formik.values.images}
              onUpload={(e) => formik.setFieldValue("images", e)}
            />

            {groups.main.map((e) => (
              <InputRender key={e.id} formik={formik} {...e} />
            ))}

            {groups[formik.values.type]?.map((e) => (
              <InputRender key={e.id} formik={formik} {...e} />
            ))}

            {groups[formik.values.pay_type]?.map((e) => (
              <InputRender key={e.id} formik={formik} {...e} />
            ))}

            {formik.values.type === "Estab" && formik.values.pay_type && (
              <InputRender
                formik={formik}
                {...{
                  id: "turnover_cost",
                  label: "Turnover",
                  type: "input-select",
                  input_type: "number",
                  placeholder: "e.g. 1000",
                  options_id: "currency",
                  options: ["INR", "AED", 'USD'],
                  reverse: true,
                }}
              />
            )}

            <div
              className="mt-3 pt-4"
              style={{ borderTop: "1px solid rgb(33, 37, 41, .25)" }}
            >
              {groups.contact.map((e) => (
                <InputRender key={e.id} formik={formik} {...e} />
              ))}
            </div>
          </Col>
          <Col md={6}>
            <LocationAndDescription formik={formik} />

            <div className="d-flex justify-content-end gap-2">
              <Button
                variant="outline-danger"
                className="px-5"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button variant="primary" className="px-5" type="submit">
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Form>

      {isCreating && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 100,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            display: "grid",
            placeItems: "center",
          }}
        >
          <div
            style={{
              width: 360,
              padding: 24,
              backgroundColor: "white",
              borderRadius: 16,
              display: "inherit",
              placeItems: "center",
              gap: 8,
              textAlign: "center",
            }}
          >
            <span>
              {pre_data
                ? "Updating your Pox, please wait..."
                : "Creating your Pox, please wait..."}
            </span>
            <Spinner
              animation="border"
              variant="primary"
              role="status"
              size={64}
            />
          </div>
        </div>
      )}
    </>
  );
}
