import "./styles.scss";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { endGuidedTour, setGuidedTourStage } from "redux/features/other.slice";

const TOUR_MAP = {
  1: { content: "Easily add properties to your portfolio." },
  2: { content: "Explore various types of properties available." },
  3: { content: "Refine your search with easy filtering options." },
  4: { content: "Quickly grasp property prices with color-coded icons." },
  5: { content: "Access Messages and Upgrading easily from the menu." },
  6: { content: "Track property insights with detailed stats." },
};

export default function GuidedTourWrapper({ className = "", children, stage }) {
  const dispatch = useDispatch();
  const { guided_tour } = useSelector((state) => state.other);

  if (
    guided_tour.init &&
    guided_tour.stage !== 0 &&
    stage === guided_tour.stage
  )
    return (
      <div className={`guided-tour ${className} stage-${stage}`}>
        <div className="guided-tour-wrapper">
          {children}

          <div className="guided-tour-content">
            <div>
              <button
                type="button"
                title="close"
                onClick={() => {
                  dispatch(endGuidedTour());
                }}
              >
                <MdClose />
              </button>
            </div>
            <div>{TOUR_MAP[stage].content}</div>
            <div>
              <button
                type="button"
                onClick={() => {
                  dispatch(endGuidedTour());
                }}
              >
                Skip
              </button>
              <button
                type="button"
                onClick={() => {
                  dispatch(setGuidedTourStage(guided_tour.stage + 1));
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>

        <div className="guided-tour-mask" />
      </div>
    );

  return <div className={className}>{children}</div>;
}
