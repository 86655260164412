import { useEffect } from "react";
import { useMapEvents } from "react-leaflet";

const LAST_MAP_ZOOM_LEVEL = "LAST_MAP_ZOOM_LEVEL";
const PersistMapZoom = () => {
  const mapEvents = useMapEvents({
    zoomend: () => {
      localStorage.setItem(LAST_MAP_ZOOM_LEVEL, mapEvents.getZoom());
    },
  });

  useEffect(() => {
    const savedZoom = localStorage.getItem(LAST_MAP_ZOOM_LEVEL);
    mapEvents.setZoom(+savedZoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default PersistMapZoom;
