import { configureStore } from '@reduxjs/toolkit';
import authSlice from './features/auth.slice';
import mapSlice from './features/map.slice';
import otherSlice from './features/other.slice';

export const store = configureStore({
	reducer: {
		auth: authSlice,
		map: mapSlice,
		other: otherSlice,
	},
});
