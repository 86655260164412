import { Fragment } from "react";
import { useAxiosPrivate } from "hooks";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getUser } from "redux/features/auth.slice";
import { useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { MapFloatingListing } from "components";
import GeofenceCard from "./GeofenceCard";
import "./styles.scss";

export default function GeofencePage() {
  const user = useSelector(getUser);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { geofence_rerender } = useSelector((state) => state.other);

  const { isLoading, data } = useQuery(
    ["geofences"],
    async () => {
      const res = await axiosPrivate.get("/geofences");
      return res.data?.geofences;
    },
    { enabled: !!user }
  );

  if (isLoading || geofence_rerender) return null;

  return (
    <div className="geofence">
      <Fragment>
        <MapFloatingListing
          className="Geo"
          allowOwnershipFilter
          Heading={({ total = 0 }) => (
            <>
              <h1 className="fs-6 fw-bold mb-0" style={{ lineHeight: "unset" }}>
                Geofeneces
              </h1>
              <Badge
                bg="primary"
                className="badge"
                style={{ lineHeight: "unset" }}
              >
                {total}
              </Badge>
            </>
          )}
          Content={({ pdata }) => (
            <div className="content">
              {pdata.length > 0 && (
                <ul className="p-0">
                  {pdata.map((geofence) => (
                    <li key={geofence._id}>
                      <GeofenceCard geofence={geofence} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          onBack={() => {
            navigate(-1);
          }}
          isLoading={isLoading}
          data={data}
        />
      </Fragment>
    </div>
  );
}
