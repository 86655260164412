import "./styles.scss";
import { useQuery } from "react-query";
import GeofenceCard from "./GeofenceCard";
import instance from "apis/axios";
import { Fragment } from "react";
import { MapFloatingListing } from "components";
import { Badge } from "react-bootstrap";

export default function GeofenceListing({ token }) {
  const { isLoading, data = [] } = useQuery(
    ["geofences"],
    async () => {
      const res = await instance.get("/geofences", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data?.geofences;
    },
    { enabled: !!token }
  );

  return (
    <div className="geofence">
      <Fragment>
        <MapFloatingListing
          className="Geo"
          allowOwnershipFilter
          Heading={({ total = 0 }) => (
            <>
              <h1 className="fs-6 fw-bold mb-0" style={{ lineHeight: "unset" }}>
                Geofeneces
              </h1>
              <Badge
                bg="primary"
                className="badge"
                style={{ lineHeight: "unset" }}
              >
                {total}
              </Badge>
            </>
          )}
          Content={({ pdata }) => (
            <div className="content">
              {pdata.length > 0 && (
                <ul className="p-0">
                  {pdata.map((geofence) => (
                    <li key={geofence._id}>
                      <GeofenceCard geofence={geofence} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          onBack={null}
          isLoading={isLoading}
          data={data}
        />
      </Fragment>
    </div>
  );
}
