import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { reverseGeocoding } from "apis/open-cage";
import { Map } from "components";
import { useDebounce } from "hooks";

export default function LocationAndDescription({ formik }) {
  const [isGenerating, setIsGenerating] = useState(false);

  async function generateAddress() {
    setIsGenerating(true);
    try {
      const res = await reverseGeocoding(formik.values.position);
      formik.setFieldValue("address", res?.formatted);
    } catch (error) {
      toast.error(error.message, { style: { fontSize: "0.5rem" } });
    } finally {
      setIsGenerating(false);
    }
  }

  useDebounce(
    () => {
      generateAddress();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [formik.values.position],
    1000
  );

  return (
    <>
      <div className="d-flex align-items-center gap-2 mb-2">
        <span className="fs-6 fw-bold">Pick Location</span>
        <span className="flex-grow-1 bg-dark" style={{ height: 0.5 }} />
      </div>

      {/* map select area */}
      <div className="d-flex flex-direction-column">
        <div style={{ width: "100%", height: "350px", zIndex: 1 }}>
          <Map
            position={formik.values.position}
            setPosition={(new_position) => {
              formik.setFieldValue("position", new_position);
            }}
          />
        </div>
      </div>

      <div className="mb-5">
        <div className="mb-4">
          <div className="d-flex justify-content-end mt-2">
            <Button
              size="sm"
              variant="link"
              disabled={isGenerating}
              onClick={generateAddress}
              className="p-0"
            >
              {isGenerating ? "Generating..." : "Generate Address"}
            </Button>
          </div>
          <Form.Group as={Row} controlId="address">
            <Form.Label column sm={2} className="fw-bold d-flex gap-1">
              <span className="text-danger">*</span>
              Address
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Enter Address"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.address && formik.errors.address}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.address}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </div>

        <Form.Group as={Row} controlId="description" className="mb-4">
          <Form.Label column sm={2} className="fw-bold d-flex gap-1">
            {/* <span className="text-danger">*</span> */}
            Description
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter Description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.description && formik.errors.description
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.description}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </div>
    </>
  );
}
