import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { FiMenu } from "react-icons/fi";
import { FaTimes } from "react-icons/fa";
import { ReactComponent as SavedPoxesSvg } from "assets/svg/nav/saved-poxes.svg";
import { ReactComponent as SavedNotesSvg } from "assets/svg/nav/saved-notes.svg";
import { ReactComponent as EarningsSvg } from "assets/svg/nav/earnings.svg";
import { ReactComponent as SettingsSvg } from "assets/svg/nav/settings.svg";
import { ReactComponent as PrivacySvg } from "assets/svg/nav/privacy.svg";
import { ReactComponent as AboutUsSvg } from "assets/svg/nav/about-us.svg";
import { ReactComponent as ContactUsSvg } from "assets/svg/nav/contact-us.svg";
import UserSection from "./UserSection";

const navItems = [
  [
    { id: "saved-poxes", label: "Saved Poxes", icon: SavedPoxesSvg },
    { id: "saved-notes", label: "Saved Notes", icon: SavedNotesSvg },
    { id: "earnings", label: "Earnings", icon: EarningsSvg, disabled: true },
    { id: "settings", label: "Settings", icon: SettingsSvg, disabled: true },
  ],
  [
    { id: "privacy", label: "Privacy Policies", icon: PrivacySvg },
    { id: "about-us", label: "About Us", icon: AboutUsSvg },
    { id: "contact-us", label: "Contact Us", icon: ContactUsSvg },
  ],
];

export default function Menu({ onChange }) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { loading } = useSelector((state) => state.auth);

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    onChange(isOpen);
  }, [isOpen, onChange]);

  return (
    <>
      <div className="menu-wrapper">
        <button
          type="button"
          title="menu"
          className="btn text-white"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <FiMenu />
        </button>

        <div className={isOpen ? "menu" : "menu close"}>
          <div className="mask" onClick={() => setIsOpen(false)} />
          <div className="content">
            <div className="header">
              <UserSection />

              <button
                type="button"
                title="close button"
                className="btn btn-sm close-btn"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <FaTimes />
              </button>
            </div>

            <div className="body">
              {navItems.map((navitems, idx) => {
                if (idx === 0 && loading)
                  return (
                    <div key={idx} className="menu-nav">
                      <Spinner animation="border" />
                    </div>
                  );
                return (
                  <div key={idx} className="menu-nav">
                    {navitems.map((item) => {
                      if (item.disabled) return null;
                      return (
                        <Link
                          key={item.id}
                          to={`/${item.id}`}
                          className="menu-nav-item"
                        >
                          <item.icon />
                          <span>{item.label}</span>
                        </Link>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
