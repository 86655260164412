import { useURLQuery } from "hooks";
import { useQuery } from "react-query";
import axios from "apis/axios";
import GeofenceWebView from "./Geofence";

export default function MobileWebView() {
  const url_queries = useURLQuery();
  const target = url_queries.get("target");
  const auth_token = url_queries.get("auth_token");

  const { isError, error } = useQuery({
    queryKey: ["verify_user_details"],
    queryFn: async () => {
      const res = await axios.post("/auth", {
        token: auth_token,
      });
      return res.data;
    },
    enabled: !!auth_token,
  });

  if (!auth_token || isError || error) {
    console.log("ERROR", error);
    return (
      <div>
        <h1>Mobile WebView</h1>
        <p>You are not authenticated to access this page</p>
      </div>
    );
  }

  switch (target) {
    case "geofence":
      return <GeofenceWebView auth_token={auth_token} />;
    default:
      return (
        <div>
          <h1>Mobile WebView</h1>
          <p>No target provided</p>
        </div>
      );
  }
}
