import { useState } from "react";
import { ReactComponent as InfoSvg } from "assets/svg/info.svg";
import { Modal } from "react-bootstrap";

export default function GeofenceInfo() {
  const [iShow, setIShow] = useState(false);
  const openInfo = () => setIShow(true);
  const closeInfo = () => setIShow(false);

  return (
    <div className="geofence-info">
      <button className="info-btn" onClick={openInfo}>
        <InfoSvg />
      </button>

      <Modal
        show={iShow}
        onHide={closeInfo}
        centered
        size="sm"
        className="info-modal"
      >
        <Modal.Header
          closeButton
          className="fw-bold py-3 d-flex justify-content-center border-0"
        >
          Mark a region on map as Geofence and get notified when any new pox is
          added in that Geofence region.
        </Modal.Header>
        <Modal.Body
          className="border-0 pt-0"
          style={{ fontSize: "0.85rem", color: "#1F1F1F80" }}
        >
          <span className="fw-bold d-block">Steps</span>
          <ul className="mb-0">
            {[
              "Pinch in-out zoom and move to region you are interested in.",
              "Select rectangle button, now on the area you are interested in, tap and drag to adjust the size of shape.",
              "Fill in the required details, Click on save to add the Geofence.",
              "The Geofence list will be updated with the newly added Geofence.",
            ].map((e, idx) => {
              return <li key={idx}>{e}</li>;
            })}
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
}
