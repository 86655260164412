import "./styles.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { FiBell } from "react-icons/fi";
import { useAxiosPrivate } from "hooks";
import NotificationsDropdown from "./Dropdown";

export default function Notification() {
  const { user } = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const { data: unread } = useQuery({
    queryKey: ["unread-notifications", { uid: user?.uid }],
    queryFn: async () => {
      const res = await axiosPrivate("notifications/unread");
      return res.data;
    },
    enabled: !!user,
  });

  return (
    <div className="notification" style={{ zIndex: isOpen ? 1001 : 401 }}>
      <button
        className="btn p-0 text-white"
        disabled={!user}
        onClick={() => setIsOpen(true)}
      >
        <FiBell />
        {Boolean(unread) && unread > 0 && (
          <span className="unread">{unread}</span>
        )}
      </button>

      {user && isOpen && (
        <NotificationsDropdown close={() => setIsOpen(false)} />
      )}
    </div>
  );
}
