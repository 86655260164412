import { ReactComponent as WarnIcon } from "assets/svg/warn.svg";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { useAxiosPrivate } from "hooks";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import socket from "apis/socket";

const DeleteModal = ({
  handleDeleteModal,
  room,
  selected,
  setMessageList,
  setSelected,
}) => {
  const axios = useAxiosPrivate();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deleteChat = useMutation({
    mutationFn: async () => {
      await axios.delete(`/chats/${room.chatid}`);
    },
    onSuccess() {
      handleDeleteModal();
      queryClient.invalidateQueries(["contacts"]);
      navigate("/messages/inbox", { replace: true });
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const deleteMessages = useMutation({
    mutationFn: async () => {
      await axios.delete(
        `/chats/${room.chatid}/delete-messages?messageids=${JSON.stringify(
          selected
        )}`
      );
    },
    onSuccess() {
      setMessageList((prevMessageList) => {
        return prevMessageList.filter((message) => {
          if (selected.includes(message._id)) {
            return false;
          }
          return true;
        });
      });
      setSelected([]);
      socket.emit("update_messages", room);
      handleDeleteModal();
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleDelete = () => {
    if (selected.length > 0) {
      deleteMessages.mutate(selected);
    } else {
      deleteChat.mutate();
    }
  };

  return (
    <div className="message-modal">
      <div className="message-wrapper">
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={() => handleDeleteModal()}
        >
          <CloseIcon />
        </div>

        <div className="d-flex justify-content-center" style={{}}>
          <WarnIcon />
        </div>
        <div style={{ color: "#1F1F1F" }}>
          <p className="text-center fs-6 mb-0 mt-2">
            {selected.length > 0
              ? `Delete ${selected.length} messages`
              : "Delete chat"}
          </p>
          <p className="text-center fs-6 mb-0">
            Are you sure you want to delete?
          </p>
        </div>

        <div
          className="mt-4"
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 4 }}
        >
          <button
            className="btn"
            onClick={() => handleDeleteModal()}
            disabled={deleteChat.isLoading || deleteMessages.isLoading}
          >
            cancel
          </button>
          <button
            className="btn"
            style={{
              backgroundColor: "#EA4335",
              padding: "7px 16px",
              color: "white",
              borderRadius: 10,
            }}
            onClick={handleDelete}
            disabled={deleteChat.isLoading || deleteMessages.isLoading}
          >
            {deleteChat.isLoading || deleteMessages.isLoading
              ? "Deleting..."
              : selected.length > 0
              ? "Delete"
              : "Delete Chat"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
