import { useMemo } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export default function Pagination({
  onClick,
  current_page,
  total_pages,
  btnDependecies = [],
}) {
  const Button = ({ id: bID }) =>
    useMemo(() => {
      if (isNaN(bID)) return null;
      return (
        <button
          type="button"
          className={`btn fs-6 w-6 h-6 btn-sm ${
            current_page === bID
              ? "fw-semibold bg-primary text-white"
              : "border fw-medium"
          }`}
          onClick={() => {
            onClick?.(bID);
          }}
        >
          {bID}
        </button>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_page, ...btnDependecies]);

  return (
    <>
      <div className="d-flex gap-1 justify-content-end pt-3 text-sm lg:text-base">
        <button
          type="button"
          title="prev"
          className={`btn w-5 h-6 fw-semibold bg-secondary text-white btn-sm`}
          disabled={current_page === 1}
          onClick={() => {
            onClick?.(current_page - 1);
          }}
        >
          <FaChevronLeft />
        </button>
        {total_pages <= 5 ? (
          Array.from(Array(total_pages).keys())?.map((e) => (
            <Button key={e} id={e + 1} />
          ))
        ) : (
          <>
            <Button id={1} />
            {current_page === 1 || current_page - 1 < 2 ? (
              <>
                <Button id={2} />
                <Button id={3} />
              </>
            ) : current_page === total_pages ||
              total_pages - current_page < 2 ? (
              <>
                <Button id={total_pages - 2} />
                <Button id={total_pages - 1} />
              </>
            ) : (
              <>
                <Button id={current_page - 1} />
                <Button id={current_page} />
                <Button id={current_page + 1} />
              </>
            )}
            <Button id={total_pages} />
          </>
        )}
        <button
          type="button"
          title="next"
          className={`btn w-5 h-6 fw-semibold bg-secondary text-white btn-sm`}
          disabled={current_page === total_pages}
          onClick={() => {
            onClick?.(current_page + 1);
          }}
        >
          <FaChevronRight />
        </button>
      </div>
    </>
  );
}
