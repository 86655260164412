import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { FaChevronLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FiSend } from "react-icons/fi";
import { v4 as uuid } from "uuid";
import { uploadChatFile } from "utils/firebase";
import { useAxiosPrivate } from "hooks";
import { BasicLoader } from "components";
import { useNavigate, useParams } from "react-router-dom";
import socket from "apis/socket";
import ScrollToBottom from "react-scroll-to-bottom";
import ChatBodyList from "./ChatBodyList";
import DeleteModal from "./DeleteModal";
import { ReactComponent as DeleteChatIcon } from "assets/svg/deleteChat.svg";

export default function ChatBoxPage() {
  const [messageList, setMessageList] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [room, setRoom] = useState(null);
  const [isJoining, setIsJoining] = useState(true);
  const { chatid } = useParams();
  const navigate = useNavigate();

  const joinRoom = async ({ chatid }) => {
    try {
      const res = await axiosPrivate(`chats/${chatid}`);
      setRoom(res.data);
    } catch (error) {
      navigate(-1, { replace: true });
    } finally {
      setIsJoining(false);
    }
  };

  const handleDeleteModal = () => {
    setShowModal(!showModal);
  };

  const sendMessage = (newMessage) => {
    socket.emit(
      "send_message",
      {
        roomid: room?.roomid,
        chatid: room?.chatid,
        ...newMessage,
      },
      (res) => {
        setMessageList(res);
      }
    );
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;

    Array.from(files).forEach((file) => {
      if (file) {
        const id = uuid();
        handleFinish({
          id,
          file: {
            uid: uuid(),
            name: file.name,
            size: file.size,
            type: file.type,
            url: URL.createObjectURL(file),
            pathname: file.name,
          },
        });

        uploadChatFile(
          {
            file,
            onSuccess: ({ pathname, url }) => {
              handleFinish({
                id,
                allow: true,
                file: {
                  uid: uuid(),
                  name: file.name,
                  size: file.size,
                  type: file.type,
                  url,
                  pathname,
                },
              });
            },
            onError: (error) => toast.error(error?.response?.data?.message),
          },
          room.chatid
        );
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData(e.target);
    const chat = formdata.get("chat");
    handleFinish({
      text: chat,
    });
    e.target.reset();
  };

  const handleFinish = (values) => {
    if (values.text) {
      const newMessage = {
        id: uuid(),
        author: user?.uid,
        content: values.text,
        content_type: "text",
        created_at: Date.now(),
      };
      setMessageList((prev) => [...prev, newMessage]);
      sendMessage(newMessage);
    }

    if (values.file) {
      const { uid, name, size, type, url, pathname } = values.file;
      const newMessage = {
        id: values.id,
        author: user.uid,
        content: name,
        content_type: "file",
        meta: {
          uid,
          name,
          size,
          file_type: type,
          url,
          pathname,
        },
        created_at: Date.now(),
      };
      setMessageList((prev) =>
        prev[prev.length - 1].id !== newMessage.id
          ? [...prev, newMessage]
          : prev
      );
      values?.allow && sendMessage(newMessage);
    }
  };

  const markAsSeen = async (chatid) => {
    try {
      await axiosPrivate.patch(`/chats/${chatid}`);
      queryClient.invalidateQueries(["unread-messages", { uid: user?.uid }]);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSelect = (message_id) => {
    setSelected((prev) => {
      if (prev.includes(message_id)) {
        const res = prev.filter((e) => e !== message_id);
        return res;
      }
      return [...prev, message_id];
    });
  };

  useEffect(() => {
    if (room) {
      if (messageList.length === 0) {
        setMessageList(room.messages);
        socket.emit("join_room", room?.roomid);
        markAsSeen(room?.chatid);
      }

      socket.on("receive_message", (data) => {
        markAsSeen(room?.chatid);
        setMessageList(data);
      });

      socket.on("messages_updated", (data) => {
        setMessageList(data);
      });
    } else {
      if (!chatid) {
        toast(`Chat Room doesn't exits`);
        navigate(-1, { replace: true });
      }
      joinRoom({ chatid });
    }

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("receive_message");
      setMessageList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, socket, chatid]);

  if (isJoining)
    return (
      <div className="loading">
        <BasicLoader />
      </div>
    );

  return (
    <div className="chat-box">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="header">
          <div>
            <button
              type="button"
              title="close button"
              className="btn btn-sm btn-dark py-1 mb-0"
              onClick={() => {
                navigate("/messages/inbox", { replace: true });
              }}
            >
              <FaChevronLeft />
            </button>
            <span className="text-truncate">{room.contact.displayName}</span>
          </div>

          <div
            className="delete-btn"
            style={{ cursor: "pointer" }}
            onClick={handleDeleteModal}
          >
            <DeleteChatIcon />
            {selected.length > 0 && <span>{selected.length}</span>}
          </div>
        </div>
      </div>

      {showModal && (
        <DeleteModal
          handleDeleteModal={handleDeleteModal}
          room={{ roomid: room.id, chatid: room.chatid }}
          selected={selected}
          setMessageList={setMessageList}
          setSelected={setSelected}
        />
      )}

      <div className="body">
        <ScrollToBottom className="message-body" initialScrollBehavior="smooth">
          <ChatBodyList
            {...{ messageList, user, room, selected, handleSelect }}
          />
        </ScrollToBottom>
        <form className="enter-message" onSubmit={handleSubmit}>
          <div>
            <input type="text" name="chat" id="chat" />
            <button type="button" className="file-upload btn btn-sm btn-light">
              <i className="fa fa-paperclip" aria-hidden="true"></i>

              <input
                type="file"
                name="file"
                id="file"
                onChange={handleFileUpload}
                // accept="image/*"
                multiple
              />
            </button>
          </div>
          <button type="submit" className="btn btn-sm btn-dark">
            <FiSend />
          </button>
        </form>
      </div>
    </div>
  );
}
