import { useDispatch } from "react-redux";
import { GeofenceForm } from "components/Forms";
import { useAxiosPrivate } from "hooks";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { updateGeofenceRerender } from "redux/features/other.slice";

let iconString = "";
export default function GeofenceCard({ geofence }) {
  if (geofence.pox_type === "Flat") iconString = "home";
  else if (geofence.pox_type === "Plot") iconString = "square";
  else if (geofence.pox_type === "Estab") iconString = "building";
  else iconString = "";
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [eShow, setEShow] = useState(false);
  const onHide = () => setShow(false);
  const onShow = () => setShow(true);
  const handleClose = () => setEShow(false);
  const handleShow = () => setEShow(true);

  const delete_mutation = useMutation({
    mutationFn: async () => {
      await axiosPrivate.delete(`/geofences/${geofence.id}`);
    },
    onSuccess() {
      queryClient.invalidateQueries(["geofences"]);
      dispatch(updateGeofenceRerender(true));
    },
  });

  return (
    <>
      <div className="d-flex align-items-center gap-2 border px-3 py-2 geofence-card">
        <span
          className="color"
          style={{
            backgroundColor: geofence.color + "44",
            borderColor: geofence.color,
          }}
        />
        <div className="flex-grow-1 d-flex align-items-center gap-2">
          <div className="flex-grow-1 info">
            <span
              className="text-truncate fw-bold d-inline-block"
              style={{ fontSize: "0.85rem" }}
            >
              {geofence.name}
            </span>
            <div
              style={{
                fontSize: "0.65rem",
                fontWeight: 700,
                color: "#042954",
              }}
            >
              {iconString && (
                <>
                  <i className={`fa fa-${iconString}`}></i>&nbsp;
                </>
              )}
              {geofence.pox_type + " Alerts"}
            </div>
          </div>
          <div className="d-flex justify-content-evenly align-items-center gap-2 actions">
            <Button
              className="p-2"
              size="sm"
              style={{ lineHeight: 0, borderRadius: 6, opacity: "70%" }}
              onClick={handleShow}
            >
              <FaEdit size={"0.65em"} />
              <span className="mobile-only">Edit</span>
              <span className="visually-hidden">Edit</span>
            </Button>
            <Button
              className="p-2"
              variant="danger"
              size="sm"
              style={{ lineHeight: 0, borderRadius: 6, opacity: "70%" }}
              onClick={onShow}
            >
              <FaTrash size={"0.65em"} />
              <span className="mobile-only">Delete</span>
              <span className="visually-hidden">Delete</span>
            </Button>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={!delete_mutation.isLoading && onHide}
        centered
        size="sm"
      >
        <Modal.Header className="fw-bold py-3 d-flex justify-content-center border-0">
          Delete Geofence!
        </Modal.Header>
        <Modal.Body
          className="d-flex justify-content-center border-0 py-0"
          style={{ fontSize: "0.85rem", color: "#EA4335" }}
        >
          Are you sure you want to delete this?
        </Modal.Body>
        <Modal.Footer className="py-33 d-flex justify-content-center border-0">
          <Button
            variant="secondary"
            size="sm"
            style={{ fontSize: "0.65rem" }}
            onClick={onHide}
            disabled={delete_mutation.isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            size="sm"
            style={{ fontSize: "0.65rem" }}
            disabled={delete_mutation.isLoading}
            onClick={() => delete_mutation.mutate()}
          >
            {delete_mutation.isLoading ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>

      {eShow && (
        <GeofenceForm
          {...{ show: eShow, handleClose, fence: geofence, id: geofence.id }}
        />
      )}
    </>
  );
}
