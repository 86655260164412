import { useState } from 'react';
import axiosInstance from 'apis/axios';
import { Marker, Popup, useMapEvents } from 'react-leaflet';
import { getProjectInfoIcon, projectInfoIconsMap } from './utils';
import { Link } from 'react-router-dom';

const ProjectInfo = () => {
	const [level, setLevel] = useState(null);
	const [data, setData] = useState(null);

	const fetchData = async () => {
		try {
			const res = await axiosInstance.get(`/project-info`);
			setData(res.data.project_info);
		} catch (error) {
			console.log({ error });
		}
	};

	const mapEvents = useMapEvents({
		zoomend: () => {
			setLevel(mapEvents.getZoom());
			if (mapEvents.getZoom() >= 10 && !data) {
				fetchData();
			}
		},
	});

	if (level > 8 && data)
		return data.map(info => (
			<Marker
				key={info?._id}
				position={{ lat: info?.position.lat, lng: info?.position.lng }}
				icon={getProjectInfoIcon()}>
				<Popup>
					<div className='project-info-popup'>
						<Link
							to={`/project-info/${info?._id}`}
							style={{
								margin: 4,
								fontWeight: 700,
								fontSize: '1.25em',
								whiteSpace: 'nowrap',
							}}>
							{info?.title}
						</Link>
						<div className='d-flex align-items-center gap-1'>
							<img src={projectInfoIconsMap.get(info?.category)} alt={'info'} />
							<p className='m-0' style={{ whiteSpace: 'nowrap' }}>
								{info?.category}
							</p>
						</div>
					</div>
				</Popup>
			</Marker>
		));

	return null;
};

export default ProjectInfo;
