import { useEffect, useState, useMemo } from "react";
import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import { Spinner } from "react-bootstrap";
import { ReactComponent as NavigatorIcon } from "assets/svg/bx_current-location.svg";
import MarkerSvg from "assets/svg/marker.svg";
import useToggle from "hooks/use-toggle";
import "leaflet/dist/leaflet.css";
import "./styles.scss";

const LocationIcon = L.icon({
  iconUrl: MarkerSvg,
  iconRetinaUrl: MarkerSvg,
  iconSize: [60, 50],
  className: "marker",
});

export default function Map(props) {
  const { position, positions, setPosition } = props;
  const [markerPosition, setMarkerPosition] = useState(position);

  const zoom = position ? 10 : 1;
  const center = useMemo(() => {
    if (positions) {
      const latCenter =
        positions.reduce((a, b) => a + b.lat, 0) / positions.length;
      const lngCenter =
        positions.reduce((a, b) => a + b.lng, 0) / positions.length;

      return [latCenter, lngCenter];
    }

    return [position?.lat, position?.lng];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePositions = (newPosition) => {
    setPosition(newPosition);
    setMarkerPosition(newPosition);
  };

  return (
    <MapContainer
      zoom={zoom}
      center={center}
      id="map"
      className="small_map"
      style={{ height: "100%", width: "100%", position: "relative" }}
      dragging={setPosition}
    >
      <TileLayer
        attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <LocationMarker
        position={markerPosition}
        positions={positions}
        updatePositions={setPosition && updatePositions}
      />

      {setPosition ? (
        <>
          <LeafletgeoSearch updatePositions={updatePositions} />
          <ResetCenterView position={markerPosition} />
        </>
      ) : (
        <DisabledControls />
      )}
    </MapContainer>
  );
}

function DisabledControls() {
  const map = useMap();
  useEffect(() => {
    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    if (map.tap) map.tap.disable();
    document.getElementById("map").style.cursor = "default";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

function ResetCenterView({ position }) {
  const map = useMap();
  useEffect(() => {
    if (position) {
      map.setView(L.latLng(position?.lat, position?.lng), map.getZoom(), {
        animate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  return null;
}

function simulateClick() {
  const event = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  const cb = document.getElementsByClassName("reset")[0];
  cb.dispatchEvent(event);
}

function LocationMarker({ position, positions, updatePositions }) {
  const mapEvents = useMapEvents({});
  const [current, setCurrent] = useState(position);
  const [isLoading, toggleLoading] = useToggle(false);

  const navigate_to_user_location = () => {
    toggleLoading();
    navigator.geolocation.getCurrentPosition((pos) => {
      setCurrent({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      });
      updatePositions({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      });
      mapEvents.setView(
        { lat: pos.coords.latitude, lng: pos.coords.longitude },
        14,
        {
          animate: true,
          pan: {
            duration: 1,
          },
        }
      );
      toggleLoading();
    });
  };

  useEffect(() => {
    setCurrent(position);
  }, [position]);

  const map = useMapEvents({
    click(e) {
      if (updatePositions) {
        updatePositions({ ...e.latlng });
        map.flyTo(e.latlng, map.getZoom());
        simulateClick();
      }
    },
  });

  if (current)
    return (
      <>
        {updatePositions && (
          <div className="navigator">
            <button
              onClick={navigate_to_user_location}
              title="current location"
              type="button"
            >
              {isLoading ? (
                <Spinner
                  animation="border"
                  role="status"
                  variant="primary"
                  size="sm"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <NavigatorIcon />
              )}
            </button>
          </div>
        )}
        <Marker position={current} icon={LocationIcon} />;
      </>
    );

  if (positions)
    return (
      <>
        {positions.map((pos) => (
          <Marker
            key={`${pos.lat}-${pos.lng}-${Math.random()}`}
            position={pos}
            icon={LocationIcon}
          />
        ))}
      </>
    );

  return null;
}

function LeafletgeoSearch({ updatePositions }) {
  const map = useMap();

  useEffect(() => {
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      notFoundMessage: "Sorry, that address could not be found.",
      provider,
      marker: { icon: LocationIcon },
      popupFormat: ({ query, result }) => {
        updatePositions({ lat: query.data.y, lng: query.data.x });
        return result.label;
      },
    });

    map.addControl(searchControl);

    return () => {
      map.removeControl(searchControl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
