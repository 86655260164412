import { useNavigate } from "react-router-dom";
import { ContactUsForm } from "components";

export default function ContactUsPage() {
  const navigate = useNavigate();
  return (
    <div className="contact-us-page">
      <ContactUsForm closeModal={() => navigate(-1)} />
    </div>
  );
}
