import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import './styles.scss';

const AboutUsPage = () => {
	const navigate = useNavigate();

	return (
		<main className='about-section'>
			<div className='mask' onClick={() => navigate(-1)} />

			<div className='content'>
				<div>
					<img src='/assets/img/logo_2.png' alt='ucpox' className='logo' />

					<button className='close-btn' onClick={() => navigate(-1)}>
						<FaTimes />
					</button>
				</div>

				<section className='header'>
					<h1 className='title'>About Us</h1>

					<p>
						A borderless digitalized peer to peer platform. <br></br>
						Its enabled as AI based property exchange platform.
					</p>

					<p className='text-small'>
						Property exchange will be featuring - FLATS, PLOTS, ESTABS to Sale
						or Rent.
					</p>
				</section>

				<section className='content'>
					<div className='header'>
						<h1 className='title'>Overview</h1>

						<p>
							UCPOX allows genuine tenants and owners to publish their
							properties and to connect directly. <br></br>
							It is a personalized app the plays with the latest technology and
							help to find their convinience within their
							budget/affordability/wanted amenities.
						</p>

						<p className='text-small'>
							UCPOX designed to have focus points as&nbsp;
							<span style={{ color: '#003069' }}>
								SECURE, ACCESSIBLE, FAST & RELIABLE.
							</span>
						</p>
					</div>

					<div className='body'>
						<div className='card'>
							<p>
								<strong>SECURE</strong> describes a safest environment against
								unauthorized access and modification.
							</p>
						</div>
						<div className='card'>
							<p>
								<strong>ACCESSIBLE</strong> describes as User friendly, that
								helps the user to register him/herself into the application and
								use the application whenever required.
							</p>
						</div>
						<div className='card'>
							<p>
								<strong>FAST &amp; RELIABLE</strong> describes as technology
								empowered and built as a trusted version and also can increase
								the usability.
							</p>
						</div>
					</div>

					<div className='footer'>
						<h1 className='title'>Goal</h1>

						<p>
							"Our Goal is to provide an efficient and user friendly platform to
							the users to accomplish their goals
							<br></br>
							based on their needs & requirements with high User interface. "
						</p>
					</div>
				</section>
			</div>
		</main>
	);
};

export default AboutUsPage;
