import { Button, Modal } from "react-bootstrap";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUser } from "redux/features/auth.slice";
import { getMapData } from "redux/features/map.slice";
import { useAxiosPrivate } from "hooks";
import { onRemove } from "utils/firebase";

export default function DeletePoxModal({ show, onHide, pox, onSuccess }) {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const mutation = useMutation({
    mutationFn: async () => {
      pox.images?.forEach((img) => img && onRemove(img));
      await axios.delete(`/poxes/${pox.id}`);
    },
    onSuccess: () => {
      toast.success("Pox deleted");
      onSuccess?.();
      dispatch(
        getMapData({
          instance: axios,
          url: `/poxes?publishedBy=${user?.uid}`,
        })
      );
    },
  });

  return (
    <Modal
      show={show}
      onHide={!mutation.isLoading && onHide}
      centered
      size="sm"
    >
      <Modal.Header className="fw-bold py-3 d-flex justify-content-center border-0">
        Delete Pox!
      </Modal.Header>
      <Modal.Body
        className="d-flex justify-content-center border-0 py-0"
        style={{ fontSize: "0.85rem", color: "#EA4335" }}
      >
        Are you sure you want to delete this?
      </Modal.Body>
      <Modal.Footer className="py-33 d-flex justify-content-center border-0">
        <Button
          variant="secondary"
          size="sm"
          style={{ fontSize: "0.65rem" }}
          onClick={onHide}
          disabled={mutation.isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          size="sm"
          style={{ fontSize: "0.65rem" }}
          disabled={mutation.isLoading}
          onClick={() => mutation.mutate()}
        >
          {mutation.isLoading ? "Deleting..." : "Delete"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
