import { useState } from "react";

function useToggle(defaultState) {
  const [state, setState] = useState(defaultState);

  const toggleState = (value) => {
    if (typeof value === typeof defaultState) setState(value);
    else setState((prev) => !prev);
  };

  return [state, toggleState];
}

export default useToggle;
