function convertAreaUnit(area, unit) {
	let newArea = area;

	switch (unit) {
		case 'SQMS':
			newArea = area * 10.7639104;
			break;

		case 'CENTS':
			newArea = area * 435.56;
			break;

		case 'ACRES':
			newArea = area * 43560;
			break;

		default:
			break;
	}

	return `${(+newArea.toFixed(2)).toLocaleString()} SQFTS`;
}

export default convertAreaUnit;
