import { Routes, Route } from "react-router-dom";
import {
  AboutUsPage,
  PoxInfoModal,
  PrivacyPage,
  ProjectInfoPage,
  SubscriptionPage,
  CreatePoxPage,
  EditPoxPage,
  SavedNotesPage,
  MyPoxesPage,
  SavedPoxesPage,
  GeofencePage,
  ContactUsPage,
  MobileWebView,
  MessageLayout,
  InboxPage,
  ChatPage,
  CreateChatPage,
} from "pages";
import { PersistLogin, ProtectedPage, MainLayout } from "components";

export default function App() {
  return (
    <Routes>
      <Route element={<PersistLogin />}>
        <Route path="/" element={<MainLayout />}>
          <Route path="about-us" element={<AboutUsPage />} />
          <Route path="contact-us" element={<ContactUsPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="subscription" element={<SubscriptionPage />} />
          <Route path="project-info/:id" element={<ProjectInfoPage />} />
          <Route path="flat/:pid" element={<PoxInfoModal />} />
          <Route path="plot/:pid" element={<PoxInfoModal />} />
          <Route path="estab/:pid" element={<PoxInfoModal />} />
          <Route element={<ProtectedPage />}>
            <Route path="saved-notes" element={<SavedNotesPage />} />
            <Route path="saved-poxes" element={<SavedPoxesPage />} />
            <Route path="my-poxes" element={<MyPoxesPage />} />
            <Route path="geofence" element={<GeofencePage />} />
            <Route path="messages" element={<MessageLayout />}>
              <Route path="inbox" element={<InboxPage />} />
              <Route path="create-chat" element={<CreateChatPage />} />
              <Route path="chat/:chatid" element={<ChatPage />} />
            </Route>
          </Route>
        </Route>
        <Route element={<ProtectedPage />}>
          <Route path="poxes">
            <Route path="create" element={<CreatePoxPage />} />
            <Route path=":id">
              <Route path="edit" element={<EditPoxPage />} />
            </Route>
          </Route>
        </Route>
      </Route>

      {/* Mobile Web Views */}
      <Route element={<MobileWebView />} path="/mobile-web-view" />
    </Routes>
  );
}
