import { useFormik } from "formik";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAxiosPrivate } from "hooks";
import { initialValues, validationSchema } from "./config";

export default function NoteForm({
  show,
  handleClose,
  type = "create",
  pox_id,
  title,
  description,
}) {
  const { user } = useSelector((state) => state.auth);
  const axios = useAxiosPrivate();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (values) => {
      if (type === "create")
        return await axios.post("/poxes-notes", { pox_id, ...values });
      else return await axios.patch(`/poxes-notes/${pox_id}`, values);
    },
    async onSuccess() {
      await queryClient.invalidateQueries(["my-notes", user.uid]);
      handleClose();
    },
    onError() {
      toast.error("There was an error, try again later");
    },
  });

  const formik = useFormik({
    initialValues: type === "create" ? initialValues : { title, description },
    validationSchema,
    onSubmit(values) {
      mutation.mutate(values);
    },
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold fs-6 text-capitalize">{`${type} Note`}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <FloatingLabel
            controlId={"title"}
            className="mb-4"
            label={
              <span className="fw-semibold d-flex gap-1">
                <span className="text-danger">*</span>
                {"Note Title"}
              </span>
            }
          >
            <Form.Control
              name={"title"}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.title && formik.errors.title}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.title}
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel
            controlId={"description"}
            label={
              <span className="fw-semibold d-flex gap-1">
                <span className="text-danger">*</span>
                {"Note Description"}
              </span>
            }
          >
            <Form.Control
              as="textarea"
              name={"description"}
              placeholder="Enter Note Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.description && formik.errors.description
              }
              style={{ height: "150px" }}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.description}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer as={Row}>
          <Col className="d-flex justify-content-center">
            <Button
              type="button"
              variant="danger"
              style={{ width: "90%", borderRadius: 8 }}
              onClick={handleClose}
              disabled={mutation.isLoading}
            >
              Cancel
            </Button>
          </Col>
          <Col className="d-flex justify-content-center">
            <Button
              type="submit"
              variant="primary"
              className="text-capitalize"
              style={{ width: "90%", borderRadius: 8 }}
              disabled={mutation.isLoading}
            >
              {mutation.isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                type
              )}
            </Button>
          </Col>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
