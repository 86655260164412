import "./style.scss";
import { Fragment, useMemo, useState } from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { FaFilter, FaSearch, FaTimes } from "react-icons/fa";
import { BasicLoader } from "components";
import CPagination from "../Pagination";

const types_options = ["All", "Flat", "Plot", "Estab"];
const status_options = ["All", "active", "inactive"];
const ownership_options = ["All", "Self", "Others"];

const per_page = 5;
export default function MapFloatingListing({
  Content,
  Heading,
  onBack,
  data,
  isLoading,
  className,
  allowOwnershipFilter = false,
}) {
  const [current_page, setCurrentPage] = useState(1);
  const skip = useMemo(() => (current_page - 1) * per_page, [current_page]);
  const limit = useMemo(() => skip + per_page, [skip]);
  const [filter_type, setFilterType] = useState("All");
  const [filter_status, setFilterStatus] = useState("All");
  const [filter_ownership, setFilterOwnership] = useState("All");
  const [search, setSearch] = useState("");

  const filtered_data = useMemo(() => {
    if (
      filter_type === "All" &&
      filter_status === "All" &&
      search === "" &&
      filter_ownership === "All"
    ) {
      return data;
    }
    return data.filter((item) => {
      let type_match = [];

      if (className === "Geo") {
        type_match =
          filter_type === "All" ? true : item.pox_type === filter_type;
      } else {
        type_match = filter_type === "All" ? true : item.type === filter_type;
      }

      const status_match =
        filter_status === "All"
          ? true
          : item.status.toLowerCase() === filter_status.toLowerCase();

      const ownership_match =
        filter_ownership === "All"
          ? true
          : (item.ownership && filter_ownership === "Self") ||
            (!item.ownership && filter_ownership === "Others")
          ? true
          : false;

      const search_match =
        item.name.toLowerCase().indexOf(search.toLowerCase()) > -1;

      if (allowOwnershipFilter)
        return type_match && status_match && search_match && ownership_match;

      return type_match && status_match && search_match;
    });
  }, [
    filter_type,
    filter_status,
    filter_ownership,
    search,
    allowOwnershipFilter,
    data,
    className
  ]);

  return (
    <Fragment>
      <div className={`floating-details-wrapper ${className}`}>
        <div className="d-flex justify-content-between mb-2 px-2 py-3 p-md-3 pb-0 header">
          <div className="d-flex align-items-center gap-2">
            <Heading total={filtered_data?.length ?? 0} />
          </div>
          {onBack && (
            <button
              type="button"
              title="close button"
              className="btn btn-sm btn-dark py-1 mb-0"
              style={{
                lineHeight: 0,
                fontSize: "0.75rem",
                backgroundColor: "#042954",
              }}
              onClick={() => {
                onBack();
              }}
            >
              <FaTimes />
            </button>
          )}
        </div>

        <div>
          <div className="px-2 px-md-3 pt-1 d-flex align-items-center gap-2">
            <div className="flex-grow-1" style={{ position: "relative" }}>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: 32,
                }}
              >
                <FaSearch size={14} />
              </div>
              <input
                type="search"
                name="search"
                id="search"
                placeholder="Search all pox..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                className="pr-2 border-0"
                style={{
                  height: "40px",
                  width: "100%",
                  paddingLeft: 32,
                  fontSize: "0.85rem",
                  outline: "none",
                  borderRadius: 8,
                }}
              />
            </div>
            <div>
              <Dropdown>
                <Dropdown.Toggle
                  variant="dark"
                  className="filter-toggle px-3"
                  size="sm"
                  style={{
                    backgroundColor: "#042954",
                    height: "40px",
                  }}
                >
                  <FaFilter />
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ width: 360 }}>
                  <Form.Group as={Row} controlId={"type"} className="px-2">
                    <Form.Label column sm={3} className="fw-bold d-flex gap-1">
                      {"Type"}
                    </Form.Label>
                    <Col sm={9}>
                      <div className="d-flex gap-2 flex-wrap">
                        {types_options.map((e) => {
                          return (
                            <Form.Check
                              key={e}
                              className={`custom-radio-wrapper ${
                                filter_type === e ? "active" : ""
                              }`}
                            >
                              <Form.Check.Label
                                htmlFor={e}
                                style={{
                                  fontSize: "0.65rem",
                                  fontWeight: "600",
                                  margin: 0,
                                  lineHeight: 0,
                                }}
                              >
                                {e}
                              </Form.Check.Label>
                              <Form.Check.Input
                                type="radio"
                                name={"type"}
                                id={e}
                                value={filter_type}
                                onChange={() => {
                                  setFilterType(e);
                                  setCurrentPage(1);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </Form.Check>
                          );
                        })}
                      </div>
                    </Col>
                  </Form.Group>

                  {className !== "Geo" && <Dropdown.Divider />}

                  {className !== "Geo" && (
                    <>
                      <Form.Group as={Row} controlId={"type"} className="px-2">
                        <Form.Label
                          column
                          sm={3}
                          className="fw-bold d-flex gap-1"
                        >
                          {"Status"}
                        </Form.Label>
                        <Col sm={9}>
                          <div className="d-flex gap-2 flex-wrap">
                            {status_options.map((e) => {
                              return (
                                <Form.Check
                                  key={e}
                                  className={`custom-radio-wrapper ${
                                    filter_status === e ? "active" : ""
                                  }`}
                                >
                                  <Form.Check.Label
                                    htmlFor={e}
                                    style={{
                                      fontSize: "0.65rem",
                                      fontWeight: "600",
                                      margin: 0,
                                      lineHeight: 0,
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {e}
                                  </Form.Check.Label>
                                  <Form.Check.Input
                                    type="radio"
                                    name={"type"}
                                    id={e}
                                    value={filter_type}
                                    onChange={() => {
                                      setFilterStatus(e);
                                      setCurrentPage(1);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </Form.Check>
                              );
                            })}
                          </div>
                        </Col>
                      </Form.Group>
                      {allowOwnershipFilter && (
                        <>
                          <Dropdown.Divider />
                          <Form.Group
                            as={Row}
                            controlId={"type"}
                            className="px-2"
                          >
                            <Form.Label
                              column
                              sm={3}
                              className="fw-bold d-flex gap-1"
                            >
                              {"Ownership"}
                            </Form.Label>
                            <Col sm={9}>
                              <div className="d-flex gap-2 flex-wrap">
                                {ownership_options.map((e) => {
                                  return (
                                    <Form.Check
                                      key={e}
                                      className={`custom-radio-wrapper ${
                                        filter_ownership === e ? "active" : ""
                                      }`}
                                    >
                                      <Form.Check.Label
                                        htmlFor={e}
                                        style={{
                                          fontSize: "0.65rem",
                                          fontWeight: "600",
                                          margin: 0,
                                          lineHeight: 0,
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {e}
                                      </Form.Check.Label>
                                      <Form.Check.Input
                                        type="radio"
                                        name={"type"}
                                        id={e}
                                        value={filter_type}
                                        onChange={() => {
                                          setFilterOwnership(e);
                                          setCurrentPage(1);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </Form.Check>
                                  );
                                })}
                              </div>
                            </Col>
                          </Form.Group>
                        </>
                      )}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="content">
            {isLoading ? (
              <div>
                <BasicLoader />
              </div>
            ) : filtered_data?.length > 0 ? (
              <Content pdata={filtered_data.slice(skip, limit)} />
            ) : (
              <div className="py-3 d-flex align-items-center justify-content-center text-center fw-bold">
                <p className="fw-bold">No Data!</p>
              </div>
            )}
          </div>

          {filtered_data?.length > 0 && (
            <div className="py-3 border-top d-flex align-items-center justify-content-center">
              <CPagination
                current_page={current_page}
                onClick={setCurrentPage}
                total_pages={Math.ceil(filtered_data?.length / per_page)}
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
