/* eslint-disable no-param-reassign */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'apis/axios';
import { logOut } from 'utils/firebase';
import { logOutSuccess } from 'redux/features/auth.slice';

const useAxiosPrivate = () => {
	const dispatch = useDispatch();
	const token = useSelector(state => state.auth.token);

	useEffect(() => {
		const requestInterceptor = axios.interceptors.request.use(
			config => {
				if (config.headers && !config.headers?.Authorization)
					config.headers.Authorization = `Bearer ${token}`;

				return config;
			},
			error => Promise.reject(error)
		);

		const responseInterceptor = axios.interceptors.response.use(
			response => response,
			async error => {
				const prevRequest = error?.config;
				if (error?.response?.status === 406 && !prevRequest?.sent) {
					await logOut();
					dispatch(logOutSuccess());
				}
				return Promise.reject(error);
			}
		);

		return () => {
			axios.interceptors.request.eject(requestInterceptor);
			axios.interceptors.response.eject(responseInterceptor);
		};
	}, [dispatch, token]);

	return axios;
};

export default useAxiosPrivate;
