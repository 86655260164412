import { useEffect } from "react";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { useMap } from "react-leaflet";
import L from "leaflet";
import locationIcon from "assets/svg/venue_location_icon.svg";

const LocationIcon = L.icon({
  iconUrl: locationIcon,
  iconRetinaUrl: locationIcon,
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [45, 38],
  className: "leaflet-venue-icon",
});

const LeafletGeoSearch = () => {
  const map = useMap();

  useEffect(() => {
    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider,
      style: "bar",
      autoComplete: true,
      marker: { icon: LocationIcon },
      searchLabel: "Search location...",
    });

    map.addControl(searchControl);

    return () => {
      map.removeControl(searchControl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default LeafletGeoSearch;
