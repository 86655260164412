import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

const PrivacyPage = () => {
	const navigate = useNavigate();

	return (
		<div className='privacy-section'>
			<div className='privacy-section-wrapper'>
				{/* this is privacy header section  */}
				<div className='privacy-section-header'>
					<img src='/assets/img/logo_2.png' alt='ucpox' className='logo' />

					<h1>Privacy Policy</h1>

					<button onClick={() => navigate(-1)}>
						<FaTimes />
					</button>
				</div>

				{/* this is body section  */}
				<div className='text-content'>
					The website&nbsp;<font color='#4274C4'>www.ucpox.com</font> is a
					platform that provides accurate and reliable data in a map view and
					concisely communicates the essential elements of properties present
					worldwide.
					<br />
					<br />
					This privacy policy page describes how UCPOX collects, uses, and
					discloses the personal information of website visitors who have
					decided to use our services on the UCPOX website.
					<br />
					<br />
					During the use or access to our Service or website, you confirm that
					you have read, understand, and acknowledge the terms of this Privacy
					Policy. Personal information that we collect is used to respond to
					your requests, to provide you with services, to improve our products
					and services, to comply with our obligations, to administer our
					websites and services, for internal operations and security purposes,
					and for hiring purposes. We will not use or share your personal
					information with anyone except as described in this Privacy Policy.
					<br />
					<br />
					The terms used in this Privacy Policy have the same meanings as in our
					Terms and Conditions, which are accessible at www.ucpox.com unless
					otherwise defined in this Privacy Policy.
					<br />
					<br />
					<h3>Information Collection and Use</h3>
					For a better experience while using our Service, we may require you to
					provide us with certain personally identifiable information, including
					but not limited to your name, phone number, and postal address. We
					collect such information to contact or identify you to provide you
					with a service at your request, such as when you try to contact us,
					register an account on our website, avail of subscription services, or
					access the content on our Platform.
					<br />
					<br />
					<h3>Log Data</h3>
					We want to inform you that whenever you visit our Service, we collect
					information sent by your browser called log data. This Log Data may
					include information such as your device's Internet Protocol or IP
					address, browser version, pages of our Service that you visit, the
					time and date of your visit, the time spent on those pages, and other
					statistics.
					<br />
					<br />
					<h3>Cookies</h3>
					A “cookie” is a small text file that may be used, for example, to
					collect data about website activity. Some cookies and other
					technologies may serve to recall Personal Data previously indicated by
					a Web user.
					<br />
					<br />
					Our website uses these cookies to collect information and to improve
					our Service. Most browsers allow you to control cookies, including
					whether or not to accept them and how to remove them. You may set most
					browsers to notify you if you receive a cookie, or you may choose to
					block cookies with your browser. If you choose to refuse our cookies,
					you may not be able to use only some portions of our Service. For more
					general information on cookies, please read the "Cookies" article from
					the Privacy Policy Generator.
					<br />
					<br />
					<h3>Service Providers</h3>
					We may employ third-party companies and individuals due to the
					following reasons:
					<ul>
						<li>To facilitate our Service;</li>
						<li>To provide the Service on our behalf;</li>
						<li>To perform Service-related services; or</li>
						<li>To assist us in analysing how our Service is used. </li>
					</ul>
					We want to inform our Service users that these third parties have
					access to your Personal Information. The reason is to perform the
					tasks assigned to them on our behalf. However, they are obligated not
					to disclose or use the information for any other purpose.
					<br />
					<br />
					<h3>Security</h3>
					We value your trust in providing us with your Personal Information,
					thus we are striving to use commercially acceptable means of
					protecting it. But remember that no method of transmission over the
					internet, or method of electronic storage is 100% secure and reliable,
					and we cannot guarantee its absolute security.
					<br />
					<br />
					<h3>Links to Other Sites</h3>
					Our Service may contain links to other sites. If you click on a
					third-party link, you might end up landing on a third-party website.
					Please make a note that we do not operate these sites. Therefore, we
					strongly advise you to review the Privacy Policy of these websites. We
					have no control over and assume no responsibility for the content,
					privacy policies, or practices of any third-party sites or services.
					<br />
					<br />
					<h3>Privacy for Children</h3>
					Our Services do not address anyone under the age of 13. We do not
					knowingly collect personally identifiable information from children
					under 13. In case of discovering that a child under 13 has provided us
					with personal information, we immediately delete this from our
					servers. If you are a parent or guardian and are aware that your child
					has provided us with personal information, please contact us so that
					we will be able to do the necessary actions.
					<br />
					<br />
					<h3>Changes to This Privacy Policy</h3>
					We may update our Privacy Policy from time to time. Thus, we advise
					you to review this page periodically for any changes. We will notify
					you of any changes by posting the new Privacy Policy on this page.
					These changes are effective immediately after they are posted on this
					page.
					<br />
					<br />
					<h3>Contact Us</h3>
					If you have any questions or suggestions about our Privacy Policy feel
					free to contact us.
					<br />
					<br />
					This Privacy policy is subject to the terms of the Site Policy (User
					agreement) of UCPOX. This policy is effective from the date and time a
					user registers with UCPOX by filling up the Registration form and
					accepting the terms and conditions laid out in the Site Policy.
					<br />
					<br />
					To provide a personalized browsing experience, UCPOX may collect
					personal information from you. Additionally, some of our websites may
					require you to complete a registration form or seek some information
					from you. When you let us have your preferences, we will be able to
					deliver or allow you to access the most relevant information that
					meets your end.
					<br />
					<br />
					To extend this personalized experience, UCPOX may track the IP address
					of a user's computer and save certain information on your system in
					the form of cookies. A user has the option of accepting or declining
					the cookies of this website by changing the settings of their browser.
					<br />
					<br />
					The personal information provided by the users to UCPOX, will not be
					provided to third parties without the previous consent of the
					concerned user. However, information of a general nature may be
					revealed to external parties.
					<br />
					<br />
					Every effort will be made to keep the information safely provided by
					users. The information will be displayed on the website only after
					obtaining consent from the users. Any user browsing the site generally
					is not required to disclose his identity or provide any information
					about him/her, it is only at the time of registration you will be
					required to furnish the details in the registration form.
					<br />
					<br />
					A full user always has the option of not providing the information
					which is not mandatory. You are solely responsible for maintaining the
					confidentiality of the User's password and user identification and all
					activities and transmission performed by the user through his user
					identification and shall be solely responsible for carrying out any
					online or off-line transaction involving credit cards/debit cards or
					such other forms of instruments or documents for making such
					transactions and IEIL assumes no responsibility or liability for their
					improper use of information relating to such usage of credit
					cards/debit cards used by the subscriber online / off-line.
					<br />
					<br />
					IEIL provides you with the ability to keep your personal information
					accurate and up-to-date. If at any time you would like to;
					<ol>
						<li>
							Rectify, update, correct, or delete your personal information.
						</li>
						<li>
							Obtain confirmation on whether or not your personal information is
							processed by it
						</li>
						<li>
							Access your personal information or exercise your right to data
							portability.
						</li>
						<li>
							Exercise your right to restrict the continuing disclosure of your
							personal information to any third party by IEIL in certain
							circumstances, you are requested to contact us at&nbsp;
							<font color='#4274C4'>admin@ucpox.com</font>
						</li>
					</ol>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPage;
