import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { restoreFromMyPoxes } from "redux/features/map.slice";
import { Badge, ListGroup } from "react-bootstrap";
import { MapFloatingListing } from "components";
import PoxCard from "./PoxCard";
import "./style.scss";

const SavedPoxes = () => {
  const { data, isLoading } = useSelector((state) => state.map);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Fragment>
      <MapFloatingListing
        Heading={({ total = 0 }) => (
          <>
            <h1 className="fs-6 fw-bold mb-0" style={{ lineHeight: "unset" }}>
              Saved Poxes
            </h1>
            <Badge
              bg="primary"
              className="badge"
              style={{ lineHeight: "unset" }}
            >
              {total}
            </Badge>
          </>
        )}
        Content={({ pdata }) => (
          <ListGroup as="ul">
            {pdata.map((pox) => {
              return (
                <ListGroup.Item key={pox._id} as="li" className="p-0">
                  <PoxCard {...pox} />
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        )}
        onBack={() => {
          dispatch(restoreFromMyPoxes());
          navigate(-1);
        }}
        isLoading={isLoading}
        data={data}
      />
    </Fragment>
  );
};

export default SavedPoxes;
