import { useEffect, useRef, useState } from "react";

export default function useDebounce(cb, dep = [], delay = 1000) {
  const [timeout, set_timeout] = useState(null);
  const firstRender = useRef(false);

  useEffect(() => {
    if (!firstRender.current) {
      firstRender.current = true;
    } else {
      if (timeout !== null) {
        clearTimeout(timeout);
      }

      const search_timeout = setTimeout(() => {
        cb();
      }, delay);

      set_timeout(search_timeout);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dep]);

  return null;
}
