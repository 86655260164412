import { useEffect, useState } from "react";
import { ReactComponent as WarnIcon } from "assets/svg/warn.svg";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { useMutation, useQueryClient } from "react-query";
import { useAxiosPrivate } from "hooks";
import { toast } from "react-toastify";

export default function StatusModal({ handleStatusModal, id }) {
  const [selectedOption, setSelectedOption] = useState("");
  const axios = useAxiosPrivate();
  const queryClient = useQueryClient();

  const update_mutation = useMutation({
    mutationFn: async (payload) => {
      await axios.patch(`/poxes/${id}`, payload);
    },
    onSuccess() {
      handleStatusModal();
      queryClient.invalidateQueries(["pox", { id }]);
    },
    onError(error) {
      console.log("error", error);
    },
  });

  const handleSubmit = () => {
    if (!selectedOption) {
      toast.info("Select a reason");
    } else {
      update_mutation.mutate({
        status: "inactive",
        inactive_reason: selectedOption,
      });
    }
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    return () => {
      setSelectedOption("");
    };
  }, []);

  return (
    <div className="message-modal">
      <div className="message-wrapper">
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={() => handleStatusModal()}
        >
          <CloseIcon />
        </div>

        <div style={{ position: "absolute", top: "15px", right: "49%" }}>
          <WarnIcon />
        </div>

        <div style={{ padding: "10px" }}>
          <div
            style={{
              textAlign: "center",
              marginTop: "25px",
              fontSize: "14px",
              color: "#1F1F1F",
            }}
          >
            You are about to change pox status
          </div>

          <select
            style={{
              width: "100%",
              backgroundColor: "white",
              cursor: "pointer",
              outline: "none",
              margin: "10px 0",
              color: "#333333",
              borderRadius: "8px",
              padding: "8px 15px",
            }}
            value={selectedOption}
            onChange={handleChange}
            placeholder="hi"
          >
            <option value="" disabled>
              Select a Reason
            </option>
            <option value="sold out">Sold Out</option>
            <option value="Temporarily Unavailable">
              Temporarily Unavailable
            </option>
          </select>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              cursor: "pointer",
            }}
          >
            <div onClick={() => handleStatusModal()}>Cancel</div>
            <div
              style={{
                backgroundColor: "#EA4335",
                padding: "7px 29px",
                color: "white",
                borderRadius: 15,
                marginLeft: 10,
                cursor: "pointer",
              }}
              onClick={handleSubmit}
            >
              {update_mutation.isLoading
                ? "Changing status..."
                : "Update Status"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
