import L from "leaflet";
import { COLORS, COST_OPTIONS } from "data/dataMaps";
import icon_construction from "assets/img/Construction.png";
import icon_airport from "assets/img/Airport.png";
import icon_hospital from "assets/img/Hospital Bed.png";
import icon_railway_station from "assets/img/Railway Station.png";
import icon_shopping from "assets/img/Shopping Cart.png";
import icon_university from "assets/img/University.png";
import icon_info from "assets/img/Info.png";
import { estab_icon, flat_icon, plot_icon } from "utils/icons";

function createClusterCustomIcon() {
  return function (cluster) {
    // const html = (index) => cluster.getAllChildMarkers()?.[index]?.getIcon()?.options?.html;
    // const color = (index) => "#" + html(index)?.split("#")?.[1]?.slice(0, 6);
    // const recent = (index) => html(index)?.includes("recent");
    const count = cluster.getChildCount();

    // if(count> 50) {
    return L.divIcon({
      html: `<div style="background:#042954">
      	<span></span>
				<span></span>
        <span class='count'>${count}</span>
      </div>`,
      className: "marker-cluster-custom-count",
      iconSize: L.point(10, 10, true),
    });
    // }

    // let children = "";

    // for (let i = 0; i < count; i++) {
    //   const top = Math.random() * 20;
    //   const left = Math.random() * 20;
    //   children += `<span class="${
    //     recent(i) ? "pox recent" : "pox"
    //   }" style="top:${top}px;left:${left}px;background:${color(i)}"></span>`;
    // }

    // return L.divIcon({
    //   html: `<div>${children}</div>`,
    //   className: "marker-cluster-custom-dots",
    //   iconSize: L.point(5, 5, true),
    // });
  };
}

const layersMap = new Map([
  ["Flat", flat_icon],
  ["Plot", plot_icon],
  ["Estab", estab_icon],
]);

const getMarkerColor = (param, pox) => {
  if (pox?.status === "inactive" && pox?.inactive_reason === "sold_out")
    return "#c23a52";

  if (param) {
    const paramCheck = pox?.[param];
    const optionsCheck = COST_OPTIONS?.[param];
    let color = COLORS?.get(pox.type)?.[4];

    if (optionsCheck) {
      for (const option of optionsCheck) {
        if (
          paramCheck &&
          paramCheck <= option.maxValue &&
          paramCheck >= option.minValue
        ) {
          color = option?.color?.(pox?.type);
          break;
        }
      }
    }

    return color;
  }

  return "#042954";
};

const getMarkerIcon = (pox, color) => {
  const icon = layersMap.get(pox.type);
  const recent =
    Math.ceil(Date.now() - new Date(pox.createdAt).getTime()) /
      (1000 * 60 * 60 * 24) <
    7;

  return L.divIcon({
    html: `<div class="pox-marker ${
      recent ? "recent" : ""
    }" style="background-color:${color}">
		${icon()}
		<img src="${pox.images[0]?.url ?? "/assets/img/ucpox_ux.png"}" alt="" />
		${
      recent
        ? `
				<div class="recent-anim" style="background-color:${color}">
				<span></span>
				<span></span>
				</div>
			`
        : ""
    }
	</div>`,
    className: "marker-custom",
    iconSize: L.point(10, 10, true),
  });
};

const getProjectInfoIcon = () => {
  return L.divIcon({
    html: `<div class="py-2 px-2">
		 <img src="/assets/svg/project-info.svg" alt="project-info" class="project-info-marker-img" />
	  </div>`,
    className: "project-info-marker-custom",
    iconSize: L.point(10, 10, true),
  });
};

const projectInfoIconsMap = new Map([
  ["Road/ High way construction", icon_construction],
  ["Metro/Railway Project", icon_railway_station],
  ["Airport Project", icon_airport],
  ["Shopping Complex", icon_shopping],
  ["Schools/College /University", icon_university],
  ["Hospital", icon_hospital],
  ["Others", icon_info],
]);

export {
  createClusterCustomIcon,
  getMarkerIcon,
  getMarkerColor,
  getProjectInfoIcon,
  projectInfoIconsMap,
};
