import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GuidedTourWrapper } from "components";
import { updateParam } from "redux/features/map.slice";
import { PARAMS_MAP } from "data/dataMaps";
import "./styles.scss";

const getUnit = (key) => {
  if (key === "area") return "SQR FT";
  if (key === "lease_period") return "YEAR(s)";
  return "INR";
};

const PoxLegends = () => {
  const dispatch = useDispatch();
  const [unit, setUnit] = useState("INR");
  const [curr_param, setCurrParam] = useState("");
  const { layer, payType } = useSelector((state) => state.map);

  const newParams = useMemo(
    () => PARAMS_MAP.get(layer)?.options[payType.toLowerCase()],
    [layer, payType]
  );
  useEffect(() => {
    setCurrParam(0);
    setUnit(getUnit(newParams?.[0]?.key));
    dispatch(updateParam(newParams?.[0]?.key));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newParams]);

  if (layer && payType)
    return (
      <div className="control-bottom-right">
        <GuidedTourWrapper stage={4} className="legend-container">
          <div className="legend-header">
            {PARAMS_MAP.get(layer)?.options[payType.toLowerCase()]?.map(
              (param, idx) => (
                <div
                  key={param.label}
                  className={
                    curr_param === idx
                      ? "visual-item mr-2 active"
                      : "visual-item mr-2"
                  }
                  onClick={() => {
                    setUnit(getUnit(param.key));
                    setCurrParam(idx);
                    dispatch(updateParam(param.key));
                  }}
                >
                  {param.label}
                </div>
              )
            )}
          </div>

          <div className="legend-items">
            {PARAMS_MAP?.get(layer)?.values[payType.toLowerCase()]?.[
              newParams?.[curr_param]?.label
            ]?.map((value) => (
              <div
                key={value.color(layer)}
                className="legend-item"
                style={{ backgroundColor: value.color(layer) }}
              >
                <span>{value.label}</span>
                <div style={{ backgroundColor: value.color(layer) }} />
              </div>
            ))}

            <div className="bold unit-section">{unit}</div>
          </div>
        </GuidedTourWrapper>
      </div>
    );

  return null;
};

export default PoxLegends;
