import "./styles.scss";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function MessagesPage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/messages") {
      navigate("/messages/inbox", { replace: true });
    }
  }, [location.pathname, navigate]);

  return (
    <div className="message-page">
      <div className="mask" onClick={() => navigate("/")}></div>

      <div className="content">
        <Outlet />
      </div>
    </div>
  );
}
