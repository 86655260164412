import Bath from "assets/svg/Bath.svg";
import FloorPlan from "assets/svg/Floor Plan.svg";
import Bed from "assets/svg/Sleeping in Bed.svg";
import convertAreaUnit from "utils/convertAreaUnit";
import { getUser } from "redux/features/auth.slice";
import { useSelector } from "react-redux";
import StatusActions from "./StatusActions";

const ShortHandDesc = ({ img, heading, desc, recommended }) => {
  if (!desc) return null;

  return (
    <div
      className={`d-flex align-items-center shortdesc ${img ? "has-img" : ""}`}
    >
      <div className="d-flex justify-content-center flex-column">
        <span style={{ color: "#A0A0A0" }}>{heading}</span>
        <div className="d-flex align-items-center gap-1">
          {img && (
            <>
              <img src={img} alt="" />{" "}
              <span className="desc">
                <strong>{desc}</strong>
              </span>
            </>
          )}
          {!img && (
            <span className="desc">
              <strong>{desc}</strong>
            </span>
          )}
        </div>
      </div>

      {recommended ? (
        <span>{recommended ? `( recommended : ${recommended} )` : ""}</span>
      ) : null}
    </div>
  );
};

const MainInfo = ({ pox, tab }) => {
  const user = useSelector(getUser);
  const { type, pay_type } = pox;

  switch (type) {
    case "Flat":
      return (
        <div className="main-info">
          {tab === "Info" && (
            <>
              <div className="d-flex gap-5">
                {pay_type === "Rent" && (
                  <>
                    <ShortHandDesc
                      heading="Rent Cost"
                      desc={`${
                        pox?.currency
                      } ${pox?.rent_cost?.toLocaleString()}`}
                      recommended={
                        pox?.recommended
                          ? `${
                              pox?.currency
                            } ${pox?.recommended?.rent_cost?.toLocaleString()}`
                          : null
                      }
                    />
                    <ShortHandDesc
                      heading="Deposit Cost"
                      desc={`${
                        pox?.currency
                      } ${pox?.deposit_cost?.toLocaleString()}`}
                      recommended={
                        pox?.recommended
                          ? `${
                              pox?.currency
                            } ${pox?.recommended?.deposit_cost?.toLocaleString()}`
                          : null
                      }
                    />
                  </>
                )}
                {pay_type === "Sale" && (
                  <ShortHandDesc
                    heading="Sale Cost"
                    desc={`${
                      pox?.currency
                    } ${pox?.sale_cost?.toLocaleString()}`}
                    recommended={
                      pox?.recommended
                        ? `${
                            pox?.currency
                          } ${pox?.recommended?.sale_cost?.toLocaleString()}`
                        : null
                    }
                  />
                )}
                {pox?.effective_cost && (
                  <ShortHandDesc
                    heading="Effective cost"
                    desc={`${
                      pox?.currency
                    } ${pox?.effective_cost?.toLocaleString()}`}
                  />
                )}
              </div>
            </>
          )}

          {tab === "More Info" && (
            <>
              <div className="d-md-flex align-items-center gap-5">
                <div className="d-flex align-items-center gap-5">
                  <ShortHandDesc heading="Facing" desc={pox?.facing} />
                  <ShortHandDesc heading="Floor" desc={pox?.floor} />
                  <ShortHandDesc
                    heading=" Published on"
                    desc={new Date(pox?.createdAt).toLocaleDateString()}
                  />
                </div>

                {user?.uid && user?.uid === pox?.publishedBy?.uid && (
                  <StatusActions pox={pox} />
                )}
              </div>
              <Description description={pox.description} />
            </>
          )}
        </div>
      );

    case "Plot":
      return (
        <div className="main-info">
          {tab === "Info" && (
            <>
              <div className="d-flex gap-5">
                {pay_type === "Lease" && (
                  <>
                    <ShortHandDesc
                      heading="Lease Cost"
                      desc={`${
                        pox?.currency
                      } ${pox?.lease_cost?.toLocaleString()}`}
                      recommended={
                        pox?.recommended
                          ? `${
                              pox?.currency
                            } ${pox?.recommended?.lease_cost?.toLocaleString()}`
                          : null
                      }
                    />
                    <ShortHandDesc
                      heading="Lease Dur."
                      desc={`${pox?.lease_period} Days(s)`}
                    />
                  </>
                )}
                {pay_type === "Sale" && (
                  <ShortHandDesc
                    heading="Sale Cost"
                    desc={`${
                      pox?.currency
                    } ${pox?.sale_cost?.toLocaleString()}`}
                    recommended={
                      pox?.recommended
                        ? `${
                            pox?.currency
                          } ${pox?.recommended?.sale_cost?.toLocaleString()}`
                        : null
                    }
                  />
                )}
                {pox?.effective_cost && (
                  <ShortHandDesc
                    heading="Effective cost"
                    desc={`${
                      pox?.currency
                    } ${pox?.effective_cost?.toLocaleString()}`}
                  />
                )}
              </div>
            </>
          )}

          {tab === "More Info" && (
            <>
              <div className="d-md-flex align-items-center gap-5">
                <div className="d-flex align-items-center gap-5">
                  <ShortHandDesc
                    heading=" Published on"
                    desc={new Date(pox?.createdAt).toLocaleDateString()}
                  />
                </div>
                {user?.uid && user?.uid === pox?.publishedBy?.uid && (
                  <StatusActions pox={pox} />
                )}
              </div>
              <Description description={pox.description} />
            </>
          )}
        </div>
      );

    case "Estab":
      return (
        <div className="main-info">
          {tab === "Info" && (
            <>
              <div className="d-flex gap-5">
                {pay_type === "Rent" && (
                  <>
                    <ShortHandDesc
                      heading="Rent Cost"
                      desc={`${
                        pox?.currency
                      } ${pox?.rent_cost?.toLocaleString()}`}
                      recommended={
                        pox?.recommended
                          ? `${
                              pox?.currency
                            } ${pox?.recommended?.rent_cost?.toLocaleString()}`
                          : null
                      }
                    />
                    <ShortHandDesc
                      heading="Deposit Cost"
                      desc={`${
                        pox?.currency
                      } ${pox?.deposit_cost?.toLocaleString()}`}
                      recommended={
                        pox?.recommended
                          ? `${
                              pox?.currency
                            } ${pox?.recommended?.deposit_cost?.toLocaleString()}`
                          : null
                      }
                    />
                  </>
                )}
                {pay_type === "Sale" && (
                  <ShortHandDesc
                    heading="Sale Cost"
                    desc={`${
                      pox?.currency
                    } ${pox?.sale_cost?.toLocaleString()}`}
                    recommended={
                      pox?.recommended
                        ? `${
                            pox?.currency
                          } ${pox?.recommended?.sale_cost?.toLocaleString()}`
                        : null
                    }
                  />
                )}
                <ShortHandDesc
                  heading="Turnover Cost"
                  desc={`${
                    pox?.currency
                  } ${pox?.turnover_cost?.toLocaleString()}`}
                  recommended={
                    pox?.recommended
                      ? `${
                          pox?.currency
                        } ${pox?.recommended?.turnover_cost?.toLocaleString()}`
                      : null
                  }
                />

                {pox?.effective_cost && (
                  <ShortHandDesc
                    heading="Effective cost"
                    desc={`${
                      pox?.currency
                    } ${pox?.effective_cost?.toLocaleString()}`}
                  />
                )}
              </div>
            </>
          )}

          {tab === "More Info" && (
            <>
              <div className="d-md-flex align-items-center gap-5">
                <div className="d-flex align-items-center gap-5">
                  <ShortHandDesc
                    heading="Published on"
                    desc={new Date(pox?.createdAt).toLocaleDateString()}
                  />
                </div>

                {user?.uid && user?.uid === pox?.publishedBy?.uid && (
                  <StatusActions pox={pox} />
                )}
              </div>
              <Description description={pox.description} />
            </>
          )}
        </div>
      );

    default:
      return <></>;
  }
};

const SubInfo = ({ pox, tab }) => {
  if (tab !== "Info") return <></>;

  switch (pox?.type) {
    case "Flat":
      return (
        <div className="sub-info">
          <div className="d-flex gap-5">
            <ShortHandDesc img={Bed} heading="Rooms" desc={pox?.room_config} />
            <ShortHandDesc img={Bath} heading="Bath" desc={pox?.bath} />
            <ShortHandDesc
              heading="Floor"
              img={FloorPlan}
              desc={convertAreaUnit(pox?.area, pox?.area_unit)}
            />
          </div>
        </div>
      );

    case "Plot":
      return (
        <div className="sub-info">
          <div className="d-flex gap-4">
            <ShortHandDesc
              heading="Plot"
              img={FloorPlan}
              desc={convertAreaUnit(pox?.area, pox?.area_unit)}
            />
            <ShortHandDesc heading="Plot Config" desc={pox?.plot_config} />
          </div>
        </div>
      );

    case "Estab":
      return (
        <div className="sub-info">
          <div className="d-flex gap-4">
            <ShortHandDesc
              heading="Floor"
              img={FloorPlan}
              desc={convertAreaUnit(pox?.area, pox?.area_unit)}
            />
          </div>
        </div>
      );

    default:
      return <></>;
  }
};

const PropertyInfo = (props) => {
  return (
    <>
      <SubInfo {...props} />
      <MainInfo {...props} />
    </>
  );
};

const Description = ({ desc }) => {
  return (
    <ShortHandDesc
      heading={"Description"}
      desc={Boolean(desc) ? desc : "No description..."}
    />
  );
};

export default PropertyInfo;
