import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logInSuccess: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.loading = false;
    },
    logOutSuccess: (state) => {
      state.user = null;
      state.token = null;
    },
    updateUserProfile: (state, action) => {
      state.user = action.payload;
    },
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { logInSuccess, logOutSuccess, updateUserProfile, updateLoading } =
  authSlice.actions;

export const getUser = (state) => state.auth.user;

export const getAuthLoading = (state) => state.auth.loading;

export default authSlice.reducer;
