import { useEffect, useState } from "react";
import { useMapEvents } from "react-leaflet";
import { FiLoader } from "react-icons/fi";
import { MdMyLocation } from "react-icons/md";
import { useWindowDimensions } from "hooks";
import { toast } from "react-toastify";

const Navigator = ({ LAST_POX_POPUP_OPENED }) => {
  const mapEvents = useMapEvents({});
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowDimensions();
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      () => {},
      () => {
        setHidden(true);
      }
    );
  }, []);

  const navigate_to_user_location = () => {
    if (!isLoading) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          mapEvents.setView(
            { lat: position.coords.latitude, lng: position.coords.longitude },
            width <= 480 ? 18 : 14,
            {
              animate: true,
              pan: {
                duration: 1,
              },
            }
          );
          localStorage.setItem(
            LAST_POX_POPUP_OPENED,
            JSON.stringify([
              position.coords.latitude,
              position.coords.longitude,
            ])
          );
          setIsLoading(false);
        },
        (err) => {
          toast.info(err.message);
          setIsLoading(false);
          setHidden(true);
        }
      );
    }
  };

  if (hidden) return null;

  return (
    <div className="navigator">
      <button
        type="button"
        onClick={navigate_to_user_location}
        title="current location"
        disabled={isLoading}
      >
        {isLoading ? <FiLoader /> : <MdMyLocation />}
      </button>
    </div>
  );
};

export default Navigator;
