import { Fragment, useState } from "react";
import L from "leaflet";
import { FeatureGroup, Marker, Rectangle } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "redux/features/auth.slice";
import { updateGeofenceRerender } from "redux/features/other.slice";
import { Tooltip, Modal } from "react-bootstrap";
import { GeofenceForm } from "components/Forms";
import { useAxiosPrivate } from "hooks";
import { ReactComponent as InfoSvg } from "assets/svg/info.svg";
import LocationSvg from "assets/svg/marker.svg";
import "leaflet-draw/dist/leaflet.draw.css";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { createClusterCustomIcon } from "./utils";
import PoxMarker from "./PoxMarker";

const LocationIcon = L.icon({
  iconUrl: LocationSvg,
  iconRetinaUrl: LocationSvg,
  iconSize: [30, 30],
  className: `marker `,
});

export default function Geofencing() {
  const user = useSelector(getUser);
  const axiosPrivate = useAxiosPrivate();
  const [fence, setFence] = useState(null);
  const [show, setShow] = useState(false);
  const [iShow, setIShow] = useState(false);

  const { geofence_rerender } = useSelector((state) => state.other);
  const dispatch = useDispatch();

  const openInfo = () => setIShow(true);
  const closeInfo = () => setIShow(false);

  const handleClose = () => {
    setShow(false);
  };

  const { isLoading, data, refetch } = useQuery(
    ["geofences"],
    async () => {
      const res = await axiosPrivate.get("/geofences");
      return res.data?.geofences;
    },
    {
      enabled: !!user,
      onSuccess() {
        dispatch(updateGeofenceRerender(false));
        return false;
      },
    }
  );

  const onSuccess = () => {
    refetch();
  };

  const editMutation = useMutation({
    mutationFn: async ({ id, payload }) => {
      await axiosPrivate.patch(`/geofences/${id}`, payload);
    },
    onSuccess() {
      onSuccess();
      dispatch(updateGeofenceRerender(true));
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (id) => {
      await axiosPrivate.delete(`/geofences/${id}`);
    },
    onSuccess,
  });

  const onCreated = (e) => {
    const newFence = {
      id: e.layer._leaflet_id,
      bounds: [
        [e.layer._bounds._southWest.lat, e.layer._bounds._southWest.lng],
        [e.layer._bounds._northEast.lat, e.layer._bounds._northEast.lng],
      ],
      layer_type: e.layerType,
      color: e.layer.options?.color,
    };
    setShow(true);
    setFence(newFence);
  };

  const onEdited = (e) => {
    e.layers.eachLayer((a) => {
      editMutation.mutate({
        id: a.options.id ?? a._leaflet_id,
        payload: {
          bounds: [
            [a._bounds._southWest.lat, a._bounds._southWest.lng],
            [a._bounds._northEast.lat, a._bounds._northEast.lng],
          ],
        },
      });
    });
  };

  const onDeleted = (e) => {
    e.layers.eachLayer((a) => {
      deleteMutation.mutate(a.options.id ?? a._leaflet_id);
    });
  };

  if (isLoading || geofence_rerender) return null;

  return (
    <>
      <FeatureGroup>
        <EditControl
          position="bottomright"
          onCreated={onCreated}
          onEdited={onEdited}
          onDeleted={onDeleted}
          draw={{
            marker: false,
            circle: false,
            circlemarker: false,
            polygon: false,
            polyline: false,
          }}
        />
        {data?.map((fence) => (
          <Fragment key={fence.id}>
            <Rectangle bounds={fence.bounds} id={fence.id} color={fence.color}>
              <Tooltip>
                <span style={{ color: fence.color }}>{fence.name}</span>
              </Tooltip>
            </Rectangle>
            <Marker
              icon={LocationIcon}
              position={[
                (fence.bounds[0][0] + fence.bounds[1][0]) / 2,
                (fence.bounds[0][1] + fence.bounds[1][1]) / 2,
              ]}
            >
              <Tooltip>
                <span style={{ color: fence.color }}>{fence.name}</span>
              </Tooltip>
            </Marker>
          </Fragment>
        ))}
      </FeatureGroup>

      {data?.map((fence) => (
        <MarkerClusterGroup
          key={fence.id}
          showCoverageOnHover
          spiderfyOnMaxZoom
          iconCreateFunction={createClusterCustomIcon()}
        >
          <PoxMarker data={fence.poxes} />
        </MarkerClusterGroup>
      ))}

      {show && (
        <GeofenceForm {...{ show: show && fence, handleClose, fence }} />
      )}

      <button className="info-btn" onClick={openInfo}>
        <InfoSvg />
      </button>

      <Modal
        show={iShow}
        onHide={closeInfo}
        centered
        size="sm"
        className="info-modal"
      >
        <Modal.Header
          closeButton
          className="fw-bold py-3 d-flex justify-content-center border-0"
        >
          Mark a region on map as Geofence and get notified when any new pox is
          added in that Geofence region.
        </Modal.Header>
        <Modal.Body
          className="border-0 pt-0"
          style={{ fontSize: "0.85rem", color: "#1F1F1F80" }}
        >
          <span className="fw-bold d-block">Steps</span>
          <ul className="mb-0">
            {[
              "Pinch in-out zoom and move to region you are interested in.",
              "Select rectangle button, now on the area you are interested in, tap and drag to adjust the size of shape.",
              "Fill in the required details, Click on save to add the Geofence.",
              "The Geofence list will be updated with the newly added Geofence.",
            ].map((e, idx) => {
              return <li key={idx}>{e}</li>;
            })}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
}
