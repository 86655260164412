import { useAxiosPrivate } from "hooks";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateGeofenceRerender } from "redux/features/other.slice";
import { useDispatch } from "react-redux";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});

export default function GeofenceForm({ show, handleClose, fence, id }) {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const onClose = () => {
    handleClose();
    window.location.reload();
  }
  const formik = useFormik({
    initialValues: id
      ? { name: fence.name, pox_type: fence.pox_type, color: fence.color }
      : {
          name: "",
          pox_type: "All",
          color: "#3388ff",
        },
    validationSchema,
    onSubmit: async (values) => {
      if (id) {
        editMutation.mutate({ ...values });
      } else {
        createMutation.mutate({ ...fence, ...values });
      }
      handleClose();
    },
  });

  const onSuccess = () => {
    queryClient.invalidateQueries(["geofences"]);
    queryClient.invalidateQueries(["web-view-geofences"]);
    dispatch(updateGeofenceRerender(true));
  };

  const createMutation = useMutation({
    mutationFn: async (payload) => {
      await axiosPrivate.post("/geofences", payload);
    },
    onSuccess,
  });

  const editMutation = useMutation({
    mutationFn: async (payload) => {
      await axiosPrivate.patch(`/geofences/${id}`, payload);
    },
    onSuccess,
  });

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold fs-6 text-capitalize">{`Create Geofence`}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <FloatingLabel
            controlId={"name"}
            className="mb-3"
            aria-autocomplete="none"
            label={
              <span className="fw-semibold d-flex gap-1">
                <span className="text-danger">*</span>
                {"Geofence Name"}
              </span>
            }
          >
            <Form.Control
              name={"name"}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.name && formik.errors.name}
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </FloatingLabel>

          <Form.Group controlId={"pox_type"} className="mb-4">
            <Form.Label className="fw-semibold" style={{ fontSize: "0.87rem" }}>
              {"Geofence Alert Pox Type"}
            </Form.Label>
            <div className="d-flex gap-2 flex-wrap">
              {["All", "Flat", "Plot", "Estab"].map((e) => {
                return (
                  <Form.Check
                    key={e}
                    className={`custom-radio-wrapper ${
                      formik.values.pox_type === e ? "active" : ""
                    }`}
                  >
                    <Form.Check.Label
                      style={{
                        fontSize: "0.85rem",
                        fontWeight: "500",
                        margin: 0,
                        lineHeight: 0,
                      }}
                    >
                      {e}
                    </Form.Check.Label>
                    <Form.Check.Input
                      type="radio"
                      name={"pox_type"}
                      value={formik.values.pox_type}
                      onChange={() => {
                        formik.setFieldValue("pox_type", e);
                      }}
                      onBlur={formik.handleBlur}
                      style={{ cursor: "pointer" }}
                    />
                  </Form.Check>
                );
              })}
            </div>
          </Form.Group>

          <Form.Group controlId="color">
            <Form.Label className="fw-semibold" style={{ fontSize: "0.87rem" }}>
              {"Geofence Identifier Color"}
            </Form.Label>
            <Form.Control
              type="color"
              name="color"
              value={formik.values.color}
              title="Choose your color"
              onChange={formik.handleChange}
              onAbort={formik.handleBlur}
            />
          </Form.Group>
        </Modal.Body>
        {/* FOOTER */}
        <Modal.Footer as={Row} style={{ margin: 0 }}>
          <Col className="d-flex justify-content-center">
            <Button
              type="button"
              variant="danger"
              style={{ width: "100%", borderRadius: 8 }}
              onClick={onClose}
              disabled={createMutation.isLoading || editMutation.isLoading}
            >
              Cancel
            </Button>
          </Col>
          <Col className="d-flex justify-content-center">
            <Button
              type="submit"
              variant="primary"
              className="text-capitalize"
              style={{ width: "100%", borderRadius: 8 }}
              disabled={createMutation.isLoading || editMutation.isLoading}
            >
              {createMutation.isLoading || editMutation.isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Submit"
              )}
            </Button>
          </Col>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
