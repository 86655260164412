import { currencies } from "data/options";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrency } from "redux/features/map.slice";

export default function CurrencyFilter() {
  const currency = useSelector((state) => state.map.currency);
  const dispatch = useDispatch();

  return (
    <div className="currency-filter">
      <DropdownButton variant="light" title={currency ? currency : "---"}>
        <Dropdown.Item
          onClick={() => {
            dispatch(updateCurrency(""));
          }}
        >
          View ALL
        </Dropdown.Item>
        {currencies.map((e) => {
          if (!e.enabled) return null;
          return (
            <Dropdown.Item
              key={e.id}
              id={e.id}
              onClick={() => {
                dispatch(updateCurrency(e.id));
              }}
            >
              {e.id}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    </div>
  );
}
