import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { loginWithFacebook, logInWithGoogle } from "utils/firebase";
import { Button, Modal } from "react-bootstrap";
import { ReactComponent as GoogleSvg } from "assets/svg/Google.svg";
import { ReactComponent as FacebookSvg } from "assets/svg/Facebook.svg";
import { MapLoader } from "components";

export default function ProtectedPage() {
  const { user, loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // login handler
  const handleLogin = async (type) => {
    try {
      switch (type) {
        case "facebook":
          await loginWithFacebook();
          break;
        case "google":
          await logInWithGoogle();
          break;
        default:
          await logInWithGoogle();
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (loading)
    return (
      <Modal show centered size="sm" contentClassName="bg-transparent border-0">
        <Modal.Body className="d-flex align-items-center justify-content-center">
          <MapLoader />
        </Modal.Body>
      </Modal>
    );
  else if (!user)
    return (
      <Modal
        show={true}
        onHide={() => navigate(-1)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontWeight: 700 }}
          >
            Login
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#ddd" }}>
          <div className="d-grid gap-4 p-3">
            <Button
              variant="light"
              className="d-flex align-items-center justify-content-center"
              style={{ height: 48 }}
              onClick={() => handleLogin("google")}
            >
              <GoogleSvg style={{ height: 30 }} /> Login with Google
            </Button>

            <div className="divider">
              <span>OR</span>
            </div>

            <Button
              variant="light"
              className="d-flex align-items-center justify-content-center gap-2"
              style={{ height: 48 }}
              onClick={() => handleLogin("facebook")}
            >
              <FacebookSvg style={{ height: 30 }} /> Login with Facebook
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );

  return <Outlet />;
}
