import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useAxiosPrivate } from "hooks";
import StatusModal from "./StatusModal";

export default function StatusActions({ pox }) {
  const axios = useAxiosPrivate();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(pox.status);

  const handleStatusModal = () => {
    setIsOpen(false);
    setStatus("inactive");
  };

  const update_mutation = useMutation({
    mutationFn: async () => {
      await axios.patch(`/poxes/${pox.id}`, {
        status: "active",
        inactive_reason: null,
      });
    },
    onSuccess() {
      setStatus("active");
      queryClient.invalidateQueries(["pox", { id: pox.id }]);
    },
    onError(error) {
      console.log("error", error);
    },
  });

  const handleActive = () => {
    update_mutation.mutate();
  };

  return (
    <>
      <div className="status-actions">
        <span style={{ color: "#A0A0A0" }}>Pox Status</span>

        <div className="d-flex align-items-center gap-2">
          <button
            type="button"
            className="status-btn"
            style={{
              backgroundColor: status === "active" ? "#34A853" : "#A0A0A0",
              color: status === "inactive" ? "#fff" : "#000",
            }}
            onClick={handleActive}
          >
            {update_mutation.isLoading ? "Changing status..." : "Active"}
          </button>
          <button
            type="button"
            className="status-btn"
            onClick={handleStatusModal}
            style={{
              backgroundColor: status === "inactive" ? "#EA4335" : "#A0A0A0",
              color: status === "inactive" ? "#fff" : "#000",
            }}
          >
            Inactive
          </button>
        </div>
      </div>

      {isOpen && (
        <StatusModal handleStatusModal={handleStatusModal} id={pox.id} />
      )}
    </>
  );
}
