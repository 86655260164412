import { ReactComponent as GoogleStoreSvg } from "assets/svg/google-store.svg";
import "./styles.scss";

export const GOOGLE_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.ucpox.app";
export default function StoreLinks() {
  return (
    <div className="store-links">
      <a target="_blank" href={GOOGLE_STORE_URL} rel="noreferrer">
        <GoogleStoreSvg />
      </a>
    </div>
  );
}
