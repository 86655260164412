import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import firebaseApp from "config/firebase";
import { toast } from "react-toastify";
import { v4 } from "uuid";

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export const auth = getAuth(firebaseApp);

export const logInWithGoogle = () =>
  signInWithPopup(auth, googleProvider)
    .then((userCred) => ({
      user: {
        uid: userCred.uid,
        displayName: userCred.displayName,
        email: userCred.email,
        phoneNumber: userCred.phoneNumber,
        photoURL: userCred.photoURL,
      },
      token: userCred._tokenResponse.idToken,
    }))
    .catch((err) => console.error(err));

export const loginWithFacebook = () =>
  signInWithPopup(auth, facebookProvider)
    .then((userCred) => ({
      user: {
        uid: userCred?.uid,
        displayName: userCred?.displayName,
        email: userCred?.email,
        phoneNumber: userCred?.phoneNumber,
        photoURL: userCred?.photoURL,
      },
      token: userCred?._tokenResponse.idToken,
    }))
    .catch((err) => {
      console.error({ err });
      if (err?.code === "auth/account-exists-with-different-credential") {
        toast.info("Email has been used to sign in with a different method");
      }
      if (err?.code === "auth/user-disabled") {
        toast.info(
          "Your account has been disable, contact the Admin to re-enable it"
        );
      }
    });

export const logOut = () =>
  signOut(auth)
    .then((res) => res)
    .catch((err) => console.error(err));

export const storage = getStorage(firebaseApp);

export const customRequest = async ({
  file,
  onSuccess,
  onError,
  onProgress,
}) => {
  const name = v4();
  const storageRef = ref(storage, `/pox_images/${name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const percent = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // update progress
      onProgress(percent);
    },
    (err) => {
      // eslint-disable-next-line no-console
      console.log("Error: ", err);
      const error = new Error("Some error");
      toast.error(`There was an error`);
      onError({ error });
    },
    () => {
      // download url
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        onSuccess({ pathname: `pox_images/${name}`, url });
        toast.success(`${file.name} file uploaded successfully`, {
          style: { fontSize: "0.65rem" },
        });
      });
    }
  );
};

export const onRemove = (file, cb) => {
  // cancel upload if still uploading
  if (file?.status === "uploading" || file?.status === "error") {
    return true;
  }

  const url = file.url;
  const desertRef = ref(storage, url);
  deleteObject(desertRef)
    .then(() => {
      cb?.();
      return true;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log("Error:", error);
      return false;
    });

  return undefined;
};

export const uploadChatFile = async (
  { file, onSuccess, onError, onProgress },
  chatid
) => {
  const name = v4();
  const ext = file.name.split(".").pop();
  const pathname = `/chats/${chatid}/${name}.${ext}`;
  const storageRef = ref(storage, pathname);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const percent = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // update progress
      onProgress?.(percent);
    },
    (err) => {
      // eslint-disable-next-line no-console
      console.log("Error: ", err);
      const error = new Error("Some error");
      toast.error(`There was an error`);
      onError?.({ error });
    },
    () => {
      // download url
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        onSuccess?.({ pathname, url });
        toast.success(`${file.name} file uploaded successfully`);
      });
    }
  );
};
