import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import {
  FaCheckCircle,
  FaEdit,
  FaMapMarkerAlt,
  FaTimesCircle,
  FaTrash,
} from "react-icons/fa";
import { DeletePoxModal } from "components";

let iconString = "";
export default function PoxCard({ images, address, name, type, status, id }) {
  if (type === "Flat") iconString = "home";
  if (type === "Plot") iconString = "square";
  if (type === "Estab") iconString = "building";

  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Card
        className="shadow-none border-0 btn p-0"
        style={{
          background: status === "active" ? "#F0F2F5" : "#EA43351F",
        }}
      >
        <Card.Body className="d-flex justify-content-between px-3 gap-3">
          <div className="pox-image">
            <img src={images[0]?.url || "/assets/img/ucpox_ux.png"} alt="pox" />
          </div>
          <div className="flex-grow-1 d-flex flex-column justify-content-between align-items-start">
            <Link
              to={`/${type.toLowerCase()}/${id}`}
              className="text-truncate"
              style={{ fontWeight: 700, fontSize: "0.85rem", color: "#042954" }}
            >
              {name}
            </Link>
            <div
              className="text-truncate d-inline-block"
              style={{ fontSize: "0.7rem", color: "#042954" }}
            >
              <FaMapMarkerAlt />
              &nbsp;
              {address}
            </div>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ width: "100%" }}
            >
              <div
                style={{
                  fontSize: "0.65rem",
                  fontWeight: 700,
                  color: "#042954",
                }}
              >
                <i className={`fa fa-${iconString}`}></i>&nbsp;
                {type}
              </div>

              <div
                className="text-capitalize d-flex align-items-center gap-1"
                style={{
                  fontSize: "0.65rem",
                  fontWeight: 600,
                  color: status === "active" ? "#34A853" : "#EA4335",
                }}
              >
                {status === "active" ? <FaCheckCircle /> : <FaTimesCircle />}
                {status}
              </div>
            </div>
          </div>
          <div className="actions d-flex justify-content-evenly align-items-center">
            <Button
              className="p-2"
              size="sm"
              style={{ lineHeight: 0, borderRadius: 6, opacity: "70%" }}
              onClick={() => navigate(`/poxes/${id}/edit`)}
            >
              <FaEdit size={"0.65em"} />
            </Button>
            <Button
              className="p-2"
              variant="danger"
              size="sm"
              style={{ lineHeight: 0, borderRadius: 6, opacity: "70%" }}
              onClick={() => setShow(true)}
            >
              <FaTrash size={"0.65em"} />
            </Button>
          </div>
        </Card.Body>
      </Card>

      <DeletePoxModal
        show={show}
        onHide={() => setShow(false)}
        pox={{ id, images }}
      />
    </>
  );
}
