import { useDispatch, useSelector } from "react-redux";
import { endGuidedTour, setGuidedTourStage } from "redux/features/other.slice";
import GuestPNG from "assets/img/profile.png";
import "./styles.scss";

export default function GuidedTour() {
  const dispatch = useDispatch();
  const { guided_tour } = useSelector((state) => state.other);
  const { user, loading } = useSelector((state) => state.auth);

  if (loading) return null;

  if (guided_tour.init && guided_tour.stage === 0)
    return (
      <div className="guided-tour-init-modal">
        <div className="content">
          <div>
            <img
              referrerPolicy="no-referrer"
              src={user?.photoURL || GuestPNG}
              alt={user?.displayName || "Guest"}
            />
            <span>Salutations, {user?.displayName || "Guest"}!</span>
          </div>
          <div>
            <span>Ready to begin your real estate journey? </span>
            <span>Let us show you around!</span>
          </div>
          <div>
            <button
              type="button"
              onClick={() => {
                dispatch(setGuidedTourStage(1));
              }}
            >
              Take a guided tour
            </button>
            <button
              type="button"
              onClick={() => {
                dispatch(endGuidedTour());
              }}
            >
              Skip Tour
            </button>
          </div>
        </div>
      </div>
    );

  return null;
}
