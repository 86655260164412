import { useEffect, useMemo, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import { GuidedTourWrapper, StatsLoader } from "components";
import { useAxiosPrivate } from "hooks";
import "./style.scss";

const appendZero = (val) => (val < 10 ? "0" + val : val);

const Statistics = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState(null);
  const [view, setView] = useState(false);
  const axios = useAxiosPrivate();

  async function fetchPoxData() {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/poxes/stats"
      );
      setStats(data.stats);
    } catch (err) {
      toast.err(err?.response?.data?.message || err?.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchPoxData();

    return () => {
      setStats(null);
      setIsLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listItems = useMemo(
    () => [
      {
        name: "FLAT",
        value: appendZero(stats?.flat_active || 0),
      },
      {
        name: "PLOT",
        value: appendZero(stats?.plot_active || 0),
      },
      {
        name: "ESTAB",
        value: appendZero(stats?.estab_active || 0),
      },
      {
        name: "TOTAL (Active)",
        value: appendZero(stats?.total_active || 0),
      },
      {
        name: "TOTAL (ALL)",
        value: appendZero(stats?.total || 0),
      },
    ],
    [stats]
  );

  return (
    <GuidedTourWrapper stage={6} className="statistics">
      <button
        className={view ? "view-btn active" : "view-btn"}
        type="button"
        onClick={() => setView((prev) => !prev)}
      >
        {view ? <FaEyeSlash /> : <FaEye />}
        <span>{view ? "Hide Stats" : "View Stats"}</span>
      </button>

      {view && (
        <div className="poxes_stats">
          {isLoading && (
            <div className="overlay-loader">
              <StatsLoader />
            </div>
          )}

          <ul>
            {listItems.map(({ name, value }) => (
              <li key={name}>
                <span className="value">{value}</span>
                <span className="name">{name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </GuidedTourWrapper>
  );
};

export default Statistics;
