const flat_icon = (fill = "#17185C") => `<svg
      width="24"
      height="24"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.2341 3.33301H15.7674C13.8231 3.33301 12.2499 4.83134 12.2499 6.68301V16.078L4.02137 23.8147C3.77482 24.0469 3.60627 24.3437 3.53711 24.6673C3.46795 24.9909 3.5013 25.3268 3.63293 25.6323C3.76455 25.9378 3.98852 26.1992 4.2764 26.3834C4.56428 26.5675 4.90311 26.6659 5.24987 26.6663V34.9997C5.24987 35.4417 5.43424 35.8656 5.76243 36.1782C6.09062 36.4907 6.53574 36.6663 6.99987 36.6663H34.9999C35.464 36.6663 35.9091 36.4907 36.2373 36.1782C36.5655 35.8656 36.7499 35.4417 36.7499 34.9997V6.68134C36.7499 4.83134 35.1784 3.33301 33.2341 3.33301ZM19.1134 25.1847V33.333H8.74987V24.053L13.9771 19.138L19.1134 24.143V25.1847ZM22.7499 14.9997H19.2499V11.6663H22.7499V14.9997ZM29.7499 28.333H26.2499V24.9997H29.7499V28.333ZM29.7499 21.6663H26.2499V18.333H29.7499V21.6663ZM29.7499 14.9997H26.2499V11.6663H29.7499V14.9997Z"
        fill="currentColor"
      />
      <path d="M12.25 25H15.75V28.3333H12.25V25Z" fill="currentColor" />
    </svg>
`;

const plot_icon = (fill = "#17185C") => `<svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#plot_clip)">
        <path
          d="M41.9459 37.7989L37.5922 22.541C37.4193 21.9356 36.8663 21.5183 36.237 21.5183H26.225C28.2336 18.8074 30.1888 15.2347 30.1888 11.5975C30.1888 6.52892 26.0669 2.40527 21.0001 2.40527C15.9332 2.40527 11.8111 6.52892 11.8111 11.5975C11.8111 15.2348 13.7663 18.8074 15.7751 21.5183H5.76298C5.13369 21.5183 4.58061 21.9356 4.4078 22.541L0.0540723 37.7989C-0.06712 38.224 0.0178702 38.6817 0.284295 39.0346C0.550579 39.3876 0.967045 39.5951 1.40925 39.5951H40.5907C41.0329 39.5951 41.4494 39.3876 41.7157 39.0346C41.9821 38.6817 42.0671 38.224 41.9459 37.7989ZM21.0001 5.22381C24.5126 5.22381 27.3703 8.08307 27.3703 11.5974C27.3703 16.3133 22.9689 21.279 21.0001 23.2644C19.0312 21.279 14.6297 16.3133 14.6297 11.5974C14.6297 8.08307 17.4875 5.22381 21.0001 5.22381ZM18.1175 24.3371C19.1743 25.4795 19.9669 26.1854 20.0686 26.2752C20.3349 26.5094 20.6674 26.6268 21.0001 26.6268C21.3329 26.6268 21.6655 26.5094 21.9316 26.2752C22.0333 26.1854 22.8259 25.4795 23.8826 24.3371H35.1735L35.5653 25.7098L30.1139 30.2528L6.37036 25.9358L6.8267 24.3371H18.1175ZM10.7824 36.7764V29.6028L27.2917 32.6046L22.2854 36.7764H10.7824ZM5.59314 28.6595L7.96367 29.0904V36.7765H3.27705L5.59314 28.6595ZM26.6882 36.7764L36.4109 28.674L38.7229 36.7764H26.6882Z"
          fill="currentColor"
        />
        <path
          d="M24.7912 11.5974C24.7912 9.49584 23.0904 7.78613 20.9998 7.78613C18.9091 7.78613 17.208 9.49584 17.208 11.5974C17.208 13.6987 18.9089 15.4087 20.9998 15.4087C23.0904 15.4087 24.7912 13.6987 24.7912 11.5974ZM20.0268 11.5974C20.0268 11.05 20.4634 10.6047 20.9999 10.6047C21.5363 10.6047 21.9727 11.0498 21.9727 11.5974C21.9727 12.145 21.5363 12.5901 20.9999 12.5901C20.4634 12.59 20.0268 12.1448 20.0268 11.5974Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="plot_clip">
          <rect width="42" height="42" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
`;

const estab_icon = (fill = "#042954") => `<svg
      width="24"
      height="24"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4613_12324)">
        <path
          d="M9.29688 42H39.7031C40.3594 42 40.9062 41.8359 41.3438 41.5078C41.7812 41.1797 42 40.7695 42 40.2773V3.52734C42 2.54297 41.5443 1.70898 40.6328 1.02539C39.7214 0.341797 38.6094 0 37.2969 0H4.70312C3.39062 0 2.27865 0.341797 1.36719 1.02539C0.455729 1.70898 0 2.54297 0 3.52734V40.2773C0 40.7695 0.21875 41.1797 0.65625 41.5078C1.09375 41.8359 1.64062 42 2.29688 42H9.29688ZM18.7031 38.4727H14V31.5H18.7031V38.4727ZM28 38.4727H23.2969V31.5H28V38.4727ZM4.70312 3.52734H37.2969V38.4727H32.7031V31.5C32.7031 30.5703 32.2292 29.75 31.2812 29.0391C30.3333 28.3281 29.2396 27.9727 28 27.9727H14C12.7604 27.9727 11.6667 28.3281 10.7188 29.0391C9.77083 29.75 9.29688 30.5703 9.29688 31.5V38.4727H4.70312V3.52734ZM9.29688 6.97266H14V10.5H9.29688V6.97266ZM18.7031 6.97266H23.2969V10.5H18.7031V6.97266ZM28 6.97266H32.7031V10.5H28V6.97266ZM9.29688 14.0273H14V17.4727H9.29688V14.0273ZM18.7031 14.0273H23.2969V17.4727H18.7031V14.0273ZM28 14.0273H32.7031V17.4727H28V14.0273ZM9.29688 21H14V24.5273H9.29688V21ZM18.7031 21H23.2969V24.5273H18.7031V21ZM28 21H32.7031V24.5273H28V21Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4613_12324">
          <rect width="42" height="42" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
`;

export { flat_icon, plot_icon, estab_icon };
