import React from 'react';
import loader1 from './1488.gif';
import loader2 from './805.gif';
import './styles.css';

export const BasicLoader = () => {
	return (
		<div className='d-flex justify-content-center align-item-center'>
			<img src={loader2} alt='loader' />
		</div>
	);
};

export const StatsLoader = () => {
	return (
		<div className='d-flex justify-content-center align-item-center'>
			<img src={loader1} alt='loader' />
		</div>
	);
};

export const MapLoader = () => {
	return (
		<div className='map-loader overlay-loader d-flex justify-content-center align-items-center'>
			<div className='align-items-center'>
				<img src={loader2} alt='loader' />
			</div>
		</div>
	);
};
