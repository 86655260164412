import "./styles.scss";
import { MapContainer, TileLayer } from "react-leaflet";
import GeofenceMap from "./GeofenceMap";
import GeofenceInfo from "./GeofenceInfo";
import GeofenceListing from "./GeofenceListing";

export default function GeofenceWebView({ auth_token }) {
  return (
    <div className="webview-geofence">
      <MapContainer
        center={[30, 40]}
        zoom={2}
        minZoom={1}
        worldCopyJump={true}
        tap={false}
        className="webview-map"
      >
        <TileLayer url="https://api.mapbox.com/styles/v1/decorsnap/ckqnfxsql0a7417o9413rjig4/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZGVjb3JzbmFwIiwiYSI6ImNrcTVjNmRwdzByM20ybnBwenVpbTBwcG8ifQ.-CUcCijKzxvvfry7qqxdzQ" />
        <GeofenceMap token={auth_token} />
        <GeofenceListing token={auth_token} />
        <GeofenceInfo />
      </MapContainer>
    </div>
  );
}
