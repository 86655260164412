import { StrictMode } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { store } from "redux/store";

import App from "App";
import "./global.scss";
import "react-leaflet-markercluster/dist/styles.min.css";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();

render(
  <StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
          <ToastContainer />
        </Provider>
      </QueryClientProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);
