import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  preview_opened: true,
  preview_pox: null,
  geofence_rerender: false,
  guided_tour: {
    init: false,
    stage: 0,
  },
};

const otherSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setPreview(state, action) {
      state.preview_pox = action.payload;
    },
    closePreview(state, _action) {
      state.preview_opened = false;
      state.preview_pox = null;
    },
    updateGeofenceRerender(state, action) {
      state.geofence_rerender = action.payload;
    },
    initGuidedTour(state, _action) {
      state.guided_tour.init = true;
      state.guided_tour.stage = 0;
    },
    setGuidedTourStage(state, action) {
      state.guided_tour.stage = action.payload;
    },
    endGuidedTour(state, _action) {
      state.guided_tour.init = false;
      state.guided_tour.stage = 0;
      localStorage.setItem("UCPOX_has_been_guided", true);
    },
  },
});

export const {
  setPreview,
  closePreview,
  updateGeofenceRerender,
  initGuidedTour,
  setGuidedTourStage,
  endGuidedTour,
} = otherSlice.actions;

export default otherSlice.reducer;
