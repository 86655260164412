import { useEffect, useState } from "react";
import { useForm } from "@formspree/react";
import { Form, Button } from "react-bootstrap";
import { FiMail } from "react-icons/fi";
import { toast } from "react-toastify";
import { ReactComponent as FacebookIcon } from "assets/svg/facebook_icon.svg";
import { ReactComponent as TwitterIcon } from "assets/svg/twitter_icon.svg";
import { ReactComponent as InstagramIcon } from "assets/svg/instagram_icon.svg";
// import { ReactComponent as AppStoreIcon } from "assets/svg/app_store_icon.svg";
import { ReactComponent as GoogleStoreIcon } from "assets/svg/google_store_icon.svg";
import { GOOGLE_STORE_URL } from "components/MainLayout/StoreLinks";
import "./styles.scss";

const groups = [
  {
    id: "email",
    name: "email",
    type: "email",
    as: "input",
    placeholder: "ex: ucpox001@gmail.com",
  },
  {
    id: "message",
    name: "message",
    type: "text",
    as: "textarea",
    placeholder: "Type your message here",
  },
];

const ContactForm = ({ closeModal }) => {
  const [state, handleSubmit] = useForm(
    process.env.REACT_APP_FORMSPREE_MAILID_CONTACT_US
  );
  const [formstate, setFormState] = useState({
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormState({
      ...formstate,
      [name]: value,
    });
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(e);
    setFormState({ email: "", message: "" });
  };

  useEffect(() => {
    if (state.succeeded) toast.success("Message sent successfully");
  }, [state.succeeded]);

  useEffect(() => {
    if (state.errors[0]) toast.error("Please try again later");
  }, [state.errors]);

  return (
    <div className="custom-modal">
      <div className="custom-modal-mask" onClick={closeModal} />
      <div className="custom-contactmodal-content">
        <div className="content p-3">
          <h1
            className="text-center mb-2"
            style={{ fontSize: "1.05em", color: "#042954", fontWeight: 600 }}
          >
            Contact Us
          </h1>
          <span
            className="text-center d-flex align-items-center justify-content-center"
            style={{ fontSize: "0.9em" }}
          >
            <FiMail style={{ margin: "3px" }} />
            admin@ucpox.com
          </span>
          <span
            className="text-center my-1 opacity-25 d-block"
            style={{ fontSize: "1em" }}
          >
            OR
          </span>
          <p className="text-center my-0" style={{ fontSize: "0.9em" }}>
            Get contacted by providing the below details
          </p>

          <Form noValidate onSubmit={onSubmit} style={{ marginTop: "1em" }}>
            {groups.map(({ id, name, type, as, placeholder }) => {
              return (
                <Form.Group key={id} className="mb-3" controlId={id}>
                  <Form.Label
                    style={{
                      textTransform: "capitalize",
                      fontSize: "0.9em",
                      marginBottom: "0",
                    }}
                  >
                    {name}
                  </Form.Label>

                  <Form.Control
                    type={type}
                    name={name}
                    as={as}
                    placeholder={placeholder}
                    onClick={handleClick}
                    value={formstate[name]}
                    style={{
                      height: `${id === "message" ? "80px" : "auto"}`,
                      backgroundColor: "#F2F3F8",
                      fontSize: "0.85em",
                      padding: "0.35em 0.75em",
                    }}
                    onChange={handleChange}
                    disabled={state.submitting}
                    required
                  />
                </Form.Group>
              );
            })}

            <div className="center-btn">
              <Button
                variant="primary"
                type="submit"
                className="px-5 py-1"
                style={{ fontSize: "0.9em" }}
                disabled={state.submitting}
              >
                Send
              </Button>
            </div>
          </Form>
          <div className="icons my-3">
            <a
              href="https://www.facebook.com/ucpox"
              rel="noreferrer"
              target="_blank"
            >
              <FacebookIcon style={{ width: 24, height: 24 }} />
            </a>
            <a
              href="https://twitter.com/ucpox_"
              rel="noreferrer"
              target="_blank"
            >
              <TwitterIcon style={{ width: 24, height: 24 }} />
            </a>
            <a
              href="https://www.instagram.com/ucpox_/"
              rel="noreferrer"
              target="_blank"
            >
              <InstagramIcon style={{ width: 24, height: 24 }} />
            </a>
          </div>

          <div className="icons">
            {/* <div>
              <AppStoreIcon />
            </div> */}
            <a target="_blank" href={GOOGLE_STORE_URL} rel="noreferrer">
              <GoogleStoreIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
