/* eslint-disable react/prop-types */
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAxiosPrivate } from "hooks";
import { formatDay } from "utils/date";

function NotificationsDropdown({ close }) {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  const { user } = useSelector((state) => state.auth);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ["notifications", { uid: user?.uid }],
      async ({ pageParam = 0 }) => {
        const res = await axiosPrivate(`notifications?page=${pageParam}`);
        return res.data;
      },
      {
        getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
        enabled: !!user,
      }
    );

  const mark_all_as_read = useMutation({
    mutationFn: async () => {
      const res = await axiosPrivate.patch("notifications/read-all");
      return res.data;
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["notifications"]);
      queryClient.invalidateQueries(["unread-notifications"]);
      close();
    },
    onError: async (error) => {
      toast?.error(error?.response?.data?.message);
    },
  });

  const mark_individual_read = useMutation({
    mutationFn: async (id) => {
      await axiosPrivate.patch(`notifications/read/${id}`);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["notifications"]);
      queryClient.invalidateQueries(["unread-notifications"]);
    },
    onError: async (error) => {
      toast?.error(error?.response?.data?.message);
    },
  });

  return (
    <div className="custom-modal-wrapper">
      <button
        type="button"
        aria-label="close modal"
        onClick={close}
        className="mask"
      />
      <div className="child-wrapper">
        <div className="content">
          <div className="header">
            <span style={{ marginBottom: 0 }}>Notifications</span>

            {data?.pages[0]?.notifications.length > 0 && (
              <button
                type="button"
                className="btn btn-link"
                style={{ padding: 0, fontSize: "12px" }}
                onClick={mark_all_as_read.mutate}
              >
                {mark_all_as_read.isLoading ? "Loading..." : "Mark all as read"}
              </button>
            )}
          </div>

          <div className="body">
            {data?.pages
              ?.reduce((a, b) => {
                if (b?.notifications) {
                  return [...a, ...b.notifications];
                }
                return a;
              }, [])
              .map((item) => {
                return (
                  <div key={item._id} className="notification-item">
                    <span className="notification-type">
                      {item?.notification_type}
                    </span>

                    <div>
                      <Link
                        to={`${
                          item?.about.slice(0, 1) === "F"
                            ? "flat"
                            : item?.about.slice(0, 1) === "P"
                            ? "plot"
                            : "estab"
                        }/${item?.about}`}
                        onClick={() => {
                          close();
                          mark_individual_read.mutate(item._id);
                        }}
                      >
                        {item?.message}
                      </Link>

                      <span className="date">
                        {formatDay(new Date(item?.createdAt))}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="footer-section">
          {isLoading || isFetchingNextPage ? (
            <span style={{ fontSize: 10 }}>Loading...</span>
          ) : hasNextPage ? (
            <button
              type="button"
              className="load-more-btn"
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
            >
              Load More
            </button>
          ) : (
            data?.pages?.[1] && (
              <span style={{ fontSize: 10 }}>No more data</span>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default NotificationsDropdown;
