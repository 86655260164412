import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosIns from 'apis/axios';
import { Button } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { BasicLoader } from 'components';
import { projectInfoIconsMap } from 'components/MapView/utils';
import { ReactComponent as LocationIcon } from 'assets/svg/carbon_location.svg';
import './styles.scss';

export default function ProjectInfo() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState(null);

	const close = () => navigate('/');

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const res = await axiosIns.get(`/project-info/${id}`);
				setData(res.data);
				setIsLoading(false);
			} catch (error) {
				toast.error('No Pox with such Id');
				navigate('/', { replace: true });
			}
		};

		fetchData();

		return () => {
			setData(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='project-info-page'>
			<Link to='/' className='mask' />

			<div className='content'>
				{isLoading ? (
					<div className='modal-body'>
						<div
							style={{
								width: '100%',
								height: '100%',
								display: 'grid',
								placeItems: 'center',
								padding: '16px 0',
							}}>
							<BasicLoader />
						</div>
					</div>
				) : (
					data && (
						<div className='p-2'>
							<div className='logo'>
								<img src='/assets/img/logo_2.png' alt='ucpox' />
							</div>

							<div className='mobile-heading justify-content-between mb-4'>
								<h1>Project Info</h1>

								<Button variant='text' className='p-0' onClick={close}>
									<FaTimes />
								</Button>
							</div>

							<div className='desktop-heading d-flex justify-content-between mb-2'>
								<img
									src={projectInfoIconsMap.get(data?.category)}
									alt={data?.category}
								/>

								<Button variant='text' className='p-0' onClick={close}>
									<FaTimes />
								</Button>
							</div>

							<h2
								style={{
									fontSize: '1.35em',
									fontWeight: 700,
									color: '#042954',
								}}>
								{data?.title}
							</h2>

							<p
								className='d-flex align-items-center gap-1 mb-4'
								style={{ color: '#808080' }}>
								<LocationIcon />

								{data?.location}
							</p>

							{data?.details && <p>{data?.details}</p>}

							{data?.more_info_url && (
								<div className='d-flex justify-content-end'>
									<a
										href={data.more_info_url}
										target='__blank'
										rel='no-referrer'
										style={{ fontSize: '0.9em' }}>
										Read More
									</a>
								</div>
							)}
						</div>
					)
				)}
			</div>
		</div>
	);
}
