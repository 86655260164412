const COLORS = new Map([
  // ['Flat', ['#f24867', '#da415d', '#c23a52', '#a93248', '#912b3e']],
  // ['Plot', ['#ff8753', '#ff7b43', '#ff6e30', '#ff601c', '#ff5107']],
  ["Flat", ["#1378ee", "#0f62c3", "#0b4e9b", "#063b77", "#042954"]],
  ["Plot", ["#1378ee", "#0f62c3", "#0b4e9b", "#063b77", "#042954"]],
  ["Estab", ["#1378ee", "#0f62c3", "#0b4e9b", "#063b77", "#042954"]],
]);

const COST_OPTIONS = {
  lease_period: [
    {
      label: "0 - 365",
      color: (type) => COLORS.get(type)[0],
      minValue: 0,
      maxValue: 1 * 365,
    },
    {
      label: "365 - 1825",
      color: (type) => COLORS.get(type)[1],
      minValue: 1 * 365,
      maxValue: 5 * 365,
    },
    {
      label: "> 1825",
      color: (type) => COLORS.get(type)[2],
      minValue: 5 * 365,
      maxValue: null,
    },
  ],
  lease_cost: [
    {
      label: "0 - 1M",
      color: (type) => COLORS.get(type)[0],
      minValue: 0,
      maxValue: 1000000,
    },
    {
      label: "1 - 2.5M",
      color: (type) => COLORS.get(type)[1],
      minValue: 1000000,
      maxValue: 2500000,
    },
    {
      label: "2.5M - 5M",
      color: (type) => COLORS.get(type)[2],
      minValue: 2500000,
      maxValue: 5000000,
    },
    {
      label: "> 5M",
      color: (type) => COLORS.get(type)[3],
      minValue: 5000000,
      maxValue: null,
    },
  ],
  turnover_cost: [
    {
      label: "0 - 1M",
      color: (type) => COLORS.get(type)[0],
      minValue: 0,
      maxValue: 1000000,
    },
    {
      label: "1 - 2.5M",
      color: (type) => COLORS.get(type)[1],
      minValue: 1000000,
      maxValue: 2500000,
    },
    {
      label: "2.5M - 5M",
      color: (type) => COLORS.get(type)[2],
      minValue: 2500000,
      maxValue: 5000000,
    },
    {
      label: "> 5M",
      color: (type) => COLORS.get(type)[3],
      minValue: 5000000,
      maxValue: null,
    },
  ],
  sale_cost: [
    {
      label: "0 - 1M",
      color: (type) => COLORS.get(type)[0],
      minValue: 0,
      maxValue: 1000000,
    },
    {
      label: "1 - 2.5M",
      color: (type) => COLORS.get(type)[1],
      minValue: 1000000,
      maxValue: 2500000,
    },
    {
      label: "2.5M - 5M",
      color: (type) => COLORS.get(type)[2],
      minValue: 2500000,
      maxValue: 5000000,
    },
    {
      label: "> 5M",
      color: (type) => COLORS.get(type)[3],
      minValue: 5000000,
      maxValue: null,
    },
  ],
  area: [
    {
      label: "0 - 1K",
      color: (type) => COLORS.get(type)[0],
      minValue: 0,
      maxValue: 1000,
    },
    {
      label: "1K - 2.5K",
      color: (type) => COLORS.get(type)[1],
      minValue: 1000,
      maxValue: 2500,
    },
    {
      label: "2.5K - 5K",
      color: (type) => COLORS.get(type)[2],
      minValue: 2500,
      maxValue: 5000,
    },
    {
      label: "5K - 10K",
      color: (type) => COLORS.get(type)[3],
      minValue: 5000,
      maxValue: 10000,
    },
    {
      label: "> 10k",
      color: (type) => COLORS.get(type)[4],
      minValue: 10000,
      maxValue: null,
    },
  ],
  rent_cost: [
    {
      label: "0 - 5K",
      color: (type) => COLORS.get(type)[0],
      minValue: 0,
      maxValue: 5000,
    },
    {
      label: "5K - 10K",
      color: (type) => COLORS.get(type)[1],
      minValue: 5000,
      maxValue: 10000,
    },
    {
      label: "10K - 15K",
      color: (type) => COLORS.get(type)[2],
      minValue: 10000,
      maxValue: 15000,
    },
    {
      label: "15K - 20K",
      color: (type) => COLORS.get(type)[3],
      minValue: 15000,
      maxValue: 20000,
    },
    {
      label: "> 20K",
      color: (type) => COLORS.get(type)[4],
      minValue: 20000,
      maxValue: null,
    },
  ],
  deposit_cost: [
    {
      label: "0 - 10K",
      color: (type) => COLORS.get(type)[0],
      minValue: 0,
      maxValue: 10000,
    },
    {
      label: "10K - 20K",
      color: (type) => COLORS.get(type)[1],
      minValue: 10000,
      maxValue: 20000,
    },
    {
      label: "20K - 50K",
      color: (type) => COLORS.get(type)[2],
      minValue: 20000,
      maxValue: 50000,
    },
    {
      label: "> 50K",
      color: (type) => COLORS.get(type)[3],
      minValue: 50000,
      maxValue: null,
    },
  ],
};

const PARAMS_MAP = new Map([
  [
    "Flat",
    {
      values: {
        rent: {
          rent: COST_OPTIONS.rent_cost,
          deposit: COST_OPTIONS.deposit_cost,
        },
        sale: { cost: COST_OPTIONS.sale_cost },
      },
      options: {
        rent: [
          { label: "rent", key: "rent_cost" },
          { label: "deposit", key: "deposit_cost" },
        ],
        sale: [{ label: "cost", key: "sale_cost" }],
      },
    },
  ],
  [
    "Plot",
    {
      values: {
        lease: {
          cost: COST_OPTIONS.lease_cost,
          duration: COST_OPTIONS.lease_period,
        },
        sale: { cost: COST_OPTIONS.sale_cost, area: COST_OPTIONS.area },
      },
      options: {
        lease: [
          { label: "cost", key: "lease_cost" },
          { label: "duration", key: "lease_period" },
        ],
        sale: [
          { label: "cost", key: "sale_cost" },
          { label: "area", key: "area" },
        ],
      },
    },
  ],
  [
    "Estab",
    {
      values: {
        rent: {
          rent: COST_OPTIONS.rent_cost,
          deposit: COST_OPTIONS.deposit_cost,
        },
        sale: {
          cost: COST_OPTIONS.sale_cost,
          turnover: COST_OPTIONS.turnover_cost,
        },
      },
      options: {
        rent: [
          { label: "rent", key: "rent_cost" },
          { label: "deposit", key: "deposit_cost" },
        ],
        sale: [
          { label: "cost", key: "sale_cost" },
          { label: "turnover", key: "turnover_cost" },
        ],
      },
    },
  ],
]);

const FILTERS_MAP = new Map([
  [
    "Flat",
    {
      Rest: [
        {
          id: "0",
          title: "Built Up Area (sqft)",
          options: COST_OPTIONS.area.map((cost) => ({
            label: cost.label,
            values: `[${cost.minValue},${cost.maxValue}]`,
          })),
          queryHead: "area",
        },
        {
          id: "1",
          title: "Room Config",
          options: [
            { label: "1RK" },
            { label: "1BHK" },
            { label: "2BHK" },
            { label: "3BHK" },
          ],
          queryHead: "roomConfig",
        },
        {
          id: "2",
          title: "Facing",
          options: [
            { label: "North" },
            { label: "East" },
            { label: "South" },
            { label: "West" },
            { label: "North-East" },
            { label: "North-West" },
            { label: "South-East" },
            { label: "South-West" },
          ],
          queryHead: "facing",
        },
        {
          id: "3",
          title: "Floor",
          options: [
            { label: "Ground" },
            { label: "First" },
            { label: "Middle" },
            { label: "Top" },
          ],
          queryHead: "floor",
        },
      ],
      Rent: [
        {
          id: "4",
          title: "Rent Cost",
          options: COST_OPTIONS.rent_cost.map((cost) => ({
            label: cost.label,
            values: `[${cost.minValue},${cost.maxValue}]`,
          })),
          queryHead: "rent",
        },
        {
          id: "5",
          title: "Deposit Cost",
          options: COST_OPTIONS.deposit_cost.map((cost) => ({
            label: cost.label,
            values: `[${cost.minValue},${cost.maxValue}]`,
          })),
          queryHead: "deposit",
        },
      ],
      Sale: [
        {
          id: "4",
          title: "Selling Cost",
          options: COST_OPTIONS.sale_cost.map((cost) => ({
            label: cost.label,
            values: `[${cost.minValue},${cost.maxValue}]`,
          })),
          queryHead: "sale",
        },
      ],
    },
  ],
  [
    "Plot",
    {
      Rest: [
        // {
        // 	id: '0',
        // 	title: 'Sale Type',
        // 	options: [{ label: 'New' }, { label: 'Resale' }],
        // 	queryHead: 'saleType',
        // },
        {
          id: "1",
          title: "Area (sqft)",
          options: COST_OPTIONS.area.map((cost) => ({
            label: cost.label,
            values: `[${cost.minValue},${cost.maxValue}]`,
          })),
          queryHead: "area",
        },
        {
          id: "2",
          title: "Plot Configs",
          options: [
            { label: "Agricultural" },
            { label: "Residential" },
            { label: "Commercial" },
          ],
          queryHead: "plotConfig",
        },
        {
          id: "3",
          title: "Posted Since",
          options: [
            { label: "5 Days", timeFrame: "5" },
            { label: "15 Days", timeFrame: "15" },
            { label: "30 Days", timeFrame: "30" },
          ],
          queryHead: "postedSince",
        },
      ],
      Lease: [
        {
          id: "4",
          title: "Lease Amount",
          options: COST_OPTIONS.lease_cost.map((cost) => ({
            label: cost.label,
            values: `[${cost.minValue},${cost.maxValue}]`,
          })),
          queryHead: "lease",
        },
        {
          id: "5",
          title: "Lease Period (Days)",
          options: COST_OPTIONS.lease_period.map((cost) => ({
            label: cost.label,
            values: `[${cost.minValue},${cost.maxValue}]`,
          })),
          queryHead: "leasePeriod",
        },
      ],
      Sale: [
        {
          id: "4",
          title: "Selling Cost",
          options: COST_OPTIONS.sale_cost.map((cost) => ({
            label: cost.label,
            values: `[${cost.minValue},${cost.maxValue}]`,
          })),
          queryHead: "sale",
        },
      ],
    },
  ],
  [
    "Estab",
    {
      Rest: [
        {
          id: "0",
          title: "Annual Turnover",
          options: COST_OPTIONS.turnover_cost.map((cost) => ({
            label: cost.label,
            values: `[${cost.minValue},${cost.maxValue}]`,
          })),
          queryHead: "turnover",
        },
        {
          id: "1",
          title: "Space Area (sqft)",
          options: COST_OPTIONS.area.map((cost) => ({
            label: cost.label,
            values: `[${cost.minValue},${cost.maxValue}]`,
          })),
          queryHead: "area",
        },
      ],
      Rent: [
        {
          id: "2",
          title: "Rent Cost",
          options: COST_OPTIONS.rent_cost.map((cost) => ({
            label: cost.label,
            values: `[${cost.minValue},${cost.maxValue}]`,
          })),
          queryHead: "rent",
        },
        {
          id: "3",
          title: "Deposit Cost",
          options: COST_OPTIONS.deposit_cost.map((cost) => ({
            label: cost.label,
            values: `[${cost.minValue},${cost.maxValue}]`,
          })),
          queryHead: "deposit",
        },
      ],
      Sale: [
        {
          id: "2",
          title: "Selling Cost",
          options: COST_OPTIONS.sale_cost.map((cost) => ({
            label: cost.label,
            values: `[${cost.minValue},${cost.maxValue}]`,
          })),
          queryHead: "sale",
        },
      ],
    },
  ],
]);

export { COLORS, COST_OPTIONS, FILTERS_MAP, PARAMS_MAP };
