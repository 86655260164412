import { PoxForm } from "components";
import { CloseButton, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function CreatePoxPage() {
  const navigate = useNavigate();

  const handleCancel = () => {
    // eslint-disable-next-line no-alert
    const res = window.confirm(
      "Are you sure you want to leave this page? Changes would be lost"
    );
    if (res) navigate(-1);
  };

  return (
    <Container fluid="2xl" className="p-4">
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-3">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_2599_7069"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="34"
              height="34"
            >
              <rect width="34" height="34" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2599_7069)">
              <path
                d="M24.4846 12.2069H26.8457V9.84583H24.4846V12.2069ZM24.4846 18.1333H26.8457V15.7722H24.4846V18.1333ZM24.4846 24.0361H26.8457V21.675H24.4846V24.0361ZM30.2221 29.75H24.3428C24.0084 29.75 23.728 29.6365 23.5017 29.4094C23.2754 29.1823 23.1623 28.901 23.1623 28.5653C23.1623 28.2297 23.2754 27.9497 23.5017 27.7254C23.728 27.5011 24.0084 27.3889 24.3428 27.3889H30.2221V6.61109H16.6457V9.9167L14.2845 8.24033V6.5875C14.2845 5.94818 14.5143 5.39876 14.9738 4.93924C15.4333 4.47975 15.9827 4.25 16.622 4.25H30.2221C30.8714 4.25 31.4272 4.48119 31.8896 4.94356C32.352 5.40594 32.5832 5.96178 32.5832 6.61109V27.3889C32.5832 28.0382 32.352 28.5941 31.8896 29.0564C31.4272 29.5188 30.8714 29.75 30.2221 29.75ZM3.77759 29.75C3.12829 29.75 2.57245 29.5188 2.11007 29.0564C1.64769 28.5941 1.4165 28.0382 1.4165 27.3889V17.0354C1.4165 16.6576 1.50307 16.2956 1.67621 15.9493C1.84938 15.603 2.09336 15.3236 2.40817 15.1111L9.75121 9.85763C10.1679 9.56643 10.6243 9.42083 11.1203 9.42083C11.6164 9.42083 12.073 9.56643 12.4901 9.85763L19.8095 15.1111C20.1244 15.3236 20.3683 15.603 20.5415 15.9493C20.7146 16.2956 20.8012 16.6576 20.8012 17.0354V27.3889C20.8012 28.0382 20.57 28.5941 20.1077 29.0564C19.6453 29.5188 19.0894 29.75 18.4401 29.75H14.0248C13.6903 29.75 13.41 29.6369 13.1837 29.4106C12.9574 29.1843 12.8443 28.9039 12.8443 28.5695V22.643H9.39705V28.5695C9.39705 28.9039 9.28392 29.1843 9.05765 29.4106C8.83136 29.6369 8.55098 29.75 8.2165 29.75H3.77759ZM3.77759 17.0336V27.3889H7.03596V21.4625C7.03596 21.128 7.14909 20.8476 7.37536 20.6214C7.60162 20.3951 7.88201 20.282 8.2165 20.282H14.0248C14.3593 20.282 14.6397 20.3951 14.866 20.6214C15.0922 20.8476 15.2054 21.128 15.2054 21.4625V27.3889H18.4401V17.0336L11.1207 11.782L3.77759 17.0336Z"
                fill="#042954"
              />
            </g>
          </svg>

          <h1
            className="fs-4 fw-bold lh-base mb-0"
            style={{ color: "#042954" }}
          >
            Add New Pox
          </h1>
        </div>
        <CloseButton onClick={handleCancel} />
      </div>

      <PoxForm />
    </Container>
  );
}
