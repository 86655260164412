import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "utils/firebase";
import {
  getUser,
  logInSuccess,
  updateLoading,
} from "redux/features/auth.slice";
import { toast } from "react-toastify";
import axios from "apis/axios";

const PersistLogin = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  useEffect(() => {
    const refresh = async () => {
      dispatch(updateLoading(true));
      return onAuthStateChanged(auth, async (userCred) => {
        try {
          if (userCred) {
            const { data } = await axios.post("/auth", {
              token: await userCred.getIdToken(),
            });

            dispatch(
              logInSuccess({
                user: {
                  uid: userCred?.uid,
                  displayName: userCred?.displayName,
                  email: userCred?.email,
                  phoneNumber: userCred?.phoneNumber,
                  photoURL: userCred?.photoURL,
                  isAdmin: data.user.isAdmin,
                  coins: data.user.coins,
                  admin_type: data.user?.admin_type,
                  access_type: data.user?.access_type,
                },
                token: await userCred.getIdToken(),
              })
            );
          }
        } catch (err) {
          console.error({ err });
          if (err?.code === "auth/user-disabled") {
            toast.info(
              "Your account has been disable, contact the Admin to re-enable it"
            );
          }
        } finally {
          dispatch(updateLoading(false));
        }
      });
    };

    if (!user) refresh();
    else dispatch(updateLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Outlet />;
};

export default PersistLogin;
