import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { FaTimes, FaBuilding, FaInfoCircle, FaChartLine } from "react-icons/fa";
import { MdOutlineMessage } from "react-icons/md";
import { useAxiosPrivate, useURLQuery } from "hooks";
import { getUser } from "redux/features/auth.slice";
import { setPreview } from "redux/features/other.slice";
import { updateLayer, updatePayType } from "redux/features/map.slice";
import { BasicLoader } from "components";
import { ReactComponent as VerifiedIcon } from "assets/svg/Verified.svg";
import PropertyInfo from "./PropertyInfo";
import SalePredictionChart from "./SalePredictionChart";
import SaveButton from "./SaveButton";
import NoteButton from "./NoteButton";
import UserActions from "./UserActions";
import ShareButton from "./ShareButton";
import "./styles.scss";

const tab_options = [
  { name: "Info", icon: FaInfoCircle },
  { name: "More Info", icon: FaBuilding },
  { name: "Price Prediction", icon: FaChartLine },
];

const action_btns = [
  { name: "Save Pox", btn: SaveButton },
  { name: "Add Note", btn: NoteButton },
  { name: "Share Now", btn: ShareButton },
];

const PoxInfoModal = () => {
  const { pid } = useParams();
  const URLQuery = useURLQuery();
  const shared = URLQuery.get("shared");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const user = useSelector(getUser);

  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState();
  const [tab, setTab] = useState("Info");

  const { data, isLoading } = useQuery({
    queryKey: ["pox", { id: pid }],
    queryFn: async () => {
      const res = await axios.get(`/poxes/map/${pid}`);
      return res.data?.pox;
    },
    enabled: !!pid,
    onError() {
      toast.error("No Pox with such Id");
      navigate(-1, { replace: true });
    },
  });

  const sendMessage = () => {
    navigate(
      `/messages/create-chat?to=${data?.publishedBy?.uid}&for=${window.location.href}`
    );
  };

  const handleImageClick = (imageUrl) => {
    setMainImage(imageUrl);
  };

  const handleClose = () => {
    if (shared) {
      navigate("/");
      return;
    }
    navigate(-1);
  };

  useEffect(() => {
    if (data && !isLoading) {
      setImages(
        [
          ...data.images,
          { uid: "0", url: "/assets/img/ucpox_ux.png" },
          { uid: "1", url: "/assets/img/ucpox_ux.png" },
          { uid: "2", url: "/assets/img/ucpox_ux.png" },
          { uid: "3", url: "/assets/img/ucpox_ux.png" },
        ].slice(0, 4)
      );

      dispatch(updateLayer(data.type));
      dispatch(updatePayType(data.pay_type));
      localStorage.setItem(
        "LAST_POX_POPUP_OPENED",
        JSON.stringify([data.position?.lat, data.position?.lng])
      );
      dispatch(setPreview(data.id));
    }

    return () => {
      setImages([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  useEffect(() => {
    setMainImage(images[0]);
  }, [images]);

  return (
    <div className="pox-info-modal">
      <div className="mask" onClick={handleClose} />

      {isLoading ? (
        <div className="content">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "grid",
              placeItems: "center",
              padding: "16px 0",
            }}
          >
            <BasicLoader />
          </div>
        </div>
      ) : (
        data && (
          <div className="content">
            <div className="d-flex align-items-center justify-content-between mt-3">
              <div className="d-flex align-items-center">
                <FaBuilding color="#042954" fontSize={14} />
                <span style={{ marginLeft: 8, color: "#042954", fontSize: 14 }}>
                  View Details - {data.name}
                </span>
              </div>

              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <FaTimes fontSize={16} />
              </div>
            </div>

            <div className="imageContainer">
              <div className="mainImage">
                <img src={mainImage?.url} alt={images[0]?.uid} loading="lazy" />
              </div>

              <div className="slideImages">
                {images?.map((image) => {
                  if (image.uid === mainImage?.uid) return null;

                  return (
                    <img
                      key={image?.uid}
                      src={image?.url}
                      alt={image?.uid}
                      loading="lazy"
                      onClick={() => handleImageClick(image)}
                    />
                  );
                })}
              </div>
            </div>

            <div className="mt-4 content-heading border-bottom border-gray border-1">
              <div className="d-flex justify-content-between">
                <h1 className="d-flex flex-column gap-1">
                  <span className="type type-mobile">
                    <FaBuilding fontSize={10} />
                    <div>{data.type}</div>
                  </span>

                  <div className="d-flex align-items-center gap-1">
                    <span>{data.name}</span>
                    <VerifiedIcon style={{ height: 16, width: 16 }} />
                  </div>

                  <div className="address">{data?.address}</div>
                </h1>

                <div className="d-flex justify-content-between align-items-center gap-2">
                  <div className="type type-desktop">
                    <FaBuilding fontSize={10} />
                    <div>{data.type}</div>
                  </div>
                  <div className="d-flex justify-content-between gap-2">
                    {action_btns.map((act) => {
                      if (
                        ((act.name === "Save Pox" || act.name === "Add Note") &&
                          !user) ||
                        user?.uid === data?.publishedBy?.uid
                      ) {
                        return null;
                      }

                      return (
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>{act.name}</Tooltip>
                          )}
                          placement="bottom"
                          key={act.name}
                        >
                          <div>
                            <act.btn pox={data} />
                          </div>
                        </OverlayTrigger>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="content-body">
              <div className="toggle-detail">
                {tab_options.map((opt) => {
                  if (
                    data.pay_type !== "Sale" &&
                    opt.name === "Price Prediction"
                  ) {
                    return null;
                  }

                  return (
                    <OverlayTrigger
                      delay={{ hide: 450, show: 300 }}
                      overlay={(props) => (
                        <Tooltip {...props}>{opt.name}</Tooltip>
                      )}
                      placement="right"
                      key={opt.name}
                    >
                      <button
                        type="button"
                        className="tab-btn"
                        style={{
                          backgroundColor:
                            tab === opt.name ? "#F0F2F5" : "white",
                        }}
                      >
                        <opt.icon
                          color={tab === opt.name ? "#4C83EB" : "gray"}
                          onClick={() => setTab(opt.name)}
                          fontSize={14}
                        />
                      </button>
                    </OverlayTrigger>
                  );
                })}
              </div>

              <div className="info-section">
                {tab === "Price Prediction" && data.pay_type === "Sale" ? (
                  <SalePredictionChart sale_price={data.sale_cost} currency={data.currency} />
                ) : (
                  <PropertyInfo pox={data} tab={tab} />
                )}
              </div>
            </div>

            <div className="owner">
              <div className="owner-info d-flex justify-content-between align-items-center gap-2">
                <img
                  src={data?.publishedBy?.photoURL}
                  alt={data?.publishedBy?.displayName}
                  loading="lazy"
                  referrerPolicy="no-referrer"
                  placeholder="/assets/img/ucpox_ux.png"
                />
                <div>
                  <div className="user-name">
                    {data?.publishedBy?.displayName}
                  </div>
                  <div className="user-status">
                    {data.ownership ? "Owner" : "Not Owner"}
                  </div>
                </div>
              </div>

              <div className="owner-actions">
                {user?.uid === data?.publishedBy?.uid && (
                  <UserActions data={data} />
                )}

                {user?.uid && user?.uid !== data?.publishedBy?.uid && (
                  <>
                    <button className="send-message mobile">
                      <MdOutlineMessage fontSize={14} />
                    </button>
                    <button className="send-message" onClick={sendMessage}>
                      Message
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default PoxInfoModal;
