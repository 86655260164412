import { useState } from "react";
import { FaShare } from "react-icons/fa";
import ShareNow from "./ShareNow";

export default function ShareButton({ pox }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button className="btn-icons" onClick={() => setIsOpen(true)}>
        <FaShare fontSize={12} />
      </button>

      <ShareNow open={isOpen} onClose={() => setIsOpen(false)} pox={pox} />
    </>
  );
}
