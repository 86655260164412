import { useMemo } from "react";
import { Pagination } from "react-bootstrap";

export default function CPagination({
  total_pages = 10,
  onClick,
  current_page = 5,
}) {
  const CPaginationItem = ({ id }) =>
    useMemo(
      () => {
        return (
          <Pagination.Item
            active={id === current_page}
            onClick={() => onClick(id)}
          >
            {id}
          </Pagination.Item>
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [current_page]
    );

  return (
    <Pagination size="sm" style={{ marginBottom: 0 }}>
      {/* <Pagination.First
        disabled={current_page === 1}
        onClick={() => onClick(1)}
      /> */}
      <Pagination.Prev
        disabled={current_page === 1}
        onClick={() => onClick(current_page - 1)}
      />

      {total_pages <= 5 ? (
        Array.from(Array(total_pages).keys()).map((page) => (
          <CPaginationItem key={page + 1} id={page + 1} />
        ))
      ) : (
        <>
          <CPaginationItem id={1} />
          {current_page === 1 || current_page - 1 < 2 ? (
            <>
              <CPaginationItem id={2} />
              <CPaginationItem id={3} />
            </>
          ) : current_page === total_pages || total_pages - current_page < 2 ? (
            <>
              <CPaginationItem id={total_pages - 2} />
              <CPaginationItem id={total_pages - 1} />
            </>
          ) : (
            <>
              <CPaginationItem id={current_page - 1} />
              <CPaginationItem id={current_page} />
              <CPaginationItem id={current_page + 1} />
            </>
          )}
          <CPaginationItem id={total_pages} />
        </>
      )}
      <Pagination.Next
        disabled={current_page === total_pages}
        onClick={() => onClick(current_page + 1)}
      />
      {/* <Pagination.Last
        disabled={current_page === total_pages}
        onClick={() => onClick(total_pages)}
      /> */}
    </Pagination>
  );
}
