import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "assets/svg/delete.svg";
import { ReactComponent as EditIcon } from "assets/svg/edit.svg";
import { DeletePoxModal } from "components";

export default function UserActions({ data }) {
  const [dshow, setDShow] = useState(false);
  const handleClose = () => setDShow(false);
  const navigate = useNavigate();

  return (
    <>
      <Link to={`/poxes/${data.id}/edit`} className="btn p-0">
        <EditIcon />
      </Link>
      <button className="btn p-0" onClick={() => setDShow(true)}>
        <DeleteIcon />
      </button>

      <DeletePoxModal
        show={dshow}
        onHide={handleClose}
        pox={data}
        onSuccess={() => navigate(-1)}
      />
    </>
  );
}
