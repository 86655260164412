export const currencies = [
    { id: "AED", label: "United Arab Emirates Dirham", enabled: true },
    { id: "AFN", label: "Afghan Afghani", enabled: false },
    { id: "ALL", label: "Albanian Lek", enabled: false },
    { id: "AMD", label: "Armenian Dram", enabled: false },
    { id: "ANG", label: "Netherlands Antillean Guilder", enabled: false },
    { id: "AOA", label: "Angolan Kwanza", enabled: false },
    { id: "ARS", label: "Argentine Peso", enabled: false },
    { id: "AUD", label: "Australian Dollar", enabled: false },
    { id: "AWG", label: "Aruban Florin", enabled: false },
    { id: "AZN", label: "Azerbaijani Manat", enabled: false },
    { id: "BAM", label: "Bosnia-Herzegovina Convertible Mark", enabled: false },
    { id: "BBD", label: "Barbadian Dollar", enabled: false },
    { id: "BDT", label: "Bangladeshi Taka", enabled: false },
    { id: "BGN", label: "Bulgarian Lev", enabled: false },
    { id: "BHD", label: "Bahraini Dinar", enabled: false },
    { id: "BIF", label: "Burundian Franc", enabled: false },
    { id: "BMD", label: "Bermudian Dollar", enabled: false },
    { id: "BND", label: "Brunei Dollar", enabled: false },
    { id: "BOB", label: "Bolivian Boliviano", enabled: false },
    { id: "BRL", label: "Brazilian Real", enabled: false },
    { id: "BSD", label: "Bahamian Dollar", enabled: false },
    { id: "BTN", label: "Bhutanese Ngultrum", enabled: false },
    { id: "BWP", label: "Botswana Pula", enabled: false },
    { id: "BYN", label: "Belarusian Ruble", enabled: false },
    { id: "BZD", label: "Belize Dollar", enabled: false },
    { id: "CAD", label: "Canadian Dollar", enabled: false },
    { id: "CDF", label: "Congolese Franc", enabled: false },
    { id: "CHF", label: "Swiss Franc", enabled: false },
    { id: "CLP", label: "Chilean Peso", enabled: false },
    { id: "CNY", label: "Chinese Yuan", enabled: false },
    { id: "COP", label: "Colombian Peso", enabled: false },
    { id: "CRC", label: "Costa Rican Colón", enabled: false },
    { id: "CUP", label: "Cuban Peso", enabled: false },
    { id: "CVE", label: "Cape Verdean Escudo", enabled: false },
    { id: "CZK", label: "Czech Koruna", enabled: false },
    { id: "DJF", label: "Djiboutian Franc", enabled: false },
    { id: "DKK", label: "Danish Krone", enabled: false },
    { id: "DOP", label: "Dominican Peso", enabled: false },
    { id: "DZD", label: "Algerian Dinar", enabled: false },
    { id: "EGP", label: "Egyptian Pound", enabled: false },
    { id: "ERN", label: "Eritrean Nakfa", enabled: false },
    { id: "ETB", label: "Ethiopian Birr", enabled: false },
    { id: "EUR", label: "Euro", enabled: false },
    { id: "FJD", label: "Fijian Dollar", enabled: false },
    { id: "FKP", label: "Falkland Islands Pound", enabled: false },
    { id: "FOK", label: "Faroese Króna", enabled: false },
    { id: "GBP", label: "British Pound", enabled: false },
    { id: "GEL", label: "Georgian Lari", enabled: false },
    { id: "GGP", label: "Guernsey Pound", enabled: false },
    { id: "GHS", label: "Ghanaian Cedi", enabled: false },
    { id: "GIP", label: "Gibraltar Pound", enabled: false },
    { id: "GMD", label: "Gambian Dalasi", enabled: false },
    { id: "GNF", label: "Guinean Franc", enabled: false },
    { id: "GTQ", label: "Guatemalan Quetzal", enabled: false },
    { id: "GYD", label: "Guyanese Dollar", enabled: false },
    { id: "HKD", label: "Hong Kong Dollar", enabled: false },
    { id: "HNL", label: "Honduran Lempira", enabled: false },
    { id: "HRK", label: "Croatian Kuna", enabled: false },
    { id: "HTG", label: "Haitian Gourde", enabled: false },
    { id: "HUF", label: "Hungarian Forint", enabled: false },
    { id: "IDR", label: "Indonesian Rupiah", enabled: false },
    { id: "ILS", label: "Israeli New Shekel", enabled: false },
    { id: "IMP", label: "Isle of Man Pound", enabled: false },
    { id: "INR", label: "Indian Rupee", enabled: true },
    { id: "IQD", label: "Iraqi Dinar", enabled: false },
    { id: "IRR", label: "Iranian Rial", enabled: false },
    { id: "ISK", label: "Icelandic Króna", enabled: false },
    { id: "JEP", label: "Jersey Pound", enabled: false },
    { id: "JMD", label: "Jamaican Dollar", enabled: false },
    { id: "JOD", label: "Jordanian Dinar", enabled: false },
    { id: "JPY", label: "Japanese Yen", enabled: false },
    { id: "KES", label: "Kenyan Shilling", enabled: false },
    { id: "KGS", label: "Kyrgyzstani Som", enabled: false },
    { id: "KHR", label: "Cambodian Riel", enabled: false },
    { id: "KID", label: "Kiribati Dollar", enabled: false },
    { id: "KMF", label: "Comorian Franc", enabled: false },
    { id: "KRW", label: "South Korean Won", enabled: false },
    { id: "KWD", label: "Kuwaiti Dinar", enabled: false },
    { id: "KYD", label: "Cayman Islands Dollar", enabled: false },
    { id: "KZT", label: "Kazakhstani Tenge", enabled: false },
    { id: "LAK", label: "Lao Kip", enabled: false },
    { id: "LBP", label: "Lebanese Pound", enabled: false },
    { id: "LKR", label: "Sri Lankan Rupee", enabled: false },
    { id: "LRD", label: "Liberian Dollar", enabled: false },
    { id: "LSL", label: "Lesotho Loti", enabled: false },
    { id: "LYD", label: "Libyan Dinar", enabled: false },
    { id: "MAD", label: "Moroccan Dirham", enabled: false },
    { id: "MDL", label: "Moldovan Leu", enabled: false },
    { id: "MGA", label: "Malagasy Ariary", enabled: false },
    { id: "MKD", label: "Macedonian Denar", enabled: false },
    { id: "MMK", label: "Burmese Kyat", enabled: false },
    { id: "MNT", label: "Mongolian Tögrög", enabled: false },
    { id: "MOP", label: "Macanese Pataca", enabled: false },
    { id: "MRU", label: "Mauritanian Ouguiya", enabled: false },
    { id: "MUR", label: "Mauritian Rupee", enabled: false },
    { id: "MVR", label: "Maldivian Rufiyaa", enabled: false },
    { id: "MWK", label: "Malawian Kwacha", enabled: false },
    { id: "MXN", label: "Mexican Peso", enabled: false },
    { id: "MYR", label: "Malaysian Ringgit", enabled: false },
    { id: "MZN", label: "Mozambican Metical", enabled: false },
    { id: "NAD", label: "Namibian Dollar", enabled: false },
    { id: "NGN", label: "Nigerian Naira", enabled: false },
    { id: "NIO", label: "Nicaraguan Córdoba", enabled: false },
    { id: "NOK", label: "Norwegian Krone", enabled: false },
    { id: "NPR", label: "Nepalese Rupee", enabled: false },
    { id: "NZD", label: "New Zealand Dollar", enabled: false },
    { id: "OMR", label: "Omani Rial", enabled: false },
    { id: "PAB", label: "Panamanian Balboa", enabled: false },
    { id: "PEN", label: "Peruvian Sol", enabled: false },
    { id: "PGK", label: "Papua New Guinean Kina", enabled: false },
    { id: "PHP", label: "Philippine Peso", enabled: false },
    { id: "PKR", label: "Pakistani Rupee", enabled: false },
    { id: "PLN", label: "Polish Złoty", enabled: false },
    { id: "PYG", label: "Paraguayan Guaraní", enabled: false },
    { id: "QAR", label: "Qatari Riyal", enabled: false },
    { id: "RON", label: "Romanian Leu", enabled: false },
    { id: "RSD", label: "Serbian Dinar", enabled: false },
    { id: "RUB", label: "Russian Ruble", enabled: false },
    { id: "RWF", label: "Rwandan Franc", enabled: false },
    { id: "SAR", label: "Saudi Riyal", enabled: false },
    { id: "SBD", label: "Solomon Islands Dollar", enabled: false },
    { id: "SCR", label: "Seychellois Rupee", enabled: false },
    { id: "SDG", label: "Sudanese Pound", enabled: false },
    { id: "SEK", label: "Swedish Krona", enabled: false },
    { id: "SGD", label: "Singapore Dollar", enabled: false },
    { id: "SHP", label: "Saint Helena Pound", enabled: false },
    { id: "SLL", label: "Sierra Leonean Leone", enabled: false },
    { id: "SOS", label: "Somali Shilling", enabled: false },
    { id: "SRD", label: "Surinamese Dollar", enabled: false },
    { id: "SSP", label: "South Sudanese Pound", enabled: false },
    { id: "STN", label: "São Tomé and Príncipe Dobra", enabled: false },
    { id: "SYP", label: "Syrian Pound", enabled: false },
    { id: "SZL", label: "Eswatini Lilangeni", enabled: false },
    { id: "THB", label: "Thai Baht", enabled: false },
    { id: "TJS", label: "Tajikistani Somoni", enabled: false },
    { id: "TMT", label: "Turkmenistani Manat", enabled: false },
    { id: "TND", label: "Tunisian Dinar", enabled: false },
    { id: "TOP", label: "Tongan Paʻanga", enabled: false },
    { id: "TRY", label: "Turkish Lira", enabled: false },
    { id: "TTD", label: "Trinidad and Tobago Dollar", enabled: false },
    { id: "TVD", label: "Tuvaluan Dollar", enabled: false },
    { id: "TZS", label: "Tanzanian Shilling", enabled: false },
    { id: "UAH", label: "Ukrainian Hryvnia", enabled: false },
    { id: "UGX", label: "Ugandan Shilling", enabled: false },
    { id: "USD", label: "United States Dollar", enabled: true },
    { id: "USD", label: "US Dollar (International)", enabled: false },
    { id: "UYU", label: "Uruguayan Peso", enabled: false },
    { id: "UZS", label: "Uzbekistani Som", enabled: false },
    { id: "VES", label: "Venezuelan Bolívar Soberano", enabled: false },
    { id: "VND", label: "Vietnamese Đồng", enabled: false },
    { id: "VUV", label: "Vanuatu Vatu", enabled: false },
    { id: "WST", label: "Samoan Tālā", enabled: false },
    { id: "XAF", label: "Central African CFA Franc", enabled: false },
    { id: "XCD", label: "East Caribbean Dollar", enabled: false },
    { id: "XOF", label: "West African CFA Franc", enabled: false },
    { id: "XPF", label: "CFP Franc", enabled: false },
    { id: "YER", label: "Yemeni Rial", enabled: false },
    { id: "ZAR", label: "South African Rand", enabled: false },
    { id: "ZMW", label: "Zambian Kwacha", enabled: false },
    { id: "ZWL", label: "Zimbabwean Dollar", enabled: false },
  ];