import "./styles.scss";
import { ReactComponent as HomeSvg } from "assets/svg/nav/home.svg";
import { ReactComponent as GeofenceSvg } from "assets/svg/nav/geofence.svg";
import { ReactComponent as MessageSvg } from "assets/svg/nav/mesaage.svg";
import { ReactComponent as UpgradeSvg } from "assets/svg/nav/upgrade.svg";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { GuidedTourWrapper } from "components";
import { useAxiosPrivate } from "hooks";

const nav_items = [
  { id: "home", label: "Home", icon: HomeSvg, href: "/" },
  {
    id: "geofence",
    label: "Geofence",
    icon: GeofenceSvg,
    href: "/geofence",
  },
  { id: "message", label: "Message", icon: MessageSvg, href: "/messages" },
  { id: "upgrade", label: "Upgrade", icon: UpgradeSvg, href: "/subscription" },
];

export default function BottomNav() {
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const { user, loading } = useSelector((state) => state.auth);

  // unread messages
  const { data: unread_messages = 0 } = useQuery(
    ["unread-messages", { uid: user?.uid }],
    async () => {
      const res = await axiosPrivate("chats/unread");
      return res.data;
    },
    { enabled: !!user && !loading }
  );

  return (
    <GuidedTourWrapper stage={5} className="bottom-nav">
      <div className="bottom-nav-content">
        <nav>
          {nav_items.map((item) => {
            const active =
              item.href === "/"
                ? location.pathname === "/"
                : location.pathname.startsWith(item.href);

            return (
              <Link key={item.id} to={item.href}>
                <div className={active ? "active" : ""}>
                  <div className="icon">
                    <item.icon />
                    {item.id === "message" && unread_messages > 0 && (
                      <span className="badge">{unread_messages}</span>
                    )}
                  </div>
                  <span>{item.label}</span>
                </div>
              </Link>
            );
          })}
        </nav>
      </div>
    </GuidedTourWrapper>
  );
}
